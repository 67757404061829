import { createQueryHook } from './internal/createQueryHook';
import { measuredRollouts } from './internal/queries';

export const useMeasuredRolloutMetricResults = createQueryHook(
  ({
    projectKey,
    flagKey,
    environmentKey,
    measuredRolloutId,
    metricKey,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    measuredRolloutId: string;
    metricKey: string;
  }) =>
    measuredRollouts.metricResults({
      projectKey,
      flagKey,
      environmentKey,
      measuredRolloutId,
      metricKey,
    }),
);
