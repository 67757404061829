import type { Member, MemberSummary } from './types';

export function memberFirstName(member: Pick<Member, 'firstName'> | Pick<MemberSummary, 'firstName'>) {
  return member.firstName;
}

function memberFullName(
  member: Pick<Member, 'firstName' | 'lastName'> | Pick<MemberSummary, 'firstName' | 'lastName'>,
) {
  return [member.firstName, member.lastName].join(' ').trim();
}

export function memberDisplayName(
  member: Pick<Member, 'firstName' | 'lastName' | 'email'> | Pick<MemberSummary, 'firstName' | 'lastName' | 'email'>,
): string {
  return memberFullName(member) || member.email;
}
