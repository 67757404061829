import { useParam } from './useParam';

export function useFlagOrAiConfigKey() {
  const flagParam = useParam('flagKey', { optional: true });
  const configParam = useParam('configKey', { optional: true });

  if (!flagParam && !configParam) {
    // throw, we found neither
    throw new Error('Expected flagKey or configKey');
  }

  return (flagParam ?? configParam) as string;
}
