import { type PostAuditLogEntriesOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface PostAuditLogEntriesQueryParams extends PostAuditLogEntriesOpenAPIQueryParams {}

export function querySerializer(queryParams: PostAuditLogEntriesQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.q && queryParams.q.length > 0) {
    searchParams.set('q', queryParams.q);
  }

  if ((queryParams.limit ?? 0) > 0) {
    searchParams.set('limit', String(queryParams.limit));
  }

  if (queryParams.after) {
    searchParams.set('after', String(queryParams.after));
  }

  if (queryParams.before) {
    searchParams.set('before', String(queryParams.before));
  }

  return searchParams.toString();
}
