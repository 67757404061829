import { createQueryHook } from './internal/createQueryHook';
import { metrics } from './internal/queries';

export const useMetricEvents = createQueryHook(
  ({
    projectKey,
    metricKey,
    environmentKey,
    offset,
    limit,
    uptoTime,
  }: {
    projectKey: string;
    metricKey: string;
    environmentKey: string;
    uptoTime?: number;
    limit?: number;
    offset?: number;
  }) => metrics.events({ projectKey, metricKey, environmentKey, offset, limit, uptoTime }),
);
