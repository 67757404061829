import { useContext } from 'react';
import { ComboBoxStateContext } from 'react-aria-components';
import { IconButton } from '@launchpad-ui/components';

type ButtonGroupProps = {
  label: string;
};

export const ButtonGroup = ({ label }: ButtonGroupProps) => {
  const comboBoxState = useContext(ComboBoxStateContext);

  return (
    <>
      {!comboBoxState?.selectedKey && (
        <IconButton icon="chevron-down" variant="minimal" aria-label={label} size="small" />
      )}
      {comboBoxState?.selectedKey && (
        <IconButton
          icon="cancel-circle-outline"
          variant="minimal"
          aria-label="Clear"
          size="small"
          onPress={() => comboBoxState?.setSelectedKey(null)}
        />
      )}
    </>
  );
};
