// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, OrderedMap, Set } from 'immutable';
import { createSelector } from 'reselect';

import { EnvironmentAction } from 'actions/environments';
import { ProjectAction } from 'actions/projects';
import { useSelector } from 'hooks/useSelector';
import { GlobalState } from 'reducers';
import { createRequestReducerByKey } from 'reducers/createRequestReducer';
import paginate from 'reducers/paginate';
import { ParamsProps } from 'reducers/types/shared';
import { Environment, filterEnvironment, getProjectForEnvById } from 'utils/environmentUtils';
import { Flag } from 'utils/flagUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import {
  createEnvironmentsFiltersFromQuery,
  createProject,
  createProjectFiltersFromQuery,
  Project,
} from 'utils/projectUtils';
import { Request, StateWithRequestInfo } from 'utils/requestUtils';

import { type CurrentEnvironmentState, getCurrentEnvironmentInitialState } from './getCurrentEnvironmentInitialState';
import {
  type EnvironmentEntities,
  type EnvironmentsState,
  getEnvironmentsInitialState,
} from './getEnvironmentsInitialState';
import { type ProjectEntities, type ProjectsState, getProjectsInitialState } from './getProjectsInitialState';
import registry from './registry';
import { searchSelector } from './router';

type ProjectRequest = StateWithRequestInfo<{
  entity: Project;
}>;

export function projects(
  state: ProjectsState = getProjectsInitialState(),
  action: ProjectAction | EnvironmentAction,
): ProjectsState {
  switch (action.type) {
    case 'projects/REQUEST_PROJECTS':
      return state.set('isFetching', true);
    case 'projects/REQUEST_PROJECTS_FAILED':
      return state.merge({
        isFetching: false,
        error: action.error,
        doNotFetch: true,
      });
    case 'projects/REQUEST_PROJECTS_DONE':
      return state.withMutations((st) =>
        st.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          entities: action.response.getIn(['entities', 'projects']),
        }),
      );
    case 'projects/REQUEST_PAGINATED_PROJECTS':
      return state.set('isFetching', true);
    case 'projects/REQUEST_PAGINATED_PROJECTS_FAILED':
      return state.merge({
        isFetching: false,
        error: action.error,
        doNotFetch: true,
      });
    case 'projects/REQUEST_PAGINATED_PROJECTS_DONE':
      return state.withMutations((st) =>
        st.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          entities: state.getIn(['entities']).mergeDeep(action.response.getIn(['entities', 'projects'])),
        }),
      );
    case 'environments/REQUEST_CURRENT_PROJECT_AND_ENVIRONMENT_DONE':
      return state.withMutations((st) =>
        st.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          entities: Map({
            [action.project.key]: action.project,
          }),
        }),
      );
    case 'projects/REQUEST_PROJECT_DONE':
    case 'projects/CREATE_PROJECT_DONE':
    case 'projects/UPDATE_PROJECT_DONE':
      return state.withMutations((st) => {
        const project = action.response.get('result');
        st.update('entities', (entities: ProjectEntities) => entities.set(project.key, project));
      });
    case 'projects/DELETE_PROJECT_DONE':
      return state.update('entities', (entities) => entities.delete(action.project.key));
    case 'environments/CREATE_ENVIRONMENT_DONE':
      return state.updateIn(['entities', action.project.key, 'environments'], (envs) => envs.push(action.env._id));
    case 'environments/DELETE_ENVIRONMENT_DONE':
      return state.updateIn(['entities', action.project.key, 'environments'], (envs) =>
        envs.delete(envs.indexOf(action.env._id)),
      );

    case 'projects/UPDATE_PROJECT_FAILED':
    case 'projects/DELETE_PROJECT_FAILED':
    case 'environments/DELETE_ENVIRONMENT_FAILED':
    case 'environments/UPDATE_ENVIRONMENT_FAILED':
    case 'environments/RESET_API_KEY_FAILED':
    case 'environments/RESET_MOBILE_KEY_FAILED':
    case 'environments/CREATE_ENVIRONMENT_FAILED':
      if (action.error.get('status') !== 404) {
        return state;
      }
      return state.delete('lastFetched');
    default:
      return state;
  }
}

type EnvironmentRequest = StateWithRequestInfo<{
  entity: Environment;
}>;

export const paginatedProjects = paginate({
  types: [
    'projects/REQUEST_PAGINATED_PROJECTS',
    'projects/REQUEST_PAGINATED_PROJECTS_FAILED',
    'projects/REQUEST_PAGINATED_PROJECTS_DONE',
  ],
  invalidateTypes: ['projects/REQUEST_PAGINATED_PROJECTS_DONE', 'projects/REQUEST_PAGINATED_PROJECTS_FAILED'],
  mapActionToKey: () => 'all',
});

export const paginatedProjectEnvironments = paginate({
  types: [
    'projects/REQUEST_PROJECT_ENVIRONMENTS',
    'projects/REQUEST_PROJECT_ENVIRONMENTS_FAILED',
    'projects/REQUEST_PROJECT_ENVIRONMENTS_DONE',
  ],
  invalidateTypes: [
    'projects/REQUEST_PROJECT_ENVIRONMENTS_DONE',
    'projects/REQUEST_PROJECT_ENVIRONMENTS_FAILED',
    'environments/CREATE_ENVIRONMENT_DONE',
    'environments/DELETE_ENVIRONMENT_DONE',
  ],
  mapActionToKey: () => 'all',
});

export function projectEnvironments(
  state: EnvironmentsState = getEnvironmentsInitialState(),
  action: ProjectAction | EnvironmentAction,
) {
  switch (action.type) {
    case 'environments/UPDATE_ENVIRONMENT_DONE':
      const envEntities = state.get('entities');
      const sortByCritical = (a?: Environment, b?: Environment) =>
        a && b && a.get('critical') === true && b.critical !== true ? -1 : 1;
      return state.merge({
        entities: envEntities.set(action.env._id, action.env).sort((a, b) => sortByCritical(a, b)),
        isFetching: false,
        lastFetched: Date.now(),
      });
    case 'projects/REQUEST_PROJECTS_FAILED':
      return state.merge({ doNotFetch: true, lastFetched: undefined, isFetching: false });
    case 'projects/REQUEST_PROJECT_DONE':
      return state.merge({ lastFetched: action.timestamp, isFetching: false });
    case 'projects/REQUEST_PROJECT_ENVIRONMENTS':
    case 'environments/UPDATE_ENVIRONMENT_FAILED':
      return state.merge({
        isFetching: false,
        lastFetched: Date.now(),
      });
    case 'projects/REQUEST_PROJECT_ENVIRONMENTS_DONE':
      return state.merge({
        isFetching: false,
        lastFetched: Date.now(),
        entities: action.response.getIn(['entities', 'environments']),
      });
    case 'projects/REQUEST_PROJECT_ENVIRONMENTS_FAILED':
      return state.merge({
        isFetching: false,
      });
    case 'environments/DELETE_ENVIRONMENT_DONE':
      return state.update('entities', (entities: EnvironmentEntities) => entities.delete(action.env._id));
    default:
      return state;
  }
}

export function environments(
  state: EnvironmentsState = getEnvironmentsInitialState(),
  action: ProjectAction | EnvironmentAction,
) {
  switch (action.type) {
    case 'projects/REQUEST_PROJECTS_DONE':
      return state.set('entities', action.response.getIn(['entities', 'environments']));
    case 'projects/REQUEST_PROJECT_DONE':
    case 'projects/CREATE_PROJECT_DONE':
      return state.update('entities', (entities: EnvironmentEntities) =>
        entities.merge(action.response.getIn(['entities', 'environments'])),
      );
    case 'projects/UPDATE_PROJECT_DONE':
      return state.update('entities', (entities: EnvironmentEntities) =>
        entities.merge(action.response.getIn(['entities', 'environments'])),
      );
    case 'environments/CREATE_ENVIRONMENT_DONE':
      return state.update('entities', (entities: EnvironmentEntities) => entities.set(action.env._id, action.env));
    case 'environments/UPDATE_ENVIRONMENT_DONE':
      return state.update('entities', (entities: EnvironmentEntities) => entities.set(action.env._id, action.env));
    case 'environments/RESET_API_KEY_DONE':
    case 'environments/RESET_MOBILE_KEY_DONE':
      return state.update('entities', (entities: EnvironmentEntities) => entities.set(action.env._id, action.env));
    case 'environments/DELETE_ENVIRONMENT_DONE':
      return state.update('entities', (entities: EnvironmentEntities) => entities.delete(action.env._id));
    case 'environments/FOLLOW_ENVIRONMENT':
      return state;
    // revert the environment if updating the preference failed
    case 'environments/FOLLOW_ENVIRONMENT_FAILED':
      return state;
    default:
      return state;
  }
}

export function currentEnvironment(
  state = getCurrentEnvironmentInitialState(),
  action: EnvironmentAction,
): CurrentEnvironmentState {
  switch (action.type) {
    case 'environments/REQUEST_CURRENT_PROJECT_AND_ENVIRONMENT':
      return state.set('isFetching', true);
    case 'environments/REQUEST_CURRENT_PROJECT_AND_ENVIRONMENT_FAILED':
      return state.merge({
        isFetching: false,
        error: action.error,
        doNotFetch: true,
      });
    case 'environments/REQUEST_CURRENT_PROJECT_AND_ENVIRONMENT_DONE':
      return state.withMutations((st: CurrentEnvironmentState) =>
        st.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          entity: action.environment._id,
        }),
      );
    default:
      return state;
  }
}

export const environmentTextFilters = (state: Map<string, string> = Map(), action: EnvironmentAction) => {
  switch (action.type) {
    case 'environments/FILTER_ENVIRONMENTS_BY_TEXT':
      return state.set(action.project.key, action.term);
    default:
      return state;
  }
};

export const projectRequestByKey = createRequestReducerByKey(
  ['projects/REQUEST_PROJECT', 'projects/REQUEST_PROJECT_DONE', 'projects/REQUEST_PROJECT_FAILED'],
  (action) => action.projectKey,
);

export const projectsSelector = (state: GlobalState): ProjectsState => state.projects;

export const projectEntitiesSelector = (state: GlobalState) => projectsSelector(state).get('entities');

export const projectsByKeysSelector = (keys: string[]) =>
  createSelector(projectEntitiesSelector, (projectEntities: ProjectEntities) => {
    // Iterate over keys first to preserve order.
    const results: OrderedMap<string, Project> = keys.reduce(
      (projectsOnPage: OrderedMap<string, Project>, key: string) => {
        if (projectEntities.get(key)) {
          /* eslint-disable @typescript-eslint/no-non-null-assertion */
          return projectsOnPage.set(
            key,
            projectEntities.get(key)!,
          ); /* eslint-enable @typescript-eslint/no-non-null-assertion */
        }
        return projectsOnPage;
      },
      OrderedMap<string, Project>(),
    );
    return results;
  });

export const projectSelector = (projKey: string) => (state: GlobalState) =>
  projectsSelector(state).get('entities').get(projKey);

export const projectTagsSelector = createSelector(projectsSelector, (projectsState) =>
  projectsState
    .get('entities')
    .map((p: Project) => p.tags)
    .flatten()
    .toSet(),
);

export const sortedProjectTagsSelector = createSelector(projectTagsSelector, (tags) => tags.sort());

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const projectByKeySelector = createSelector<
  GlobalState,
  ParamsProps<{ projKey: string }>,
  ProjectsState,
  string,
  ProjectRequest
>(
  projectsSelector,
  (state, props) => (props.match ? props.match.params.projKey : props.params!.projKey),
  (projectsState, projKey) =>
    fromJS({
      lastFetched: projectsState.get('lastFetched'),
      isFetching: projectsState.get('isFetching'),
      entity: projectsState.getIn(['entities', projKey]),
    }),
); /* eslint-enable @typescript-eslint/no-non-null-assertion */

export const makeProjectSelectorForEnvId = (envId: string) => (state: GlobalState) => {
  const projectsState = projectsSelector(state);
  return fromJS({
    lastFetched: projectsState.get('lastFetched'),
    isFetching: projectsState.get('isFetching'),
    entity: getProjectForEnvById(envId, projectsState.get('entities')),
  });
};

export const environmentsSelector = (state: GlobalState) => state.environments;

export const environmentEntitiesSelector = (state: GlobalState) => environmentsSelector(state).get('entities');
export const environmentsPaginationSelector = (state: GlobalState) => state.paginatedProjectEnvironments.get('all');
export const projectsPaginationSelector = (state: GlobalState) => state.paginatedProjects.get('all');
export const paginatedEnvironmentIDsSelector = (state: GlobalState) =>
  environmentsPaginationSelector(state)?.get('ids')?.toArray() || [];

export const projectEnvironmentsTagsSelector = (projectKey: string) =>
  createSelector<GlobalState, ProjectEntities, EnvironmentEntities, Set<string>>(
    projectEntitiesSelector,
    environmentEntitiesSelector,
    (projectEntities, allAccountEnvironments) =>
      projectEntities.getIn([projectKey, 'environments']).reduce((environmentTags: Set<string>, id: string) => {
        let updatedSet = environmentTags;
        const matchingEnvironment = allAccountEnvironments.get(id);
        if (matchingEnvironment) {
          updatedSet = environmentTags.union(matchingEnvironment.get('tags'));
        }
        return updatedSet;
      }, Set()),
  );

export const projectEnvironmentsSelector = (state: GlobalState) => state.projectEnvironments;
export const projectEnvironmentsEntitiesSelector = (state: GlobalState) => state.projectEnvironments.get('entities');

export const followedEnvironmentIdsSelector = createSelector<GlobalState, EnvironmentEntities, string[]>(
  environmentEntitiesSelector,
  (envs) =>
    envs
      .filter((env) => env._followed)
      .keySeq()
      .toArray(),
);

export const makeEnvironmentFilterSelector = () => (state: GlobalState, props: { project: Project }) =>
  state.environmentTextFilters.get(props.project.key, '');

export const makeProjectEnvironmentsSelector = () =>
  createSelector<GlobalState, { project: Project }, EnvironmentEntities, Project, List<Environment>>(
    environmentEntitiesSelector,
    (_, props) => props.project,
    (entities, project) => project.environments.map((id) => entities.get(id)).filter((env) => !!env),
  );

type FilteredEnvironmentList = ImmutableMap<{
  filter: string;
  list: List<Environment>;
}>;

export const makeFilteredProjectEnvironmentListSelector = () => {
  const filterSelector = makeEnvironmentFilterSelector();
  const listSelector = makeProjectEnvironmentsSelector();

  return createSelector<GlobalState, { project: Project }, string, List<Environment>, FilteredEnvironmentList>(
    filterSelector,
    listSelector,
    (filter, environmentsList) =>
      Map({
        filter,
        list: environmentsList.filter((env?: Environment) => !!env && filterEnvironment(env, filter)),
      }),
  );
};

export const makeProjectByKeySelector = () =>
  createSelector<GlobalState, { projectKey: string }, ProjectEntities, string, Project | undefined>(
    projectEntitiesSelector,
    (_, props) => props.projectKey,
    (entities, key) => entities.get(key),
  );

export const makeEnvironmentByIdSelector = () =>
  createSelector<GlobalState, { environmentId: string }, EnvironmentEntities, string, Environment | undefined>(
    environmentEntitiesSelector,
    (_, props) => props.environmentId,
    (entities, id) => entities.get(id),
  );

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const environmentByKeySelector = createSelector<
  GlobalState,
  ParamsProps<{ envKey: string; projKey: string }>,
  ProjectRequest,
  EnvironmentsState,
  string,
  EnvironmentRequest
>(
  projectByKeySelector,
  environmentsSelector,
  (state, props) => (props.match ? props.match.params.envKey : props.params!.envKey),
  (project, environmentsState, key) => {
    const env = project
      .getIn(['entity', 'environments'], List())
      .map((id: string) => environmentsState.getIn(['entities', id]))
      .filter((e: Environment) => !!e)
      .find((e: Environment) => e.key === key);

    return fromJS({
      lastFetched: project.get('lastFetched'),
      isFetching: project.get('isFetching'),
      entity: env,
    });
  },
); /* eslint-enable @typescript-eslint/no-non-null-assertion */

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const environmentByIdSelector = createSelector<
  GlobalState,
  ParamsProps<{ envId: string }>,
  ProjectsState,
  EnvironmentsState,
  string,
  EnvironmentEntities
>(
  projectsSelector,
  environmentsSelector,
  (state, props) => (props.match ? props.match.params.envId : props.params!.envId),
  (project, environmentsState, envId) => {
    const env = environmentsState
      .get('entities')
      .filter((e: Environment) => !!e)
      .find((e: Environment) => e._id === envId);
    return fromJS({
      entity: env,
    });
  },
); /* eslint-enable @typescript-eslint/no-non-null-assertion */

export const currentEnvironmentIdSelector = (state: GlobalState) => state.currentEnvironment;

export const currentEnvironmentSelector = createSelector(
  environmentsSelector,
  currentEnvironmentIdSelector,
  (environmentsState, id) =>
    fromJS({
      lastFetched: id.get('lastFetched'),
      doNotFetch: id.get('doNotFetch'),
      isFetching: id.get('isFetching'),
      entity: environmentsState.getIn(['entities', id.get('entity')]),
    }),
);

export function useCurrentEnvironmentEntity(): Environment {
  return useSelector(currentEnvironmentSelector)?.get('entity');
}

export function useCurrentEnvironmentKey() {
  return useCurrentEnvironmentEntity()?.get('key');
}

export function useCurrentProjectEnvironments() {
  const project = useCurrentProjectEntity();
  return useSelector((state) => makeProjectEnvironmentsSelector()(state, { project }));
}

export function useIsApprovalRequiredForFlagInCurrentEnvironment(flag?: Flag) {
  const { approvalSettings } = useCurrentEnvironmentEntity();
  return !!flag?.isApprovalRequired(approvalSettings);
}

export function useBypassRequiredApprovalsInCurrentEnvironment(flag?: Flag) {
  const { key } = useCurrentEnvironmentEntity();
  return flag?.canBypassRequiredApprovals(key);
}

const findCurrentProject = (projectsEntities: ProjectEntities, envId: string | undefined) =>
  envId ? projectsEntities.find((p) => p.environments.includes(envId)) : undefined;

type CurrentProject = StateWithRequestInfo<{
  entity: Project;
  doNotFetch: boolean;
}>;

export const currentProjectSelector = createSelector(
  projectsSelector,
  currentEnvironmentIdSelector,
  (projectsState, currentEnvironmentState) =>
    fromJS({
      lastFetched: projectsState.get('lastFetched'),
      doNotFetch: projectsState.get('doNotFetch'),
      error: projectsState.get('error'),
      isFetching: projectsState.get('isFetching'),
      entity:
        findCurrentProject(projectsState.get('entities'), currentEnvironmentState.get('entity')) || createProject(),
    }) as CurrentProject,
);

export const currentProjectKeySelector = createSelector(
  currentProjectSelector,
  (project) => project.getIn(['entity', 'key']) as string,
);

export const currentProjectEntitySelector = createSelector(currentProjectSelector, (project) =>
  project.getIn(['entity']),
);

export const currentProjectNameSelector = createSelector(currentProjectSelector, (project) =>
  project.getIn(['entity', 'name']),
);

export function useCurrentProjectEntity(): Project {
  return useSelector(currentProjectEntitySelector);
}

export function useCurrentProjectKey() {
  return useSelector(currentProjectKeySelector);
}

export const currentEnvironmentKeySelector = createSelector(currentEnvironmentSelector, (env) =>
  env.getIn(['entity', 'key']),
);

export const currentEnvironmentEntitySelector = createSelector(currentEnvironmentSelector, (environment) =>
  environment.getIn(['entity']),
);

export const currentEnvironmentNameSelector = createSelector(currentEnvironmentSelector, (environment) =>
  environment.getIn(['entity', 'name']),
);
export const currentCriticalEnvironmentSelector = createSelector(currentEnvironmentSelector, (environment) =>
  environment.getIn(['entity', 'critical']),
);

export const currentEnvironmentColorSelector = createSelector(currentEnvironmentSelector, (environment) =>
  environment.getIn(['entity', 'color'])?.css(),
);

export function getCheckAccessResourceProject(state = false, action: ProjectAction) {
  switch (action.type) {
    case 'projects/CHECK_ACCESS_RESOURCE':
      return action.willRemoveEditingAbility;
    case 'projects/UPDATE_PROJECT_DONE':
      return false;
    default:
      return state;
  }
}

export function getCheckAccessResourceEnvironment(state = false, action: EnvironmentAction) {
  switch (action.type) {
    case 'environments/CHECK_ACCESS_RESOURCE':
      return action.willRemoveEditingAbility;
    case 'environments/UPDATE_ENVIRONMENT_DONE':
      return false;
    default:
      return state;
  }
}

export const environmentFiltersSelector = createSelector(searchSelector, (query) =>
  createEnvironmentsFiltersFromQuery(query),
);

export const projectFilterSelector = createSelector(searchSelector, (query) => createProjectFiltersFromQuery(query));

export const projectRequestByKeySelector = createSelector<
  GlobalState,
  { projectKey: string },
  Map<string, Request>,
  string,
  Request | undefined
>(
  (state) => state.projectRequestByKey,
  (_, props) => props.projectKey,
  (requests, projectKey) => requests.get(projectKey),
);

registry.addReducers({
  currentEnvironment,
  environments,
  projectEnvironments,
  paginatedProjects,
  paginatedProjectEnvironments,
  environmentTextFilters,
  projects,
  projectRequestByKey,
});
