import { QueryKey as RQQueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

type SafeQueryOptions<QueryFnData, Error, Data, QueryKey extends RQQueryKey> = Omit<
  UseQueryOptions<QueryFnData, Error, Data, QueryKey>,
  'queryKey' | 'queryFn' | 'queryKeyHashFn'
>;

export function createQueryHook<
  Dependencies,
  QueryFnData = unknown,
  Error = unknown,
  Data = QueryFnData,
  QueryKey extends RQQueryKey = RQQueryKey,
>(queryFactory: (deps: Dependencies) => UseQueryOptions<QueryFnData, Error, Data, QueryKey>) {
  return (deps: Dependencies, options?: SafeQueryOptions<QueryFnData, Error, Data, QueryKey>) =>
    useQuery({ ...options, ...queryFactory(deps) });
}
