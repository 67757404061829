import { minPasswordLength, passwordMinCharsPerClass, passwordMinClasses } from '@gonfalon/constants';
import { Record } from 'immutable';

import { checkPasswordCharacters, isEmail, isLength, isNotEmpty, matches, validateRecord } from 'utils/validationUtils';

interface ForgotPasswordProps {
  username: string;
  accountId?: string;
}

export class ForgotPassword extends Record<ForgotPasswordProps>({ username: '', accountId: undefined }) {
  validate() {
    return validateRecord(this, isNotEmpty('username'), isEmail('username'));
  }
}

export function createForgotPassword(props = {}) {
  return new ForgotPassword(props);
}

const ResetPasswordProps = {
  password: '',
  confirm: '',
};

export class ResetPassword extends Record(ResetPasswordProps) {
  validate() {
    return validateRecord(
      this,
      isNotEmpty('password'),
      isLength(minPasswordLength())('password'),
      checkPasswordCharacters({
        requiredCharCountPerClass: passwordMinCharsPerClass(),
        requiredClassCount: passwordMinClasses(),
      })('password'),
      isNotEmpty('confirm'),
      matches('password')('confirm'),
    );
  }
}

export function createResetPassword(props = {}) {
  return new ResetPassword(props);
}
