import { deleteFlagImportConfiguration } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { flagImport } from './internal/queries';

export const useDeleteFlagImportIntegration = createMutationHook(deleteFlagImportConfiguration, {
  onSuccess: async (data, { projectKey, integrationKey, integrationId }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: flagImport.detail({
        projectKey,
        integrationKey,
        integrationId,
      }).queryKey,
    });
    await queryClient.invalidateQueries(flagImport.list);
  },
});
