import { createBigSegmentStoreIntegration } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { bigSegmentStore } from './internal/queries';

export const useCreateBigSegmentStore = createMutationHook(createBigSegmentStoreIntegration, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries(bigSegmentStore.list);
  },
});
