import { GetLayersOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface GetLayersQueryParams extends GetLayersOpenAPIQueryParams {
  environments?: string;
}

export function querySerializer(queryParams: GetLayersQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.environments) {
    searchParams.set('environments', queryParams.environments);
  }

  if (queryParams.filter) {
    searchParams.set('filter', queryParams.filter);
  }

  return searchParams.toString();
}
