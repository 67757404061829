import { createContext, ReactNode, useContext } from 'react';
import { checkAccessContextUsageError } from '@gonfalon/dogfood-flags';
import { Access } from '@gonfalon/permissions';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, Record, Set } from 'immutable';
import nullthrows from 'nullthrows';

import { Member } from 'utils/accountUtils';

import { ImmutableMap } from './immutableUtils';
import Logger from './logUtils';

export type AccessReason = ImmutableMap<{
  actions: List<string>;
  effect: 'allow' | 'deny';
  resources: List<string>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  role_name: string;
}>;

export type DeniedAccess = ImmutableMap<{
  action: string;
  reason: AccessReason;
}>;

export type AllowedAccess = ImmutableMap<{
  action: string;
  reason: AccessReason;
}>;

export type AccessChecks = ImmutableMap<{
  denied: List<DeniedAccess>;
  allowed: List<AllowedAccess>;
}>;

export type MayHaveAccessInfo = {
  _access?: Access;
};

export type CheckAccessFunction = (action?: string) => AccessDecision;

type AccessDecisionType = {
  isAllowed: boolean;
  appliedRoleName: string;
  isServiceToken: boolean;
  customMessage: string;
  reason: AccessReason;
};

export class AccessDecision extends Record<AccessDecisionType>({
  isAllowed: true,
  reason: Map(),
  appliedRoleName: '',
  isServiceToken: false,
  customMessage: '',
}) {
  getActionReason() {
    if (this.customMessage) {
      return this.customMessage;
    }

    if (!this.isAllowed) {
      return this.appliedRoleName
        ? `The "${this.appliedRoleName}" role does not allow you to perform this action`
        : 'Your permissions don’t allow you to perform this action';
    }
    return '';
  }

  getModifyFieldReason() {
    if (this.customMessage) {
      return this.customMessage;
    }
    if (!this.isAllowed) {
      return this.appliedRoleName
        ? `The "${this.appliedRoleName}" role does not allow you to modify this field`
        : 'Your permissions don’t allow you to modify this field';
    }
    return '';
  }

  getChangeSettingReason() {
    if (this.customMessage) {
      return this.customMessage;
    }
    if (!this.isAllowed) {
      return this.appliedRoleName
        ? `The "${this.appliedRoleName}" role does not allow you to change this setting`
        : 'Your permissions don’t allow you to change this setting';
    }
    return '';
  }

  getServiceTokenReason() {
    if (this.customMessage) {
      return this.customMessage;
    }
    return this.isServiceToken ? 'You may not change the roles of a service token' : '';
  }
}

export function createAccessDecision(props = {}) {
  if (props instanceof AccessDecision) {
    return props;
  }
  return new AccessDecision(fromJS(props));
}

export function combineAccessDecisions(...args: Array<AccessDecision | undefined | null>) {
  const decisions = List(args.filter((d) => !!d));
  const isAllowed = decisions.every((d) => d.isAllowed);
  const firstDenied = decisions.find((d) => !d.isAllowed);
  return new AccessDecision({
    isAllowed,
    reason: firstDenied ? firstDenied.reason : decisions.first<AccessDecision>().reason,
    appliedRoleName: firstDenied ? firstDenied.appliedRoleName : decisions.first<AccessDecision>().appliedRoleName,
    customMessage: firstDenied?.customMessage,
  });
}

export const allowDecision = () => createAccessDecision({ isAllowed: true });
export const denyDecision = () => createAccessDecision({ isAllowed: false });

export function getAccessDecisionForCollection<T>(collection: List<T>, checkAccess: (resource: T) => AccessDecision) {
  if (!collection || collection.isEmpty()) {
    return denyDecision();
  }
  const accesses = collection.map((role) => checkAccess(role));
  const allowedAccess = accesses.find((access) => access.isAllowed);
  return allowedAccess || accesses.first<AccessDecision>();
}

function getTagsClause(tags: ArrayLike<string> | Set<string>) {
  if (tags) {
    const tagsArray = Array.from(tags);
    if (tagsArray.length > 0) {
      return `;${Array.from(tagsArray).join()}`;
    }
  }
  return '';
}

export function makeResourceSpec(
  projectKey: string,
  envKey: string,
  resourceKind: string,
  resourceKey: string,
  tags: ArrayLike<string> | Set<string>,
) {
  return `proj/${projectKey}:env/${envKey}:${resourceKind}/${resourceKey}${getTagsClause(tags)}`;
}

export function makeProjectSpec(projectKey: string, tags: ArrayLike<string>) {
  return `proj/${projectKey}${getTagsClause(tags)}`;
}

export function makeEnvironmentSpec(projectKey: string, environmentKey: string, tags: ArrayLike<string>) {
  return `proj/${projectKey}:env/${environmentKey}${getTagsClause(tags)}`;
}

export function newAccessDeniesAction(oldAccess: List<DeniedAccess>, newAccess: List<DeniedAccess>, action: string) {
  const oldAccessMap = Map(oldAccess.map((i) => [i.get('action'), i.get('reason')]));
  const newAccessMap = Map(newAccess.map((i) => [i.get('action'), i.get('reason')]));
  return newAccessMap.has(action) && !oldAccessMap.has(action);
}

type AccessesRep = Record<{
  accesses: Array<{
    [key: string]: {
      denied: List<DeniedAccess>;
    };
  }>;
}>;

export function accessResponseDeniesUpdateTags(
  accesses: AccessesRep,
  newResourceSpec: string,
  oldAccessDenied: List<DeniedAccess>,
) {
  const newAccessDenied = accesses.getIn(['accesses', newResourceSpec, 'denied']);
  return newAccessDenied ? newAccessDeniesAction(oldAccessDenied, newAccessDenied, 'updateTags') : false;
}

const CheckAccessContext = createContext<CheckAccessFunction | undefined>(
  checkAccessContextUsageError() ? undefined : allowDecision,
);

export function CheckAccessProvider({ value, children }: { value: CheckAccessFunction; children: ReactNode }) {
  return <CheckAccessContext.Provider value={value}>{children}</CheckAccessContext.Provider>;
}

/**
 * @deprecated `useCheckAccess` is not recommended for new use.
 * When used outside of an instance of `<CheckAccessProvider />`, it will default to allowing access to _all_ actions.
 * Use `checkAccess` from `@gonfalon/permissions` to check access to actions directly upon a resource's access object instead.
 */
export function useCheckAccess() {
  const contextValue = useContext(CheckAccessContext);

  if (checkAccessContextUsageError() && !contextValue) {
    Logger.get('accessUtils').warn(
      'useCheckAccess() called outside of <CheckAccessProvider />. This will cause all access checks to be allowed!',
    );

    return allowDecision;
  }

  return nullthrows(contextValue, 'expected CheckAccessContext value to be a CheckAccessFunction');
}

type MockAccessDecisions = {
  [action: string]: AccessDecision; // this allows us to define multiple AccessDecisions
};

export const mockDecisions = (actions: MockAccessDecisions): CheckAccessFunction =>
  function checkAccess(actionToMock: string = ''): AccessDecision {
    if (Object.keys(actions).includes(actionToMock)) {
      return actions[actionToMock];
    }

    return denyDecision();
  };

// This is a generic checkAccess that may or may not be appropriate for every resource. Pass this function into the CheckAccessProvider
export const checkAccess =
  (profile: Member) =>
  (item?: MayHaveAccessInfo): CheckAccessFunction => {
    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (profile.isAdmin() || profile.isWriter() || profile.isOwner() || !item?._access?.denied) {
      return allowDecision;
    }

    return (action?: string) => {
      const deniedAction = item._access?.denied.find((v) => v.action === action);
      if (deniedAction) {
        const reason = deniedAction.reason;
        const roleName = reason && reason.role_name;
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  };

// If you want to combine multiple entities to check for access against
// use this helper to create a single MayHaveAccessInfo item for the checkAccess function
export const combineAccessItems = (items: MayHaveAccessInfo[]) =>
  items.reduce(
    (acc, item) =>
      ({
        _access: {
          allowed: acc?._access?.allowed?.concat(item?._access?.allowed || []),
          denied: acc?._access?.denied?.concat(item?._access?.denied || []),
        },
      }) as MayHaveAccessInfo,
    { _access: { allowed: [], denied: [] } } as MayHaveAccessInfo,
  );
