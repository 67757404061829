import { type GetMigrationFlagMetricsQueryParams } from '@gonfalon/openapi';

import { createQueryHook } from './internal/createQueryHook';
import { migrationMetrics } from './internal/queries';

export const useMigrationMetricsForCohort = createQueryHook(
  ({
    projectKey,
    flagKey,
    environmentKey,
    ruleId,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    ruleId: string;
    params: Omit<GetMigrationFlagMetricsQueryParams, 'rules'>;
  }) => migrationMetrics.detail({ projectKey, flagKey, environmentKey, ruleId, params }),
);
