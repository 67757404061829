import { updateLayer } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { layers } from './internal/queries';

export const useUpdateLayer = createMutationHook(updateLayer, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: layers.list._def });
  },
});
