import gravatarUrl from 'gravatar-url';

declare global {
  /* eslint-disable no-var, @typescript-eslint/naming-convention */
  var __DEV__: boolean;
  var __TEST__: boolean;
  /* eslint-enable no-var, @typescript-eslint/naming-convention */
}

export function getGravatarUrl(email: string | null | undefined, options: { default: string; size?: number }) {
  if ((__DEV__ || __TEST__) && options?.default !== '404') {
    // See https://en.gravatar.com/site/implement/images/ for Gravatar reference.
    //
    // In development, we use the default image directly because of this requirement:
    //
    // 1. MUST be publicly available (e.g. cannot be on an intranet, on a local development machine,
    //    behind HTTP Auth or some other firewall etc). Default images are passed through a security
    //    scan to avoid malicious content.
    return options.default || '';
  }
  return gravatarUrl(email || '', options);
}
