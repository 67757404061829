import { postFlagCleanup } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { flags } from './internal/queries';

export const useFlagCleanup = createMutationHook(postFlagCleanup, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flags.getCleanup._def });
    await queryClient.invalidateQueries({ queryKey: flags.cleanup._def });
  },
});
