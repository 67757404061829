import type { Options as ScrollOptions } from 'scroll-into-view-if-needed';
import scrollIntoView from 'scroll-into-view-if-needed';

// per the docs (https://www.npmjs.com/package/scroll-into-view-if-needed), the default for scrollMode is 'always'
export function scrollIntoViewIfNeeded(
  el: Element,
  block: ScrollLogicalPosition = 'nearest',
  scrollMode: ScrollOptions['scrollMode'] = 'always',
) {
  scrollIntoView(el, { behavior: 'smooth', block, inline: 'nearest', scrollMode });
}
