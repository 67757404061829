import { patchProject } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';

// TODO: move this somewhere else: it's a wrapper around patchProject that does not belong in this package.
export const useUpdateProjectDefaultReleasePipeline = createMutationHook(patchProject, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: ['release-pipelines'] });
  },
});
