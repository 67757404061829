import { DEFAULT_LOCALE } from './constants';

export function conditionallyFormatAsSciENotation(
  value: number | string | null | undefined,
  options: Intl.NumberFormatOptions = {},
) {
  const num = Number(value);

  // Filter out undefined and strings which are not numbers
  if (isNaN(num)) {
    return '';
  }

  const formatOptions: Intl.NumberFormatOptions = {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
    ...options,
  };

  const isPercent = formatOptions.style === 'percent';
  const exponentialStr = num.toExponential();
  const splitExponentialStr = exponentialStr.split('e');
  const exponent = isPercent ? Number(splitExponentialStr[1]) + 2 : Number(splitExponentialStr[1]);

  if (exponent < 0 - (formatOptions.maximumFractionDigits || 1)) {
    formatOptions.notation = 'scientific';
  }

  return num.toLocaleString(DEFAULT_LOCALE, { ...formatOptions });
}
