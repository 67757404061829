import { GetExperimentResultsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface GetExperimentResultsQueryParams extends GetExperimentResultsOpenAPIQueryParams {
  sumMetrics?: boolean;
  defaultMissingMetrics?: boolean;
  attributes?: Array<{ name: string; values: Array<string | number | boolean> }>;
}

export function querySerializer(queryParams: GetExperimentResultsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.iterationId) {
    searchParams.set('iterationId', queryParams.iterationId);
  }

  if (typeof queryParams.sumMetrics !== 'undefined') {
    searchParams.set('sumMetrics', String(queryParams.sumMetrics));
  }

  if (typeof queryParams.defaultMissingMetrics !== 'undefined') {
    searchParams.set('defaultMissingMetrics', String(queryParams.defaultMissingMetrics));
  }

  if (queryParams.attributes) {
    searchParams.set('attributes', JSON.stringify(queryParams.attributes));
  }

  return searchParams.toString();
}
