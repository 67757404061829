import { ResourceSpecifier } from './ast';

const memberScopedResources = ['token'] as const satisfies Array<ResourceSpecifier['type']>;

export type MemberScopedResource = Extract<ResourceSpecifier, { type: (typeof memberScopedResources)[number] }>;

export const isMemberScopedResourceType = (resourceType: ResourceSpecifier['type']) =>
  (memberScopedResources as readonly string[]).includes(resourceType);

export const isMemberScopedResource = (resource: ResourceSpecifier): resource is MemberScopedResource =>
  isMemberScopedResourceType(resource.type);
