import { beastModeExpiration } from '@gonfalon/constants';

let expiration = beastModeExpiration() ?? 0;

export function getExpiration() {
  return expiration;
}

export function setExpiration(timestamp: number) {
  expiration = timestamp;
}
