import { useLayoutEffect, useMemo, useRef } from 'react';
import { debounce } from '@gonfalon/es6-utils';

/**
 * This creates a stable debounced callback. It grabs the
 * latest version of the callback after rendering.
 * We don't have to include deps, more info here: https://epicreact.dev/the-latest-ref-pattern-in-react/
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounce<T extends (...args: any) => any>(callback: T, delay: number) {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });
  return useMemo(() => debounce(((...args) => callbackRef.current(...args)) as T, delay), [delay]);
}
