import { deleteReleaseByFlagKey } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { flags } from './internal/queries';

export const useDeleteRelease = createMutationHook(deleteReleaseByFlagKey, {
  onSuccess: async (data, { projectKey, flagKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flags.detail({ projectKey, flagKey }).queryKey });
    // since the request will now return a 404 error, resetting the query rather than invalidating it actually updates the UI
    await queryClient.resetQueries({ queryKey: flags.detail({ projectKey, flagKey })._ctx.release.queryKey });
  },
});
