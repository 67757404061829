import { type GetExperimentQueryParams } from '@gonfalon/openapi';

import { createQueryFetcher } from './internal/createQueryFetcher';
import { experiments } from './internal/queries';

export const fetchExperiment = createQueryFetcher(
  ({
    projectKey,
    environmentKey,
    experimentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    params?: GetExperimentQueryParams;
  }) => experiments.detail({ projectKey, environmentKey, experimentKey, params }),
);
