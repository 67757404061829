import { type GetExperimentResultsQueryParams } from '@gonfalon/openapi';
import { QueryOptions, useQueries } from '@tanstack/react-query';

import { createQueryHook } from './internal/createQueryHook';
import { experiments } from './internal/queries';

export const useExperimentResults = createQueryHook(experimentResultsQuery);

export function useExperimentResultsQueries(
  {
    projectKey,
    environmentKey,
    experimentKey,
    metricKeys,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    metricKeys: string[];
    params?: GetExperimentResultsQueryParams;
  },
  options?: QueryOptions,
) {
  return useQueries({
    ...options,
    queries: metricKeys.map((metricKey) =>
      experimentResultsQuery({ projectKey, environmentKey, experimentKey, metricKey, params }),
    ),
  });
}

function experimentResultsQuery({
  projectKey,
  environmentKey,
  experimentKey,
  metricKey,
  params,
}: {
  projectKey: string;
  environmentKey: string;
  experimentKey: string;
  metricKey: string;
  params?: GetExperimentResultsQueryParams;
}) {
  return experiments.detail({ projectKey, environmentKey, experimentKey })._ctx.results({ metricKey, params });
}
