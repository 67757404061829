import { useMemo } from 'react';
import { createRoutesFromChildren, matchRoutes, RouteMatch, useLocation } from 'react-router-dom';
import { AnyRoute, AppRoute, UnauthenticatedRoute } from '@gonfalon/router';

function generateMatchingPathPattern<T = AnyRoute>(
  matches: RouteMatch[] | ReturnType<typeof matchRoutes>,
  { notFoundPattern, fallbackPattern }: { notFoundPattern?: T; fallbackPattern?: T },
) {
  if (!matches || matches.length === 0) {
    return fallbackPattern;
  }

  if (matches.length === 1 && matches[0].route.path === '*') {
    return notFoundPattern;
  }

  let pathPattern = '';
  for (const match of matches) {
    const path = match.route.path;
    if (!path || path === '/') {
      continue;
    }
    pathPattern = pathPattern.concat(path?.startsWith('/') ? path : `/${path}`);
  }

  if (!pathPattern.startsWith('/')) {
    pathPattern = `/${pathPattern}`;
  }

  return pathPattern as T;
}

export const generateMatchingAppPathPattern = generateMatchingPathPattern<AppRoute>;

function useMatches(routes: JSX.Element): RouteMatch[] | null {
  const routeConfig = useMemo(() => createRoutesFromChildren(routes), [routes]);
  return matchRoutes(routeConfig, useLocation());
}

function useMatchingPathPattern<T = AnyRoute>(
  routes: JSX.Element,
  { notFoundPattern, fallbackPattern }: { notFoundPattern?: T; fallbackPattern?: T } = {},
): T | undefined {
  const children: JSX.Element = routes.props.children;
  const matches = useMatches(children);
  return generateMatchingPathPattern<T>(matches, { notFoundPattern, fallbackPattern });
}

export const useMatchingAppPathPattern = useMatchingPathPattern<AppRoute>;

export const useMatchingUnauthenticatedPathPattern = useMatchingPathPattern<UnauthenticatedRoute>;
