// eslint-disable-next-line no-restricted-imports
import { useSelector as useReduxSelector } from 'react-redux';
import type { GlobalState } from 'reducers';

export function useSelector<TSelected>(
  selector: (state: GlobalState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
) {
  return useReduxSelector<GlobalState, TSelected>(selector, equalityFn);
}
