import { createQueryHook } from './internal/createQueryHook';
import { monitoringErrorsList } from './internal/queries';

export const useMonitoringErrors = createQueryHook(
  ({
    projectKey,
    issueId,
    search,
    limit,
    offset,
  }: {
    projectKey: string;
    issueId: string;
    search?: string;
    limit?: number;
    offset?: number;
  }) => monitoringErrorsList({ projectKey, issueId, query: { search, limit, offset } }),
);
