export class RESTAPIError extends Error {
  name = 'RESTAPIError';
  status: number;
  url: string;
  code: string;
  message: string;

  constructor({
    status,
    url,
    code,
    message,
    cause,
  }: {
    status: number;
    url: string;
    code: string;
    message: string;
    cause?: unknown;
  }) {
    super(message);
    this.status = status;
    this.url = url;
    this.code = code;
    this.message = message;
    this.cause = cause;
  }

  toJSON() {
    return { status: this.status, url: this.url, code: this.code, message: this.message };
  }
}

export function isRESTAPIError(error: unknown): error is RESTAPIError {
  return error instanceof RESTAPIError;
}

export class NetworkError extends Error {
  name = 'NetworkError';
  message: string;

  constructor({ message, cause }: { message: string; cause?: unknown }) {
    super(message);
    this.message = message;
    this.cause = cause;
  }

  toJSON() {
    return { message: this.message };
  }
}

export function isNetworkError(error: unknown): error is NetworkError {
  return error instanceof NetworkError;
}
