import { schemas } from '@gonfalon/openapi';

type Access = schemas['Access'];
type Member = schemas['Member'];

type ApplicationActions = 'createApplication' | 'updateName' | 'updateKind' | 'updateMaintainer' | 'deleteApplication';
type ApplicationVersionActions =
  | 'createApplicationVersion'
  | 'updateName'
  | 'updateSupport'
  | 'deleteApplicationVersion';
type Actions = ApplicationActions | ApplicationVersionActions;

// TODO: Move to more general Access package once refactored

const isDenied = (access: Access, action: string) => {
  if (!access || !access.denied || !access.denied.length) {
    return false;
  }
  return access.denied.some((item) => item.action === action);
};

const hasCustomRoles = (profile: Member) => !!(profile?.customRoles?.length > 0);
const hasTeamCustomRoles = (profile: Member) => !!profile?.teams?.some((team) => team.customRoleKeys.length > 0);
const isOwnerOrAdmin = (profile: Member) => ['admin', 'owner'].includes(profile.role);
const hasStrictWriterRights = (profile: Member) => ['admin', 'owner', 'writer'].includes(profile.role);

const checkAccess = (profile: Member, access: Access, action: Actions) => {
  // Although TS requires a profile, there might be times where the async JS
  // call hasn't returned one; we'll return false if one isn't passed in.
  if (!profile) {
    return false;
  }
  // Owners, Admins, and Writers can always create/edit/delete applications.
  if (hasStrictWriterRights(profile)) {
    return true;
  }
  // Members who aren't admins or owners will require custom roles to be able to create/edit/delete applications.
  // We'll early-return if they don't have any.
  if (!(hasCustomRoles(profile) || hasTeamCustomRoles(profile))) {
    return false;
  }
  // At this point in the logic, we'll need to evaluate the `access` block.
  // If the `access` block's `denied` array contains an action matching the one we're checking, we'll return false.
  if (isDenied(access, action)) {
    return false;
  }
  // If none of the above conditions are met, we return true and the member can take the specified action.
  return true;
};

// We'll let Admins, Owners, and Writers create applications. This avoids an issue where we'd need to evaluate
// permissions on a non-existent Application _access block.
const canCreateApplication = (profile: Member) => hasStrictWriterRights(profile);

const canUpdateApplication = (profile: Member, access: Access) =>
  checkAccess(profile, access, 'updateName') ||
  checkAccess(profile, access, 'updateKind') ||
  checkAccess(profile, access, 'updateMaintainer');

const canDeleteApplication = (profile: Member, access: Access) => checkAccess(profile, access, 'deleteApplication');

// We'll let Admins, Owners, and Writers create application versions. This avoids an issue where we'd need to evaluate
// permissions on a non-existent Application _access block.
const canCreateApplicationVersion = (profile: Member) => hasStrictWriterRights(profile);

const canUpdateApplicationVersion = (profile: Member, access: Access) =>
  checkAccess(profile, access, 'updateName') || checkAccess(profile, access, 'updateSupport');

const canDeleteApplicationVersion = (profile: Member, access: Access) =>
  checkAccess(profile, access, 'deleteApplicationVersion');

export {
  canCreateApplication,
  canCreateApplicationVersion,
  canUpdateApplication,
  canUpdateApplicationVersion,
  canDeleteApplication,
  canDeleteApplicationVersion,
  checkAccess,
  hasStrictWriterRights,
  isDenied,
  isOwnerOrAdmin,
};
