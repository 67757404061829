import { and, contains, equals, or, QueryFilter, queryfilterToString } from '@gonfalon/queryfilter';

import { GetApplicationVersionsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface GetApplicationVersionsQueryParams
  extends Omit<GetApplicationVersionsOpenAPIQueryParams, 'sort' | 'filter'> {
  sort?: 'name' | '-name' | 'creationDate' | '-creationDate';
  filter?: {
    key?: string;
    name?: string;
    supported?: boolean;
    autoAdded?: boolean;
  };
}

export function querySerializer(params: GetApplicationVersionsQueryParams) {
  const searchParams = new URLSearchParams();

  if ((params.limit ?? 0) > 0) {
    searchParams.set('limit', String(params.limit));
  }

  if ((params.offset ?? 0) > 0) {
    searchParams.set('offset', String(params.offset));
  }

  if (params.sort) {
    searchParams.set('sort', String(params.sort));
  }

  if (params.filter) {
    const serializedFilter = serializeApplicationVersionFilterParam(params.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  return searchParams.toString();
}

export function serializeApplicationVersionFilterParam(
  value: NonNullable<GetApplicationVersionsQueryParams['filter']>,
) {
  const matches: QueryFilter[] = [];
  const query: QueryFilter[] = [];

  if (value.key) {
    query.push(contains('key', [value.key]));
  }

  if (value.name) {
    query.push(contains('name', [value.name]));
  }

  if (value.supported) {
    matches.push(equals('supported', value.supported));
  }

  if (value.autoAdded) {
    matches.push(equals('autoAdded', value.autoAdded));
  }

  return query.length ? queryfilterToString(and([or(query), and(matches)])) : queryfilterToString(and(matches));
}
