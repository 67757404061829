import { createTaskRunner } from '@gonfalon/async';
import { chunk } from '@gonfalon/collections';
import { measuredRolloutStatusFlagKeyLimit } from '@gonfalon/dogfood-flags';
import { internalPostFlagsMeasuredRolloutStatuses, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type MeasuredRolloutStatusTask = {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
};

type SchemaWithEnvironment = schemas['FlagMeasuredRolloutStatus'] & {
  environmentKey: string;
};

type GetMeasuredRolloutStatusesPerEnvironmentWithEnvironment = {
  items: Array<{
    environmentKey: string;
    flagKey: string;
    measuredRolloutStatus: schemas['FlagMeasuredRolloutStatus']['measuredRolloutStatus'];
  }>;
};

export const internalPostFlagsMeasuredRolloutStatusesTaskRunner = createTaskRunner<
  MeasuredRolloutStatusTask,
  SchemaWithEnvironment[],
  GetMeasuredRolloutStatusesPerEnvironmentWithEnvironment
>({
  runner: async (tasks) => {
    const environmentKeys = Array.from(new Set(tasks.map((input) => input.environmentKey)));
    const results = await Promise.all(
      environmentKeys.map(async (environmentKey) => {
        const response = await reactQueryResponseAdapter(
          internalPostFlagsMeasuredRolloutStatuses({
            projectKey: tasks[0].projectKey,
            environmentKey,
            body: {
              flagKeys: Array.from(new Set(tasks.flatMap((input) => input.flagKey))),
            },
          }),
        );

        return {
          items: [
            ...response.items.map((item) => {
              return {
                ...item,
                environmentKey,
              };
            }),
          ],
        };
      }),
    );

    return {
      items: results.flatMap((result) => result.items),
    };
  },
  resolver: (input, task) => {
    const { items } = input;
    const results = items.find((item) => item.flagKey === task.flagKey && item.environmentKey === task.environmentKey);

    if (!results) {
      return [];
    }

    return [
      {
        flagKey: task.flagKey,
        measuredRolloutStatus: results.measuredRolloutStatus,
        environmentKey: results.environmentKey,
      },
    ];
  },
  batching: {
    batcher: internalPostFlagsMeasuredRolloutStatusesTaskBatcher,
    resultMerger(data) {
      return {
        items: data.flatMap((item) => item.items),
      };
    },
  },
});

export function internalPostFlagsMeasuredRolloutStatusesTaskBatcher(inputs: MeasuredRolloutStatusTask[]) {
  const flagKeyChunks = chunk(
    Array.from(new Set(inputs.map((input) => input.flagKey))),
    measuredRolloutStatusFlagKeyLimit(),
  );

  const environmentKeyChunks = chunk(Array.from(new Set(inputs.map((input) => input.environmentKey).flat())), 1);

  const batches: MeasuredRolloutStatusTask[][] = [];

  environmentKeyChunks.forEach((chunkOfEnvironmentKeys) => {
    flagKeyChunks.forEach((chunkOfFlagKeys) => {
      const tasks: MeasuredRolloutStatusTask[] = [];
      chunkOfEnvironmentKeys.forEach((environmentKey) => {
        chunkOfFlagKeys.forEach((flagKey) => {
          tasks.push({ projectKey: inputs[0].projectKey, flagKey, environmentKey });
        });
      });
      batches.push(tasks);
    });
  });

  return batches;
}
