import { useSuspenseEnvironment } from '@gonfalon/rest-api';

import { createEnvironmentConfirmationSchema } from './internal/environmentConfirmationSchema';

export function useEnvironmentChangeConfirmation({
  projectKey,
  environmentKey,
}: {
  projectKey: string;
  environmentKey: string;
}) {
  const environment = useSuspenseEnvironment({ projectKey, environmentKey });
  const isCommentRequired = environment.data.requireComments;
  const isVerificationRequired = environment.data.confirmChanges;

  const change = {
    isConfirmationRequired: isCommentRequired || isVerificationRequired,
    isCommentRequired,
    isVerificationRequired,
  };

  return {
    change,
    schema: createEnvironmentConfirmationSchema(environmentKey, change),
  };
}
