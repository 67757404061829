import { arrayRemove } from '@gonfalon/collections';
import { deleteShortcut } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { shortcuts } from './internal/queries';

export const useDeleteShortcut = createMutationHook(deleteShortcut, {
  onMutate: async (variables) => {
    const queryOptions = shortcuts.list({ projectKey: variables.projectKey });
    const queryKey = queryOptions.queryKey;

    type QueryData = Awaited<ReturnType<(typeof queryOptions)['queryFn']>>;

    await getQueryClient().cancelQueries({ queryKey });

    const previousShortcuts = getQueryClient().getQueryData<QueryData>(queryKey);

    getQueryClient().setQueryData<QueryData>(queryKey, (old) => {
      if (old === undefined) {
        return;
      }

      const index = old?.items.findIndex((shortcut) => shortcut.key === variables.shortcutKey);
      if (index > -1) {
        return {
          ...old,
          items: arrayRemove(old.items, index),
        };
      }

      return old;
    });

    return { previousShortcuts };
  },
  onError: (error, variables, context) => {
    const queryOptions = shortcuts.list({ projectKey: variables.projectKey });
    const queryKey = queryOptions.queryKey;
    getQueryClient().setQueryData(queryKey, context?.previousShortcuts);
  },
  onSuccess: async (data, variables) => {
    const queryOptions = shortcuts.list({ projectKey: variables.projectKey });
    const queryKey = queryOptions.queryKey;
    await getQueryClient().invalidateQueries({ queryKey });
  },
});
