import { getAccount, patchAccount } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { createQueryOptions } from './internal/createQueryOptions';
import { accountDetail } from '.';

export const getAccountQuery = createQueryOptions(getAccount);
export const useUpdateAccount = createMutationHook(patchAccount, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: accountDetail.queryKey });
  },
});
