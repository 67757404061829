import { patchIssue } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { monitoringIssuesList } from './internal/queries';

async function patchSemantically(
  input: Parameters<typeof patchIssue>[0],
  options: Parameters<typeof patchIssue>[1] = {},
) {
  return patchIssue(input, {
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
    },
  });
}

export const useUpdateIssue = createMutationHook(patchSemantically, {
  onSuccess: async (data, { projectKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: monitoringIssuesList.partialQueryKey({ projectKey }) });
  },
});
