import { isThirdPartyScriptsEnabled } from '@gonfalon/dogfood-flags';
import { getGravatarUrl } from '@gonfalon/gravatar';
import { Avatar } from '@launchpad-ui/core';
import type { IconProps } from '@launchpad-ui/icons';
import { Icon } from '@launchpad-ui/icons';
import clsx from 'clsx';

export type MemberAvatarProps = {
  size: IconProps['size'];
  email: string | undefined;
  initials: string | undefined;
  className?: string;
};

export function MemberAvatar({ email, initials, size, className }: MemberAvatarProps) {
  const useGravatar = isThirdPartyScriptsEnabled();

  let url = '';
  if (useGravatar && email !== undefined) {
    url = getGravatarUrl(email, {
      // instruct gravatar to 404 if it has no image for the given email
      // this is handled in Avatar with a fallback to our default
      default: '404',
      size: 48,
    });
  }

  return (
    <Avatar
      size={size || undefined}
      url={url}
      initials={initials}
      defaultIcon={<Icon size={size} name="person" />}
      className={clsx('fs-exclude', className)}
    />
  );
}
