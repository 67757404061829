import { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { getScrollParent, scrollIntoViewport } from '@react-aria/utils';

export type UseScrollIntoViewProps = {
  isDisabled?: boolean;
};

export function useScrollIntoViewOnMount<T extends HTMLElement>({ isDisabled }: UseScrollIntoViewProps = {}) {
  const { ref, inView } = useInView();

  const callbackRef = useCallback((node: T | null) => {
    ref(node);

    if (node && !inView && !isDisabled) {
      scrollIntoViewport(node, { containingElement: getScrollParent(node) });
    }
  }, []);

  return { ref: callbackRef };
}
