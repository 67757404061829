import { Controller, Form, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { isRESTAPIError, JSONPatch, usePatchMember, usePatchTeam } from '@gonfalon/rest-api';
import { Button, ButtonGroup, ProgressBar, SnackbarQueue, Tag, ToastQueue } from '@launchpad-ui/components';

import { getRoleAttributesFromPolicy } from '../../getRoleAttributesFromPolicy';
import { CustomRole } from '../../internal/types';
import { TagGroupInput } from '../../TagGroupInput';
import { AssignAccessModalProps } from '../AssignAccessModal';

import { AssignAccessFormValues } from './schema';

import styles from './AssignAccessForm.module.css';

export type AssignAccessFormProps = AssignAccessModalProps & { role: CustomRole; closeModal: () => void };

export const AssignAccessForm = ({
  role,
  forResource,
  member,
  team,
  createJsonPatch,
  closeModal,
}: AssignAccessFormProps) => {
  const isForTeam = forResource === 'team';
  const { mutate: updateMember, isPending: isUpdatingMember } = usePatchMember();
  const { mutate: updateTeam, isPending: isUpdatingTeam } = usePatchTeam();

  const roleAttributes = getRoleAttributesFromPolicy(role.policy);

  const isUpdating = isUpdatingMember || isUpdatingTeam;

  const getDefaultResources = (attributeKey: string) => {
    if (isForTeam && team) {
      // TODO - remove this "any" once we have the correct type for team
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const _team = team as any;
      return _team.roleAttributes?.[attributeKey] || [];
    }

    if (member) {
      return member.roleAttributes?.[attributeKey] || [];
    }

    return [];
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<AssignAccessFormValues>({
    defaultValues: {
      attributes: roleAttributes.map(({ attribute, type }) => ({
        attribute,
        type,
        resources: getDefaultResources(attribute),
      })),
    },
  });

  const { fields: attributeFields } = useFieldArray({
    control,
    name: 'attributes',
  });

  const onSubmit: SubmitHandler<AssignAccessFormValues> = (data) => {
    if (isForTeam && team && team.key) {
      // TODO - implement
      // https://launchdarkly.atlassian.net/browse/REL-3847
      updateTeam({
        body: {
          instructions: [],
        },
        teamKey: team.key,
      });
      return;
    }

    if (member) {
      const updatedMember = {
        ...member,
        roleAttributes: {
          ...data.attributes.reduce(
            (acc, { attribute, resources }) => {
              // eslint-disable-next-line no-param-reassign
              acc[attribute] = resources;
              return acc;
            },
            {} as Record<string, string[]>,
          ),
        },
      };

      const patch = createJsonPatch(member, updatedMember) as JSONPatch;
      updateMember(
        { body: patch, id: member._id },
        {
          onSuccess: () => {
            ToastQueue.success('Access updated');
            closeModal();
          },
          onError: (err) => {
            SnackbarQueue.error({
              description: isRESTAPIError(err) ? err?.message : 'Failed to update access. Try again later.',
            });
          },
        },
      );
      return;
    }
  };

  // using a makeshift table for now, because the RAC table focus handling prevents
  // us from using input's within the table.
  // https://github.com/adobe/react-spectrum/issues/2328

  return (
    <Form
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        // Prevent focus change to the submit button when pressing Enter
        if (e.key === 'Enter') {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
    >
      <div className={styles.tableContainer} role="table" aria-label="Role attributes and resources">
        <div className={styles.tableHeader} role="rowgroup">
          <div className={styles.tableRow} role="row">
            <div className={styles.headerCell} role="columnheader">
              Role attribute
            </div>
            <div className={styles.headerCell} role="columnheader">
              Resources
            </div>
          </div>
        </div>
        <div className={styles.tableBody} role="rowgroup">
          {attributeFields.map(({ attribute }, index) => (
            <div key={index} className={styles.tableRow} role="row">
              <div className={styles.cell} role="rowheader">
                <span className={styles.attributeName}>{attribute}</span>
              </div>
              <div className={styles.cell} role="cell">
                <Controller
                  control={control}
                  name={`attributes.${index}.resources`}
                  render={({ field: { onChange, value } }) => (
                    <TagGroupInput
                      ariaLabel={`Enter resources for ${attribute}`}
                      tag={(item) => <Tag textValue={item.textValue}>{item.textValue}</Tag>}
                      placeholder="Enter a resource key"
                      selectedItems={value.map((v) => ({ id: v, textValue: v }))}
                      onItemsChange={(v) => {
                        onChange(v.map((i) => i.id));
                      }}
                    />
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <ButtonGroup className={styles.buttonGroup}>
        <Button variant="default" onPress={close}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" isDisabled={!isDirty || isUpdating}>
          {isUpdating ? <ProgressBar isIndeterminate size="small" /> : 'Assign access'}
        </Button>
      </ButtonGroup>
    </Form>
  );
};
