import { type QueryClientConfig, QueryClient } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

let client: QueryClient;

interface QueryClientProps extends QueryClientConfig {}

export function initializeQueryClient(props: QueryClientProps = {}, { validate = true }: { validate?: boolean } = {}) {
  if (validate) {
    invariant(client === undefined, 'Query client already initialized');
  }
  client = new QueryClient(props);
}

export function getQueryClient() {
  invariant(client !== undefined, 'Query client is not initialized');
  return client;
}
