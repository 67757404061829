import { currentProject, profile } from '@gonfalon/bootstrap-data';
import { halfwayIA } from '@gonfalon/dogfood-flags';
import { InternalEnvironment } from '@gonfalon/environments';
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore as createReduxStore,
  Middleware,
  Reducer,
  Store,
  StoreEnhancer,
} from 'redux';

import reducerRegistry from 'reducers/registry';

import { getCurrentEnvironmentInitialState } from './getCurrentEnvironmentInitialState';
import { getEnvironmentsInitialState } from './getEnvironmentsInitialState';
import { getProfileInitialState } from './getProfileInitialState';
import { getProjectsInitialState } from './getProjectsInitialState';
import { currentEnvironmentKeySelector, currentProjectKeySelector } from './projects';

type CreateStoreArgs = {
  initialState?: {};
  middlewares?: Middleware[];
  enhancers?: StoreEnhancer[];
};

/**
 * This function wraps our reducers in a special reducer that supports
 * resetting the entire store state. This is necessary for the new IA, which
 * let's us change the current environment (among other things) dynamically,
 * even though our store is shaped around a single current environment,
 * bootstrapped from the server.
 *
 * We use this method to mimic the effect of a page refresh.
 *
 * Please reach out to @alexis in Slack if you have any questions.
 */
function createRootReducer(reducer: Reducer, initialState: {}) {
  return function rootReducer(state: {} = initialState, action: AnyAction) {
    if (!halfwayIA()) {
      return reducer(state, action);
    }

    if (action.type === 'RESET_STORE') {
      return reducer(
        {
          profile: getProfileInitialState(action.payload.initialState.profile),
          projects: getProjectsInitialState({
            currentProject: action.payload.initialState.currentProject,
            currentEnvironment: action.payload.initialState.currentEnvironment,
          }),
          projectEnvironments: getEnvironmentsInitialState(action.payload.initialState.currentEnvironment),
          environments: getEnvironmentsInitialState(action.payload.initialState.currentEnvironment),
          currentEnvironment: getCurrentEnvironmentInitialState(action.payload.initialState.currentEnvironment),
        },
        action,
      );
    }

    return reducer(state, action);
  };
}

export function createStore({ initialState = {}, middlewares = [], enhancers = [] }: CreateStoreArgs) {
  // Preserve initial state for not-yet-loaded reducers
  const combine = (reducers: { [k: string]: Reducer }) => {
    const reducerNames = Object.keys(reducers);
    Object.keys(initialState).forEach((item) => {
      if (reducerNames.indexOf(item) === -1) {
        // eslint-disable-next-line no-param-reassign
        reducers[item] = (state = null) => state;
      }
    });

    const allReducers = combineReducers({ initialized: () => true, ...reducers });

    // The initialized reduce is there to ensure there is at least one reducer when the app loads. (With lazy-loading,
    // it's possible there isn't one early on.)
    return createRootReducer(allReducers, initialState);
  };

  const reducer = combine(reducerRegistry.getReducers());

  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;

  const store: Store = createReduxStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares), ...enhancers),
  );

  // Replace the store's reducer whenever a new reducer is registered.
  reducerRegistry.setChangeListener((reducers) => {
    const newReducer = combine(reducers);
    store.replaceReducer(newReducer);
  });

  function reset({
    condition,
    payload,
  }: {
    condition: (state: { currentProjectKey?: string; currentEnvironmentKey?: string }) => boolean;
    payload: {
      initialState: {
        currentProject?: ReturnType<typeof currentProject>;
        currentEnvironment?: InternalEnvironment;
        profile?: ReturnType<typeof profile>;
      };
    };
  }) {
    if (
      condition({
        currentProjectKey: currentProjectKeySelector(store.getState()),
        currentEnvironmentKey: currentEnvironmentKeySelector(store.getState()),
      })
    ) {
      store.dispatch({ type: 'RESET_STORE', payload });
    }
  }

  return { store, reset } as const;
}
