import { GetExperimentsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface GetExperimentsQueryParams
  extends Omit<GetExperimentsOpenAPIQueryParams, 'filter' | 'expand' | 'lifecycleState'> {
  expand?: Array<
    'previousIterations' | 'draftIteration' | 'draftDesign' | 'treatments' | 'secondaryMetrics' | 'metrics'
  >;
  lifecycleState?: Array<'archived' | 'active'>;
  filter?: {
    flagKey?: string;
    metricKey?: string;
    status?: 'not_started' | 'running' | 'stopped' | 'started';
    query?: string;
    kind?: 'experiment' | 'measuredRollout';
  };
}

export function querySerializer(queryParams: GetExperimentsQueryParams) {
  const searchParams = new URLSearchParams();

  if ((queryParams.limit ?? 0) > 0) {
    searchParams.set('limit', String(queryParams.limit));
  }

  if ((queryParams.offset ?? 0) > 0) {
    searchParams.set('offset', String(queryParams.offset));
  }

  if (queryParams.filter) {
    searchParams.set('filter', serializeExperimentFilterParam(queryParams.filter));
  }

  if (queryParams.expand) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  if (queryParams.lifecycleState && queryParams.lifecycleState.length > 0) {
    searchParams.set('lifecycleState', queryParams.lifecycleState.join(','));
  }

  return searchParams.toString();
}

export function serializeExperimentFilterParam(value: NonNullable<GetExperimentsQueryParams['filter']>) {
  const filter: string[] = [];

  if (value.flagKey) {
    filter.push(`flagKey:${value.flagKey}`);
  }

  if (value.metricKey) {
    filter.push(`metricKey:${value.metricKey}`);
  }

  if (value.status) {
    filter.push(`status:${value.status}`);
  }

  if (value.query) {
    filter.push(`query:${value.query}`);
  }

  if (value.kind) {
    filter.push(`kind:${value.kind}`);
  }

  filter.sort();

  return filter.join(',');
}
