import { GetTeamsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetTeamsQueryParams = Omit<GetTeamsOpenAPIQueryParams, 'filter' | 'expand'> & {
  expand?: Array<'members' | 'maintainers'>;
  filter?: {
    query?: string;
    nomembers?: boolean;
  };
};

export function querySerializer(queryParams: GetTeamsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.limit !== undefined && queryParams.limit > 0) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset !== undefined && queryParams.offset > 0) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  if (queryParams.filter) {
    const serializedFilter = serializeTeamListFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  if (queryParams.expand) {
    const unique = Array.from(new Set(queryParams.expand));
    if (unique.length > 0) {
      searchParams.set('expand', unique.join(','));
    }
  }

  return searchParams.toString();
}

function serializeTeamListFilterParam(value: NonNullable<GetTeamsQueryParams['filter']>) {
  const filter: string[] = [];

  if (value.query) {
    filter.push(`query:${value.query}`);
  }

  if (typeof value.nomembers === 'boolean') {
    filter.push(`nomembers:${value.nomembers}`);
  }

  return filter.join(',');
}
