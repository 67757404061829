import { internalPatchMeasuredRolloutMetricState } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { measuredRollouts } from './internal/queries';

export const useUpdateMeasuredRolloutMetricStates = createMutationHook(internalPatchMeasuredRolloutMetricState, {
  onSuccess: async () => {
    const queryClient = getQueryClient();

    await queryClient.invalidateQueries({
      queryKey: measuredRollouts.detail._def,
    });
  },
});
