import { type GetAllReleaseProgressionsForReleasePipelineQueryParams } from '@gonfalon/openapi';

import { createQueryPrefetcher } from './internal/createQueryPrefetcher';
import { releasePipelines } from './internal/queries';

export const prefetchReleaseProgressions = createQueryPrefetcher(
  ({
    projectKey,
    releasePipelineKey,
    params,
  }: {
    projectKey: string;
    releasePipelineKey: string;
    params?: GetAllReleaseProgressionsForReleasePipelineQueryParams;
  }) => releasePipelines.detail({ projectKey, releasePipelineKey })._ctx.releaseProgressions({ params }),
);
