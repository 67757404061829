import { GetQuantileExperimentResultsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

import { type GetExperimentResultsQueryParams } from './getExperimentResultsOverrides';

export const apiVersion = 'beta';

export interface GetQuantileExperimentResultsQueryParams
  extends GetExperimentResultsQueryParams,
    GetQuantileExperimentResultsOpenAPIQueryParams {}

export function querySerializer(queryParams: GetQuantileExperimentResultsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.iterationId) {
    searchParams.set('iterationId', queryParams.iterationId);
  }

  if (typeof queryParams.sumMetrics !== 'undefined') {
    searchParams.set('sumMetrics', String(queryParams.sumMetrics));
  }

  if (typeof queryParams.defaultMissingMetrics !== 'undefined') {
    searchParams.set('defaultMissingMetrics', String(queryParams.defaultMissingMetrics));
  }

  if (queryParams.attributes) {
    searchParams.set('attributes', JSON.stringify(queryParams.attributes));
  }

  if (typeof queryParams.percentile === 'number') {
    searchParams.set('percentile', String(queryParams.percentile));
  }

  if (typeof queryParams.confidence === 'number') {
    searchParams.set('confidence', String(queryParams.confidence));
  }

  return searchParams.toString();
}
