import { ApplicationVersionAdoptionCollection } from './types';

export const getTotalSupportedAdoptionPercentage = (
  versionAdoptionCollection: ApplicationVersionAdoptionCollection,
) => {
  const { items } = versionAdoptionCollection;
  if (!items) {
    return 0;
  }

  const supportedVersions = items.filter((item) => item.supported);
  const totalSupportedAdoptionPercentage = supportedVersions.reduce(
    (acc, item) => (item.adoptionPercentage ? acc + item.adoptionPercentage : 0),
    0,
  );
  return totalSupportedAdoptionPercentage;
};
