import { KeyboardEvent, ReactNode, useCallback, useId, useRef, useState } from 'react';
import type { Key } from 'react-aria-components';
import { Group, IconButton, Input, Label, TagGroup, TagList } from '@launchpad-ui/components';

import styles from './TagGroupInput.module.css';

export type TagGroupInputProps<T extends { id: Key; textValue: string }> = {
  tag: (item: T) => ReactNode;
  onItemAdd?: (key: Key) => void;
  onItemRemove?: (key: Key) => void;
  onItemsChange: (items: T[]) => void;
  label?: string;
  placeholder?: string;
  selectedItems: T[];
  ariaLabel?: string;
};

export function TagGroupInput<T extends { id: Key; textValue: string }>({
  tag,
  onItemAdd,
  onItemRemove,
  label,
  placeholder,
  selectedItems,
  onItemsChange,
  ariaLabel,
}: TagGroupInputProps<T>) {
  const tagGroupId = useId();
  const [fieldState, setFieldState] = useState<{
    selectedKey: Key | null;
    inputValue: string;
  }>({
    selectedKey: null,
    inputValue: '',
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const selectedKeys = selectedItems.map((i) => i.id);

  const onRemove = useCallback(
    (keys: Set<Key>) => {
      const key = keys.values().next().value;
      if (!key) {
        return;
      }
      const newSelectedItems = selectedItems.filter((i) => i.id !== key);
      setFieldState({
        inputValue: '',
        selectedKey: null,
      });
      onItemRemove?.(key);
      onItemsChange?.(newSelectedItems);
    },
    [selectedItems, onItemRemove, onItemsChange],
  );

  const deleteLast = useCallback(() => {
    if (selectedItems.length === 0) {
      return;
    }

    const lastKey = selectedItems[selectedItems.length - 1];

    if (lastKey !== null) {
      const newSelectedItems = selectedItems.filter((i) => i.id !== lastKey.id);
      onItemRemove?.(lastKey.id);
      onItemsChange?.(newSelectedItems);
    }

    setFieldState({
      inputValue: '',
      selectedKey: null,
    });
  }, [selectedItems, onItemRemove, onItemsChange]);

  const handleItemAdd = useCallback(
    (value: string) => {
      if (!selectedKeys.includes(value)) {
        const newSelectedItems = [...selectedItems, { id: value, textValue: value } as T];
        setFieldState({
          inputValue: '',
          selectedKey: null,
        });
        onItemAdd?.(value);
        onItemsChange?.(newSelectedItems);
        inputRef.current?.focus();
      }
    },
    [selectedKeys, selectedItems, onItemAdd, onItemsChange],
  );

  const onKeyDownCapture = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' && fieldState.inputValue === '') {
        deleteLast();
      }

      if (e.key === 'Enter') {
        handleItemAdd(e.currentTarget.value);
      }
    },
    [deleteLast, fieldState.inputValue, handleItemAdd],
  );

  return (
    <>
      {label && <Label>{label}</Label>}
      <Group>
        <div className={styles.group}>
          <span className={styles.container}>
            <TagGroup id={tagGroupId} onRemove={onRemove} aria-label="selected items" className={styles.tagGroup}>
              <TagList items={selectedItems} className={styles.tagList}>
                {tag}
              </TagList>
            </TagGroup>
          </span>
          <Input
            className={styles.input}
            onKeyDownCapture={onKeyDownCapture}
            onChange={(e) => setFieldState({ inputValue: e.target.value, selectedKey: null })}
            value={fieldState.inputValue}
            placeholder={placeholder}
            autoFocus
            aria-label={ariaLabel}
          />
        </div>
        {fieldState.inputValue && (
          <IconButton
            icon="add"
            size="small"
            variant="minimal"
            aria-label="Add"
            onPress={() => {
              handleItemAdd(fieldState.inputValue);
            }}
          />
        )}
      </Group>
    </>
  );
}
