// eslint-disable-next-line no-restricted-imports
import { fromJS, isImmutable } from 'immutable';

import { AuthAction } from 'actions/auth';
import { FormAction } from 'actions/forms';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { createFormState } from 'utils/formUtils';
import { ImmutableServerError } from 'utils/httpUtils';
import { createImmutableState, ImmutableMap } from 'utils/immutableUtils';
import { invalidJWTError } from 'utils/jwtUtils';
import { createForgotPassword } from 'utils/resetPasswordUtils';

type LoginStateProps = {
  token?: string;
  error?: ImmutableServerError;
  needsRefresh: boolean;
  requirePassword: boolean;
  isRecovery: boolean;
};

export type LoginState = ImmutableMap<LoginStateProps>;

const authFlow = (state: LoginState, action: AuthAction) => {
  switch (action.type) {
    case 'auth/LOGIN_WITH_MFA':
      return state.merge({
        token: action.token,
        error: undefined,
      });
    case 'auth/CLEAR_MFA_STATE':
      return state.merge({
        token: undefined,
        error: undefined,
      });
    case 'auth/VERIFY_MFA_FAILED':
    case 'auth/RECOVER_MFA_FAILED':
      return state.update((st: LoginState) => {
        const needsRefresh = isImmutable(action.error) && action.error.get('message') === invalidJWTError;
        return st.merge({
          needsRefresh,
          error: needsRefresh ? undefined : action.error,
          token: needsRefresh ? undefined : st.get('token'),
        });
      });
    case 'auth/LOGIN_FAILED':
    case 'auth/RESET_PASSWORD_FAILED':
      return state.merge({
        needsRefresh: false,
        error: action.error,
      });
    case 'auth/USE_MFA_RECOVERY':
      return state.merge({
        isRecovery: true,
        error: undefined,
      });
    default:
      return state;
  }
};

// NOTE(ag): We use a function here to make sure we evaluate
//           the flag value as late as possible. This makes
//           it possible to override the context in tests.
export const initialLoginState = (): LoginState =>
  fromJS({
    token: undefined,
    error: undefined,
    needsRefresh: false,
    requirePassword: false,
    isRecovery: false,
  });

export const login = (state = initialLoginState(), action: AuthAction) => {
  switch (action.type) {
    case 'auth/LOGIN':
      return state.merge({
        error: undefined,
      });
    case 'auth/LOGIN_WITH_PASSWORD':
      return state.merge({
        requirePassword: true,
        error: state.get('requirePassword') ? action.error : undefined,
      });
    default:
      return authFlow(state, action);
  }
};

export const loginSelector = (state: GlobalState) => state.login.toObject() as LoginStateProps;

export const initialResetPasswordState: LoginState = fromJS({
  token: undefined,
  error: undefined,
  needsRefresh: false,
  isRecovery: false,
});

export const resetPassword = (state = initialResetPasswordState, action: AuthAction) => {
  switch (action.type) {
    case 'auth/RESET_PASSWORD':
      return state.merge({
        error: undefined,
      });
    default:
      return authFlow(state, action);
  }
};

export const resetPasswordSelector = (state: GlobalState) => state.resetPassword.toObject();

export function forgotPasswordForm(state = createFormState(createForgotPassword()), action: AuthAction | FormAction) {
  switch (action.type) {
    case 'auth/EDIT_FORGOT_PASSWORD':
      return state.trackField(action.field).revalidate(action.details);
    case 'auth/FORGOT_PASSWORD':
      return state.submitting();
    case 'auth/FORGOT_PASSWORD_DONE':
      return state.submitted(action.details);
    case 'auth/FORGOT_PASSWORD_FAILED':
      return state.submitFailed(action.details, action.error);
    case 'forms/BLUR':
      if (action.model !== 'forgotPasswordForm') {
        return state;
      }
      return state.handleBlur(action.field, state.modified);
    default:
      return state;
  }
}

type ResendEmailVerificationState = ImmutableMap<{
  email?: string;
  isResending: boolean;
  isBlocked: boolean;
  isSent: boolean;
  error?: ImmutableServerError;
  response: void | Response;
}>;

const initialResendEmailVerificationState: ResendEmailVerificationState = createImmutableState({
  isResending: false,
  isBlocked: false,
  isSent: false,
  response: undefined,
});

export function resendEmailVerification(state = initialResendEmailVerificationState, action: AuthAction) {
  switch (action.type) {
    case 'auth/RESEND_EMAIL_VERIFICATION_START':
      return state.set('isResending', true).set('isBlocked', true).set('response', undefined);
    case 'auth/RESEND_EMAIL_VERIFICATION_DONE':
      return state
        .set('email', action.email)
        .set('isResending', false)
        .set('isSent', true)
        .set('error', undefined)
        .set('response', action.response);
    case 'auth/RESEND_EMAIL_VERIFICATION_FAILED':
      return state.set('isResending', false).set('isSent', false).set('error', action.error);
    case 'auth/RESEND_EMAIL_VERIFICATION_BLOCKED':
      return state.set('isBlocked', true);
    case 'auth/RESEND_EMAIL_VERIFICATION_UNBLOCKED':
      return state.set('isBlocked', false);
    default:
      return state;
  }
}

export const resendEmailVerificationSelector = (state: GlobalState) => state.resendEmailVerification;

registry.addReducers({
  login,
  forgotPasswordForm,
  resetPassword,
  resendEmailVerification,
});
