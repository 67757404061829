import { GetMigrationFlagMetricsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export interface GetMigrationFlagMetricsQueryParams extends Omit<GetMigrationFlagMetricsOpenAPIQueryParams, 'rules'> {
  rules?: string[];
}

export function querySerializer(queryParams: GetMigrationFlagMetricsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.from) {
    searchParams.set('from', String(queryParams.from));
  }

  if (queryParams.to) {
    searchParams.set('to', String(queryParams.to));
  }

  if (queryParams.rules && queryParams.rules.length > 0) {
    searchParams.append('rules', queryParams.rules.join(','));
  }

  return searchParams.toString();
}
