import { type CSSProperties, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import styles from './useLineClamp.module.css';

export function useLineClamp<T extends HTMLElement>({ lines, override }: { lines: number; override?: boolean }) {
  const [hasOverflow, setOverflow] = useState(false);
  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    const node = ref.current;

    function compute() {
      if (!node) {
        return;
      }

      setOverflow(node.scrollHeight > node.clientHeight);
    }

    if (node) {
      compute();

      const observer = new ResizeObserver((entries) => {
        if (entries.length === 0) {
          return;
        }

        compute();
      });

      observer.observe(node);

      return () => observer.unobserve(node);
    }
  });

  const props = {
    style: {
      '--line-count': String(lines),
    } as CSSProperties,
    'data-overflow': hasOverflow ? 'true' : undefined,
    className: clsx(styles.clamp, {
      [styles.active]: !override,
    }),
  };

  return { ref, hasOverflow, props } as const;
}
