import { cloneElement, forwardRef, FunctionComponentElement } from 'react';
import { Theme, useThemeValue } from '@gonfalon/theme';
import { mergeRefs } from '@react-aria/utils';

export type ThemedElementProps = {
  [K in Theme]: JSX.Element;
};

const Component = forwardRef<Element, ThemedElementProps>((props, ref) => {
  const currentTheme = useThemeValue();
  const element = currentTheme && (props[currentTheme] as FunctionComponentElement<{ ref: typeof ref }>);
  return element ? cloneElement(element, { ref: element.ref ? mergeRefs(ref, element.ref) : ref }) : null;
});

Component.displayName = 'ThemedElement';

export { Component as ThemedElement };
