import { trackApplicationVersionUpdated } from '@gonfalon/applications';
import { patchApplicationVersion } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { applications } from './internal/queries';

export const useUpdateApplicationVersion = createMutationHook(patchApplicationVersion, {
  onSuccess: async (data, { applicationKey }) => {
    trackApplicationVersionUpdated({ key: data.key, name: data.name, supported: data.supported, applicationKey });
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: applications.detail({ applicationKey })._ctx.versions._def });
    //we need to invalidate the version adoption query whenever a version support status is updated so that the chart reflects the update support status
    await queryClient.invalidateQueries({
      queryKey: applications.detail({ applicationKey })._ctx.versionAdoption.queryKey,
    });
  },
});
