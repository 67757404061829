export const apiVersion = 'beta';

import {
  type GetExperimentsQueryParams,
  querySerializer as getExperimentQuerySerializer,
} from './getExperimentsOverrides';

export interface GetExperimentsAnyEnvQueryParams extends GetExperimentsQueryParams {}

export function querySerializer(queryParams: GetExperimentsAnyEnvQueryParams) {
  return getExperimentQuerySerializer(queryParams);
}
