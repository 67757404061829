import { Err, Ok, Result } from 'ts-results';

import { EOF } from './constants';
import { Token, TokenType } from './token';

type ReadResult = Result<string, string>;

export class Lexer {
  private position: number;
  private readPosition: number;
  private character: string;
  private previousTokenType: TokenType | undefined;

  public readonly input: string;

  constructor(input: string) {
    this.input = input;
    this.position = 0;
    this.readPosition = 0;
    this.character = EOF;
    this.previousTokenType = undefined;

    // Move into initial position
    this.readCharacter();
  }

  private lexTag(startPosition: number): Token {
    this.readCharacter();
    const result = this.readUntil(characterIn(',', ':', '{'));

    if (result.val.length === 0) {
      if (this.character.localeCompare('{') === 0) {
        this.readCharacter();
        return { type: 'property-selector-start', text: '{', position: startPosition };
      }
      return { type: 'illegal', text: result.val, position: startPosition };
    }

    return { type: 'tag', text: result.val, position: startPosition + 1 };
  }

  private lexPropertySelectorKey(startPosition: number): Token {
    const result = this.readUntil(characterIn(':'));
    if (result.val.length !== 0) {
      return { type: 'property-selector-key', text: result.val, position: startPosition };
    }
    return { type: 'illegal', text: result.val, position: startPosition };
  }

  private lexPropertySelectorValue(startPosition: number): Token {
    // read :
    this.readCharacter();
    const result = this.readUntil(characterIn('}'));
    if (result.val.length !== 0) {
      return { type: 'property-selector-value', text: result.val, position: startPosition };
    } else {
      return { type: 'illegal', text: result.val, position: startPosition };
    }
  }

  public nextToken(): Token {
    let token: Token;
    let result: ReadResult;

    const startPosition = this.position;

    switch (this.character) {
      case EOF:
        token = { type: 'eof', text: EOF, position: startPosition };
        break;

      case ';':
        token = this.lexTag(startPosition);
        break;

      case ',':
        token = this.lexTag(startPosition);
        break;

      case ':':
        if (this.previousTokenType === 'property-selector-key') {
          token = this.lexPropertySelectorValue(startPosition);
        } else {
          token = { type: 'scope', text: ':', position: startPosition };
          this.readCharacter();
        }
        break;

      case '/':
        this.readCharacter();
        result = this.readUntil(
          characterIn(
            ';', // tags
            ':', // scope
          ),
        );

        if (result.val.length === 0) {
          token = { type: 'illegal', text: result.val, position: startPosition };
        } else {
          token = { type: 'resource-identifier', text: result.val, position: startPosition + 1 };
        }
        break;

      case '}':
        this.readCharacter();
        token = { type: 'property-selector-end', text: '}', position: startPosition };
        break;

      default:
        if (this.previousTokenType === 'property-selector-start') {
          token = this.lexPropertySelectorKey(startPosition);
          break;
        }
        result = this.readUntil(characterIn('/'));

        if (result.val.length === 0) {
          token = { type: 'illegal', text: result.val, position: startPosition };
        } else {
          token = { type: 'resource-type', text: result.val, position: startPosition };
        }
    }
    this.previousTokenType = token.type;
    return token;
  }

  private readUntil(condition: (character: string) => boolean): ReadResult {
    let text = '';

    while (this.character !== EOF) {
      if (condition(this.character)) {
        return Ok(text);
      }

      text += this.character;

      this.readCharacter();
    }

    return Err(text);
  }

  private readCharacter() {
    if (this.readPosition >= this.input.length) {
      this.character = EOF;
    } else {
      this.character = this.input[this.readPosition];
    }
    this.position = this.readPosition;
    this.readPosition += 1;
  }
}

export function lex(input: string): Token[] {
  const lexer = new Lexer(input);
  const tokens: Token[] = [];
  let token = lexer.nextToken();
  while (token.type !== 'eof') {
    tokens.push(token);
    token = lexer.nextToken();
  }

  return tokens;
}

function characterIn(...characters: string[]) {
  return (character: string) => {
    for (const ch of characters) {
      if (ch === character) {
        return true;
      }
    }

    return false;
  };
}
