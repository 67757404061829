import { currentEnvironment as _currentEnvironment } from '@gonfalon/bootstrap-data';
import { Map } from 'immutable';

import { createEnvironment } from 'utils/environmentUtils';
import type { StateWithRequestInfo } from 'utils/requestUtils';

export type CurrentEnvironmentState = StateWithRequestInfo<{
  doNotFetch?: boolean;
  entity?: string;
}>;

export function getCurrentEnvironmentInitialState(
  initialState: ReturnType<typeof _currentEnvironment> = _currentEnvironment(),
) {
  const state: CurrentEnvironmentState = Map({
    lastFetched: null,
    isFetching: false,
    entity: null,
  });

  const env = initialState;

  if (env) {
    const e = createEnvironment(env);
    return state.withMutations((st: CurrentEnvironmentState) =>
      st.merge({
        isFetching: false,
        lastFetched: Date.now(),
        entity: e._id,
      }),
    );
  }

  return state;
}
