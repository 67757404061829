import { createTaskRunner } from '@gonfalon/async';
import { chunk } from '@gonfalon/collections';
import { segmentKeyLimit } from '@gonfalon/dogfood-flags';
import { type GetSegmentsQueryParams, getSegments, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type SegmentsTask = {
  projectKey: string;
  environmentKey: string;
  params?: GetSegmentsQueryParams;
};

export const getSegmentsTaskRunner = createTaskRunner<
  SegmentsTask,
  schemas['UserSegments'],
  NonNullable<Awaited<ReturnType<typeof getSegments>>['data']>
>({
  runner: async (inputs) => {
    return reactQueryResponseAdapter(
      getSegments({
        projectKey: inputs[0].projectKey,
        environmentKey: inputs[0].environmentKey,
        query: {
          ...inputs[0].params,
          filter: {
            ...inputs[0].params?.filter,
            keys: Array.from(new Set(inputs.flatMap((input) => input.params?.filter?.keys || []))),
          },
        },
      }),
    );
  },
  resolver: (input) => {
    return input;
  },
  batching: {
    batcher: getSegmentsTaskBatcher,
    resultMerger: (result) => {
      const items = result.reduce((acc, batch) => {
        // @ts-expect-error for some reason TS doesn't understand that `items` is always an array
        return acc.concat(batch.items);
      }, []);
      return {
        _links: result[0]?._links,
        totalCount: result[0]?.totalCount,
        items,
      };
    },
  },
});

export function getSegmentsTaskBatcher(inputs: SegmentsTask[]) {
  const taskKeys = Array.from(new Set(inputs.flatMap((input) => input.params?.filter?.keys || [])));
  let batchedTasks: SegmentsTask[][];
  if (taskKeys === undefined || taskKeys.length <= segmentKeyLimit()) {
    batchedTasks = [inputs];
    return batchedTasks;
  }
  batchedTasks = chunk(taskKeys, segmentKeyLimit()).map((keys) => {
    return [
      {
        projectKey: inputs[0].projectKey,
        environmentKey: inputs[0].environmentKey,
        params: {
          filter: {
            ...inputs[0].params?.filter,
            keys,
          },
        },
      },
    ];
  });
  return batchedTasks;
}
