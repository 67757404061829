import { createQueryFetcher } from './internal/createQueryFetcher';
import { workflows } from './internal/queries';

export const fetchWorkflow = createQueryFetcher(
  ({
    projectKey,
    flagKey,
    environmentKey,
    workflowId,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    workflowId: string;
  }) => workflows.detail({ projectKey, featureFlagKey: flagKey, environmentKey, workflowId }),
);
