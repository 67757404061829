import {
  enableHoldoutsUI,
  isCustomRolesEnabled,
  isExperimentationApiEnabled,
  isPayloadFilteringEnabled,
} from '@gonfalon/dogfood-flags';
import { isArray, isString } from '@gonfalon/es6-utils';
import { resourceSpecifierFromString } from '@gonfalon/resource-specifiers';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Record as ImmutableRecord } from 'immutable';
import isJSON from 'validator/lib/isJSON';

import { combinePredicates, isNotEmpty, predicateFor, validateRecord } from 'utils/validationUtils';

import { ImmutableMap } from './immutableUtils';

export const resourceTypeToString: () => Record<string, string> = () => ({
  acct: 'account',
  env: 'environment',
  feature: 'feature',
  flag: 'flag',
  goal: 'goal',
  integration: 'integration',
  layer: 'layer',
  member: 'member',
  metric: 'metric',
  'metric-group': 'metric-group',
  role: 'role',
  'pending-request': 'pending-request',
  proj: 'project',
  'context-kind': 'context-kind',
  user: 'user',
  webhook: 'webhook',
  segment: 'segment',
  'code-reference-repository': 'code-reference-repository',
  destination: 'destination',
  token: 'token',
  'service-token': 'service-token',
  'relay-proxy-config': 'relay-proxy-config',
  team: 'team',
  ...(isExperimentationApiEnabled() ? { experiment: 'experiment' } : {}),
  ...(enableHoldoutsUI() ? { holdout: 'holdout' } : {}),
  template: 'template',
  'release-pipeline': 'release-pipeline',
  ...(isPayloadFilteringEnabled() ? { 'payload-filter': 'payload-filter' } : {}),
});

const resourceStringToType: () => Record<string, string> = () => ({
  account: 'acct',
  application: 'application',
  environment: 'env',
  feature: 'feature',
  flag: 'flag',
  goal: 'goal',
  integration: 'integration',
  layer: 'layer',
  member: 'member',
  metric: 'metric',
  'metric-group': 'metric-group',
  role: 'role',
  'pending-request': 'pending-request',
  project: 'proj',
  user: 'user',
  webhook: 'webhook',
  segment: 'segment',
  'code-reference-repository': 'code-reference-repository',
  destination: 'destination',
  token: 'token',
  'service-token': 'service-token',
  'relay-proxy-config': 'relay-proxy-config',
  team: 'team',
  ...(isExperimentationApiEnabled() ? { experiment: 'experiment' } : {}),
  template: 'template',
  'release-pipeline': 'release-pipeline',
  ...(isPayloadFilteringEnabled() ? { 'payload-filter': 'payload-filter' } : {}),
});

const getTypeForVersion = (type: string) => {
  if (type === 'feature') {
    return 'flag';
  }
  return type;
};

export const parseResourceType = (resources: string[]) => {
  const primary = resources.length && resources[0];
  if (!primary) {
    return '';
  }
  const { val, ok } = resourceSpecifierFromString(resources[0]);
  if (!ok) {
    return '';
  }
  return resourceTypeToString()[getTypeForVersion(val.type)];
};

export type PolicyActionsObject = Readonly<{
  [key: string]: PolicyActionObject;
  account: PolicyActionObject;
  application: PolicyActionObject;
  'code-reference-repository': PolicyActionObject;
  destination: PolicyActionObject;
  environment: PolicyActionObject;
  feature: PolicyActionObject;
  flag: PolicyActionObject;
  goal: PolicyActionObject;
  integration: PolicyActionObject;
  layer: PolicyActionObject;
  member: PolicyActionObject;
  metric: PolicyActionObject;
  'metric-group': PolicyActionObject;
  'pending-request': PolicyActionObject;
  project: PolicyActionObject;
  role: PolicyActionObject;
  segment: PolicyActionObject;
  token: PolicyActionObject;
  user: PolicyActionObject;
  webhook: PolicyActionObject;
  team: PolicyActionObject;
  template: PolicyActionObject;
  experiment: PolicyActionObject;
  'release-pipeline': PolicyActionObject;
  'payload-filter': PolicyActionObject;
}>;

export type PolicyActions = ImmutableMap<PolicyActionsObject>;

export type PolicyActionAndDescription = Readonly<{
  action: string;
  description: string;
}>;

export type PolicyActionObject = ImmutableMap<{
  items: List<PolicyActionAndDescription>;
  key: string;
}>;

export type ActionsForResourceType = {
  value: string;
  label: string;
  description?: string;
};

export type ExcludedRoleActions = {
  [key: string]: string[];
  flag: string[];
  segment: string[];
};

// negateActions and negateResources are only used on the front-end to allow editing
// policies created via the API that have notActions and/or notResources.
export type PolicyStatementRecordType = {
  actions: List<string>;
  effect: string;
  negateActions?: boolean;
  negateResources?: boolean;
  resources: List<string>;
};

// this is the plain js version of `PolicyStatementRecordType`, which is useful because we transform back and forth between
// immutable and a plainjs object in several places
export type PolicyStatementRecordPlainType = {
  actions: string[];
  effect: string;
  negateActions?: boolean;
  negateResources?: boolean;
  resources: string[];
};

export type Policy = List<PolicyStatement>;

export class PolicyStatement extends ImmutableRecord<PolicyStatementRecordType>({
  actions: List(),
  effect: '',
  negateActions: undefined,
  negateResources: undefined,
  resources: List(),
}) {
  isEmpty() {
    return this.actions.isEmpty() && this.resources.isEmpty();
  }

  validate() {
    return validateRecord(this, isNotEmpty('actions'), isNotEmpty('effect'), isNotEmpty('resources'));
  }

  infoMessage() {
    if (
      isCustomRolesEnabled() &&
      this.resources &&
      this.resources.includes('proj/*') &&
      this.actions &&
      this.actions.includes('*') &&
      this.effect === 'deny'
    ) {
      return 'This policy will deny all actions to all projects, including read access.';
    }
  }
}

const isEnvironmentScopedResourceType = (type: string) => ['feature', 'flag', 'segment', 'experiment'].includes(type);
const isProjectScopedResourceType = (type: string) =>
  ['environment', 'goal', 'layer', 'metric', 'payload-filter', 'release-pipeline'].includes(type);

export function getFullResourceString(value: string, type: string, projKey?: string): string {
  const modifiedType = getTypeForVersion(type);
  const kind = resourceStringToType()[modifiedType];
  if (!kind) {
    return value;
  }
  const str = modifiedType === 'account' ? kind : `${kind}/${value}`;
  if (isEnvironmentScopedResourceType(modifiedType)) {
    return `${getFullResourceString('*', 'environment')}:${str}`;
  }
  if (isProjectScopedResourceType(modifiedType)) {
    if (!projKey) {
      return `${getFullResourceString('*', 'project')}:${str}`;
    }
    return `${getFullResourceString(projKey, 'project')}:${str}`;
  }
  return str;
}

export function isPolicyStatement(
  props: Partial<PolicyStatement> | Partial<PolicyStatementRecordPlainType> | PolicyStatementObject,
) {
  return props instanceof PolicyStatement;
}

export function createPolicyStatement(props: Partial<PolicyStatement> | Partial<PolicyStatementRecordPlainType>) {
  return isPolicyStatement(props) ? props : new PolicyStatement(fromJS(props));
}

// can have either actions or not actions, either resources or not resources
export type PolicyStatementObject = {
  effect: string;
  actions?: string[];
  resources?: string[];
  notActions?: string[];
  notResources?: string[];
};

export const isPolicyString = combinePredicates([
  predicateFor(
    (v) => !(isJSON(v) && isArray(JSON.parse(v))),
    () => 'This must be an array of statements',
  ),
  predicateFor(
    (v) => {
      const policy = JSON.parse(v) as PolicyStatementObject[];
      return !policy.every((statement) => isArray(statement.resources || statement.notResources));
    },
    () => 'Resources must be an array',
  ),
  predicateFor(
    (v) => {
      const policy = JSON.parse(v) as PolicyStatementObject[];
      return !policy.every((statement) => isArray(statement.actions || statement.notActions));
    },
    () => 'Actions must be an array',
  ),
  predicateFor(
    (v) => {
      const policy = JSON.parse(v) as PolicyStatementObject[];
      return !policy.every((statement) => isString(statement.effect));
    },
    () => 'Effect must be a string',
  ),
]);

export function getFirstInfoMessage(statements: PolicyStatement[]) {
  let message;
  for (const s of statements) {
    message = createPolicyStatement(s).infoMessage();
    if (message) {
      return message;
    }
  }
}

const someEmptyStatement = (policy: Policy) => policy.some((s: PolicyStatement) => s.resources.isEmpty());

export const isValidRacRulePolicy = (policy: Policy) =>
  predicateFor(
    (v: string) => {
      const isValid = v === 'all' || (v === 'inlinePolicy' && !someEmptyStatement(policy));
      return !isValid;
    },
    () => 'Resource is required',
  );

export const replaceResourceStringId = (resourceSpec: string, id: string) => {
  const resourceSpecArr = resourceSpec.split('/');
  resourceSpecArr[resourceSpecArr.length - 1] = id;
  return resourceSpecArr.join('/');
};
