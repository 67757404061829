import { createTaskRunner } from '@gonfalon/async';
import { chunk } from '@gonfalon/collections';
import { flagStatusesEnvironmentKeyLimit, flagStatusesFlagKeyLimit } from '@gonfalon/dogfood-flags';
import { queryFeatureFlagStatuses, schemas } from '@gonfalon/openapi';

import { mergeEnvironmentsByKey } from './mergeEnvironmentsByKey';
import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export type FlagStatusTask = {
  projectKey: string;
  flagKey: string;
  environmentKeys: string[];
};

export const queryFeatureFlagStatusesTaskRunner = createTaskRunner<
  FlagStatusTask,
  Record<string, schemas['FeatureFlagStatus']>,
  NonNullable<Awaited<ReturnType<typeof queryFeatureFlagStatuses>>['data']>
>({
  runner: async (inputs) =>
    reactQueryResponseAdapter(
      queryFeatureFlagStatuses({
        projectKey: inputs[0].projectKey,
        body: {
          flagKeys: Array.from(new Set(inputs.map((input) => input.flagKey))),
          environmentKeys: Array.from(new Set(inputs.map((input) => input.environmentKeys).flat())),
        },
      }),
    ),
  resolver: (input, task) => {
    const { items } = input;
    const flag = items.find((item) => item.key === task.flagKey);

    if (!flag) {
      return {};
    }

    const status: Record<string, schemas['FeatureFlagStatus']> = {};
    for (const environmentKey of task.environmentKeys) {
      const value = flag.environments[environmentKey];
      if (!value) {
        continue;
      }
      status[environmentKey] = value;
    }

    return status;
  },
  batching: {
    batcher: queryFeatureFlagStatusesTaskBatcher,
    resultMerger(data) {
      return {
        _links: data[0]._links,
        items: mergeEnvironmentsByKey(data),
      };
    },
  },
});

export function queryFeatureFlagStatusesTaskBatcher(inputs: FlagStatusTask[]) {
  const flagKeyChunks = chunk(Array.from(new Set(inputs.map((input) => input.flagKey))), flagStatusesFlagKeyLimit());
  const environmentKeyChunks = chunk(
    Array.from(new Set(inputs.map((input) => input.environmentKeys).flat())),
    flagStatusesEnvironmentKeyLimit(),
  );

  const batches: FlagStatusTask[][] = [];

  environmentKeyChunks.forEach((chunkOfEnvironmentKeys) => {
    flagKeyChunks.forEach((chunkOfFlagKeys) => {
      const tasks: FlagStatusTask[] = [];
      chunkOfFlagKeys.forEach((flagKey) => {
        tasks.push({ projectKey: inputs[0].projectKey, flagKey, environmentKeys: chunkOfEnvironmentKeys });
      });
      batches.push(tasks);
    });
  });

  return batches;
}
