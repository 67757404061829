import { useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import styles from './useTextOverflow.module.css';

export function useTextOverflow<T extends HTMLElement>({ override }: { override?: boolean } = {}) {
  const [hasOverflow, setOverflow] = useState(false);
  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    const node = ref.current;

    function compute() {
      if (!node) {
        return;
      }

      setOverflow(node.scrollWidth > node.clientWidth);
    }

    if (node) {
      compute();

      const observer = new ResizeObserver((entries) => {
        if (entries.length === 0) {
          return;
        }

        compute();
      });

      observer.observe(node);

      return () => observer.unobserve(node);
    }
  });

  const props = {
    'data-overflow': hasOverflow ? 'true' : undefined,
    className: clsx(styles.textOverflow, {
      [styles.active]: !override,
    }),
  };

  return { ref, hasOverflow, props } as const;
}
