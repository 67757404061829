import { createTrackerForCategory } from '@gonfalon/analytics';
import {
  enforceResourceNameLength,
  getProjectEnvironmentsPageSize,
  getProjectListPageSize,
  isProjectCreationEnabled,
} from '@gonfalon/dogfood-flags';
import { isEmpty } from '@gonfalon/es6-utils';
import { KEY_MAX_LENGTH } from '@gonfalon/strings';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, OrderedMap, Record, Set } from 'immutable';
import { normalize, schema } from 'normalizr';
import qs from 'qs';

import { AccessChecks, allowDecision, createAccessDecision } from 'utils/accessUtils';
import { Member } from 'utils/accountUtils';
import { convertMapToOrderedMap } from 'utils/collectionUtils';
import { createEnvironment, Environment } from 'utils/environmentUtils';
import { CreateFunctionInput, ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { makeFilter } from 'utils/stringUtils';
import { isLength, isNotEmpty, isReservedKey, isValidTagList, validateRecord } from 'utils/validationUtils';

import { ClientSideAvailability } from './flagUtils';

export const MAX_PROJECT_KEY_LENGTH = KEY_MAX_LENGTH;
export const MAX_PROJECT_NAME_LENGTH = 256;

export const truncateKey = (key: string) => key.substring(0, MAX_PROJECT_KEY_LENGTH);

const validateFn = (p: Project | NewProject) =>
  validateRecord(
    p,
    isNotEmpty('name'),
    enforceResourceNameLength() ? isLength(1, MAX_PROJECT_NAME_LENGTH)('name') : () => undefined,
    isNotEmpty('key'),
    isReservedKey('key'),
    isLength(1, MAX_PROJECT_KEY_LENGTH)('key'),
    isValidTagList('tags'),
  );

type NewProjectProps = Omit<ProjectProps, '_access' | '_links' | 'environments' | '_id'>;

export class NewProject extends Record<NewProjectProps>({
  key: '',
  name: '',
  tags: Set(),
  defaultClientSideAvailability: Map({ usingMobileKey: false, usingEnvironmentId: false }),
}) {
  isNew() {
    return true;
  }
  validate() {
    return validateFn(this);
  }
}

export function createNewProject(props?: CreateFunctionInput<NewProject>) {
  if (!props) {
    return new NewProject();
  }
  return props instanceof NewProject ? props : new NewProject(fromJS(props));
}

export type ProjectProps = {
  _access?: AccessChecks;
  _links: ImmutableMap<{
    self: Link;
  }>;
  _id: string;
  key: string;
  name: string;
  tags: Set<string>;
  defaultClientSideAvailability: ClientSideAvailability;
  environments: List<string>;
  environmentsTotalCount?: number;
  defaultReleasePipelineKey?: string;
};

const defaultProjectProps = {
  _access: undefined,
  _links: Map(),
  key: '',
  _id: '',
  name: '',
  tags: Set(),
  defaultClientSideAvailability: Map({ usingMobileKey: false, usingEnvironmentId: false }),
  environments: List(),
  environmentsTotalCount: 0,
  defaultReleasePipelineKey: '',
};

export class Project extends Record<ProjectProps>(defaultProjectProps) {
  checkAccess({ profile }: { profile: Member }) {
    const access = this._access;

    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (profile.hasStrictWriterRights() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action?: string) => {
      const deniedAction = access.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }

  isNew() {
    return this.key === '';
  }

  validate() {
    return validateFn(this);
  }

  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  environmentsLink() {
    return this._links.getIn(['environments', 'href']);
  }
}

export function createProject(props?: CreateFunctionInput<Project>) {
  if (!props) {
    return new Project();
  }
  const project = props instanceof Project ? props : new Project(fromJS(props));
  return project.update('tags', (tags) => (tags.size ? tags.toSet() : Set()));
}

export function canCreateProject(profile: Member, projects: OrderedMap<string, Project>) {
  if (!profile.hasWriterRights()) {
    return false;
  }

  if (projects.size < 2) {
    return true;
  }

  return isProjectCreationEnabled();
}

export const environmentSchema = new schema.Entity('environments', {}, { idAttribute: '_id' });

export const projectSchema = new schema.Entity(
  'projects',
  {
    environments: [environmentSchema],
  },
  {
    idAttribute: 'key',
  },
);

type RawProjectRep = Omit<ProjectProps, 'environments'> & { environments: List<Environment> };
type RawProjectPaginatedRep = Omit<ProjectProps, 'environments'> & {
  environments: { items: List<Environment>; totalCount?: number };
};

export type NormalizedProjectCollectionResponse = ImmutableMap<{
  entities: ImmutableMap<{
    environments: OrderedMap<string, Environment>;
    projects: OrderedMap<string, Project>;
  }>;
  result: ImmutableMap<{
    _links: ImmutableMap<{ self: Link }>;
    items: string[];
  }>;
}>;

export type NormalizedEnvironmentsCollectionResponse = ImmutableMap<{
  entities: ImmutableMap<{
    environments: OrderedMap<string, Environment>;
  }>;
  result: ImmutableMap<{
    _links: ImmutableMap<{ self: Link }>;
    items: string[];
  }>;
}>;

export function formatPaginatedProjects(
  projects: Array<CreateFunctionInput<RawProjectPaginatedRep>>,
): Array<CreateFunctionInput<RawProjectRep>> {
  const paginatedProjects: Array<CreateFunctionInput<RawProjectRep>> = projects.map(
    (project: CreateFunctionInput<RawProjectPaginatedRep>) => ({
      ...project,
      environments: project?.environments?.items,
      environmentsTotalCount: project?.environments?.totalCount,
    }),
  );
  return paginatedProjects;
}

export function processPaginatedProjectCollectionRep(rep: {
  items: Array<CreateFunctionInput<RawProjectPaginatedRep>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _links?: { [k: string]: any };
}) {
  const normalized = normalize({ ...rep, items: formatPaginatedProjects(rep.items) }, { items: [projectSchema] });
  return fromJS(normalized).withMutations(
    (
      map: ImmutableMap<{
        entities: {
          projects: Map<string, CreateFunctionInput<Project>>;
          environments: Map<string, CreateFunctionInput<Environment>>;
          environmentsTotalCount: number;
        };
      }>,
    ) => {
      map.updateIn(['entities', 'projects'], (projects) => convertMapToOrderedMap(projects, normalized.result.items));
      map.updateIn(['entities', 'projects'], (projects) => projects.reverse().map(createProject));
      map.updateIn(['entities', 'environments'], (envs) => envs && envs.map(createEnvironment));
    },
  );
}

export function processProjectCollectionRep(rep: {
  items: Array<CreateFunctionInput<RawProjectRep>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _links?: { [k: string]: any };
}) {
  const normalized = normalize(rep, { items: [projectSchema] });
  return fromJS(normalized).withMutations(
    (
      map: ImmutableMap<{
        entities: {
          projects: Map<string, CreateFunctionInput<Project>>;
          environments: Map<string, CreateFunctionInput<Environment>>;
        };
      }>,
    ) => {
      map.updateIn(['entities', 'projects'], (projects) => convertMapToOrderedMap(projects, normalized.result.items));
      map.updateIn(['entities', 'projects'], (projects) => projects.reverse().map(createProject));
      map.updateIn(['entities', 'environments'], (envs) => envs && envs.map(createEnvironment));
    },
  );
}

// TODO: Move all environment related utilities to environmentUtils.ts
export function processProjectEnvironmentsCollection(res: {
  totalCount?: number;
  items: Environment[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _links?: { [k: string]: any };
}) {
  const normalized = normalize(res, { items: [environmentSchema] });
  return fromJS(normalized).withMutations(
    (
      map: ImmutableMap<{
        entities: {
          environments: OrderedMap<string, CreateFunctionInput<Environment>>;
        };
      }>,
    ) => {
      map.updateIn(['entities', 'environments'], (envs) => convertMapToOrderedMap(envs, normalized.result.items));
      map.updateIn(['entities', 'environments'], (envs) => envs && envs.map(createEnvironment));
    },
  );
}

export class ProjectWithEnvironmentEntities extends Record<RawProjectRep>(defaultProjectProps) {}

export const normalizeProject = (projectJson: {}) => normalize(projectJson, { environments: [environmentSchema] });
export const normalizeEnvironments = (environmentJson: {}) =>
  normalize(environmentJson, { environments: [environmentSchema] });

export type NormalizedProjectResponse = ImmutableMap<{
  result: Project;
  entities: ImmutableMap<{
    environments: Map<string, Environment>;
  }>;
}>;

export function createNormalizedProjectEntity(
  rep: CreateFunctionInput<ProjectWithEnvironmentEntities>,
): NormalizedProjectResponse {
  const data = normalizeProject(rep);
  return fromJS(data).withMutations(
    (
      map: ImmutableMap<{ result: ProjectProps; entities: ImmutableMap<{ environments: Map<string, Environment> }> }>,
    ) => {
      map.update('result', createProject);
      map.updateIn(['entities', 'environments'], (envs) => envs.map(createEnvironment));
    },
  );
}

export function processLoadedProjectRep(rep: CreateFunctionInput<ProjectWithEnvironmentEntities>) {
  return createNormalizedProjectEntity(rep);
}

type ProjectFiltersType = {
  tagFilter: Set<string>;
  textFilter: string;
  sort: string;
  offset: number;
  limit: number;
};

export type EnvironmentFiltersType = {
  tagFilter: Set<string>;
  textFilter: string;
  limit: number;
  offset: number;
  sort: string;
};

type EnvironmentsFiltersQuery = Partial<{
  tagFilter: string[];
  query: string;
  textFilter: string;
  sort: string;
  limit: number;
  offset: number;
}>;

type ProjectFiltersQuery = Partial<{
  tagFilter: string[];
  textFilter: string;
  sort: string;
  limit?: number;
  offset?: number;
}>;

export class ProjectFilters extends Record<ProjectFiltersType>({
  tagFilter: Set(),
  textFilter: '',
  sort: '-createdOn',
  limit: 0,
  offset: 0,
}) {
  toQuery() {
    const ret: ProjectFiltersQuery = {};
    if (!this.tagFilter.isEmpty()) {
      ret.tagFilter = this.tagFilter.toList().sort().toJS();
    }
    if (!isEmpty(this.textFilter)) {
      ret.textFilter = this.textFilter;
    }
    if (!isEmpty(this.sort) && this.sort !== '-createdOn') {
      ret.sort = this.sort;
    }
    return ret;
  }
  withQuery(query: string) {
    return this.set('offset', 0).set('textFilter', query);
  }
  toBackendQueryString() {
    const q: Partial<ProjectFiltersQuery> & { filter?: string } = {};
    const filterParts = [];

    if (this.textFilter && this.textFilter !== '') {
      filterParts.push(`query:${this.textFilter}`);
    }

    if (this.tagFilter?.size) {
      filterParts.push(`tags:${this.tagFilter.toArray().join('+')}`);
    }

    q.filter = filterParts.join(',');

    if (this.limit && this.limit > 0) {
      q.limit = this.limit;
    }
    if (this.offset) {
      q.offset = this.offset;
    }
    if (this.sort) {
      q.sort = this.sort;
    }

    return qs.stringify(q, { indices: false, addQueryPrefix: true });
  }
  toFrontendQueryString(options?: { omitPaginationParams?: boolean }) {
    const q = {} as EnvironmentsFiltersQuery;

    if (!isEmpty(this.textFilter)) {
      q.textFilter = this.textFilter;
    }
    if (options?.omitPaginationParams) {
      return q;
    }

    const pageSize = getProjectEnvironmentsPageSize();

    if (this.limit && this.limit > 0 && (this.offset || this.limit !== pageSize)) {
      q.limit = this.limit;
    }
    if (this.offset) {
      q.offset = this.offset;
    }
    if (this.tagFilter) {
      q.tagFilter = this.tagFilter.toArray();
    }
    if (this.sort) {
      q.sort = this.sort;
    }
    return q;
  }
  toQueryString(options?: { omitPaginationParams?: boolean }) {
    return qs.stringify(this.toFrontendQueryString(options), { indices: false });
  }
}

export class EnvironmentsFilters extends Record<EnvironmentFiltersType>({
  tagFilter: Set(),
  textFilter: '',
  limit: -1,
  offset: 0,
  sort: '',
}) {
  createFiltersFromBackendQueryString(queryString: string) {
    const backendParams = qs.parse(queryString, { ignoreQueryPrefix: true });
    const props = {
      query: backendParams.query,
      limit: parseInt(backendParams.limit, 10),
      offset: parseInt(backendParams.offset, 10),
    };
    return createEnvironmentsFilters(props);
  }
  toSearchParams() {
    const filterSearchParams = new URLSearchParams();
    filterSearchParams.set('textFilter', this.textFilter || '');
    filterSearchParams.set('offset', this.offset?.toString() || '0');
    filterSearchParams.set('limit', this.limit?.toString() || '10');
    filterSearchParams.set('sort', this.sort || '');
    filterSearchParams.set('tagFilter', this.tagFilter?.join(',') || '');
    return filterSearchParams;
  }
  toBackendQueryString() {
    const q: Partial<EnvironmentFiltersType> & { filter?: string } = {};
    const filterParts = [];

    if (this.textFilter && this.textFilter !== '') {
      filterParts.push(`query:${this.textFilter}`);
    }

    if (this.tagFilter?.size) {
      filterParts.push(`tags:${this.tagFilter.toArray().join('+')}`);
    }

    q.filter = filterParts.join(',');
    if (this.limit && this.limit > 0) {
      q.limit = this.limit;
    }
    if (this.offset) {
      q.offset = this.offset;
    }

    q.sort = 'critical';

    return qs.stringify(q, { indices: false, addQueryPrefix: true });
  }
  toFrontendEnvironmentsQueryString(options?: { omitPaginationParams?: boolean }) {
    const q = {} as EnvironmentsFiltersQuery;

    if (!isEmpty(this.textFilter)) {
      q.textFilter = this.textFilter;
    }
    if (options?.omitPaginationParams) {
      return q;
    }

    const pageSize = getProjectEnvironmentsPageSize();

    if (this.limit && this.limit > 0 && (this.offset || this.limit !== pageSize)) {
      q.limit = this.limit;
    }
    if (this.offset) {
      q.offset = this.offset;
    }
    if (this.tagFilter) {
      q.tagFilter = this.tagFilter.toArray();
    }
    return q;
  }
  toQueryString(options?: { omitPaginationParams?: boolean }) {
    return qs.stringify(this.toFrontendEnvironmentsQueryString(options), { indices: false });
  }
  withQuery(query: string) {
    return this.set('offset', 0).set('textFilter', query);
  }
}

export function createProjectFilters(props = {}) {
  return new ProjectFilters(fromJS(props));
}

export function createEnvironmentsFilters(props = {}) {
  return new EnvironmentsFilters(fromJS(props));
}

export function createEnvironmentsFiltersFromQuery(queryMap: EnvironmentsFiltersQuery) {
  const textFilter = queryMap ? queryMap?.textFilter : '';
  const tagFilter = queryMap ? queryMap.tagFilter : Set();
  const sort = queryMap ? queryMap.sort : 'createdOn';
  let tagsArray;
  if (Set.isSet(tagFilter)) {
    tagsArray = tagFilter;
  } else if (Array.isArray(tagFilter)) {
    tagsArray = Set([...tagFilter]);
  } else {
    tagsArray = tagFilter ? Set([tagFilter]) : Set();
  }
  const props = {
    textFilter: textFilter || '',
    tagFilter: tagsArray,
    sort: sort || 'createdOn',
    offset: queryMap?.offset || 0,
    limit: queryMap?.limit || getProjectEnvironmentsPageSize(),
  };
  return createEnvironmentsFilters(props);
}

export function createProjectFiltersFromQuery(queryMap: ProjectFiltersQuery) {
  const textFilter = queryMap ? queryMap.textFilter : '';
  const tagFilter = queryMap ? queryMap.tagFilter : Set();
  const sort = queryMap ? queryMap.sort : 'createdOn';
  let tagsArray;
  if (Set.isSet(tagFilter)) {
    tagsArray = tagFilter;
  } else if (Array.isArray(tagFilter)) {
    tagsArray = Set([...tagFilter]);
  } else {
    tagsArray = tagFilter ? Set([tagFilter]) : Set();
  }
  const props = {
    textFilter: textFilter || '',
    tagFilter: tagsArray,
    sort: sort || '-createdOn',
    limit: queryMap?.limit || getProjectListPageSize(),
    offset: queryMap?.offset || 0,
  };

  return createProjectFilters(props);
}

export function filterProjects(
  projects: OrderedMap<string, Project>,
  textFilter: string,
  tagFilter: Set<string>,
  sort?: string,
) {
  let collection = projects;

  if (textFilter !== '') {
    collection = collection.filter(makeFilter(textFilter, 'name', 'key'));
  }

  if (!tagFilter.isEmpty()) {
    collection = collection.filter((p) => p.tags.isSuperset(tagFilter));
  }

  if (sort) {
    if (sort === '-createdOn') {
      collection = collection.reverse();
    }

    if (sort === 'name' || sort === '-name') {
      collection = collection.sort((a, b) => {
        if (sort === 'name') {
          return a.name.localeCompare(b.name);
        }
        if (sort === '-name') {
          return b.name.localeCompare(a.name);
        }

        return 0;
      });
    }
  }

  return collection;
}

export function defineKeyConventionObj(value: string, prefix: string) {
  return {
    ...(value !== 'none' && { case: value }),
    ...(prefix && { prefix }),
  };
}

export const trackProjectEvent = createTrackerForCategory('Project');

// Projects Page Instrumentation
export const trackProjectsListPageLoaded = () => trackProjectEvent('Projects List Page Loaded');
export const trackProjectPageLoaded = () => trackProjectEvent('Project Loaded');
export const trackSelectProjectTextClicked = () => trackProjectEvent('Project Link Text Clicked');
export const trackCreateProjectButtonClicked = () => trackProjectEvent('Create Project Button Clicked');
export const trackCreateProjectFormSubmitted = () => trackProjectEvent('Create Project Form Submitted');

// Individual Project Instrumentation - Environments tab
export const trackProjectEnvironmentsClicked = () => trackProjectEvent('Project Environments Clicked');
export const trackProjectReleasePipelinesClicked = () => trackProjectEvent('Project Release Pipelines Clicked');
export const trackProjectEnvironmentsLoaded = () => trackProjectEvent('Project Environments Loaded');
export const trackCreateEnvironmentButtonClicked = () => trackProjectEvent('Create Environment Button Clicked');
export const trackCreateEnvironmentFormSubmitted = () => trackProjectEvent('Create Environment Form Submitted');
export const trackDeleteEnvironmentButtonClicked = () => trackProjectEvent('Delete Environment Button Clicked');
export const trackDeleteEnvironmentConfirmationLoaded = () =>
  trackProjectEvent('Delete Environment Confirmation Loaded');

export const trackDeleteEnvironmentFormSubmitted = () => trackProjectEvent('Delete Environment Form Submitted');
export const trackEditEnvironmentButtonClicked = () => trackProjectEvent('Edit Environment Button Clicked');
export const trackEditEnvironmentFormSubmitted = () =>
  trackProjectEvent('Save Environment Button Clicked and Form Submitted');

export const trackEnvironmentEditorLoaded = () => trackProjectEvent('Edit Environment Panel Loaded');
export const trackEnvironmentCreatorLoaded = () => trackProjectEvent('Create Environment Panel Loaded');

// Individual Project Instrumentation - Settings tab
export const trackProjectSettingsPageClicked = () => trackProjectEvent('Project Settings Page Clicked');
export const trackProjectSettingsPageLoaded = () => trackProjectEvent('Project Settings Page Loaded');
export const trackDeleteProjectButtonClicked = () => trackProjectEvent('Delete Project Button Clicked');
export const trackDeleteProjectFormSubmitted = () => trackProjectEvent('Delete Project Form Submitted');
export const trackUpdateProjectInformation = () =>
  trackProjectEvent('Update Project Information Button Clicked and Form Submitted');

// Individual Project Instrumentation - Flag defaults tab
export const trackProjectFlagDefaultsPageClicked = () => trackProjectEvent('Project Flag Defaults Page Clicked');

// Individual Project Instrumentation - Payload filters tab
export const trackProjectPayloadFiltersPageClicked = () => trackProjectEvent('Project Payload Filters Page Clicked');
