import { Alert } from '@launchpad-ui/core';

import { AccessCheck } from '../internal/types';

import { getAccessRestrictedText } from './getAccessRestrictedText';

export function AccessRestricted({
  access,
  kind,
}: {
  access: AccessCheck;
  kind: 'action' | 'field' | 'setting' | 'token';
}) {
  if (access.isAllowed) {
    return null;
  }

  return (
    <Alert kind="info" size="small" isInline compact>
      {getAccessRestrictedText({ access, kind })}
    </Alert>
  );
}
