import { createTrackerForCategory } from '@gonfalon/analytics';
import { isNoAccessRoleEnabled } from '@gonfalon/dogfood-flags';
import { capitalize } from '@gonfalon/strings';
// eslint-disable-next-line no-restricted-imports
import { fromJS, is as isImmutable, List, Map, Record } from 'immutable';

import { AccessChecks, createAccessDecision } from 'utils/accessUtils';
import { Member } from 'utils/accountUtils';
import { Link } from 'utils/linkUtils';
import { isPolicyString, PolicyStatementObject } from 'utils/policyUtils';
import { isJSON, isNotEmpty, validateRecord } from 'utils/validationUtils';

import { CreateFunctionInput, ImmutableMap } from './immutableUtils';

export enum RoleName {
  NO_ACCESS = 'no_access',
  READER = 'reader',
  WRITER = 'writer',
  OWNER = 'owner',
  ADMIN = 'admin',
  CUSTOM = 'custom',
  INLINE = 'inline',
}

let roles = [RoleName.READER, RoleName.WRITER, RoleName.ADMIN];

if (isNoAccessRoleEnabled()) {
  roles = [RoleName.NO_ACCESS, ...roles];
}

type GetMemberCustomRolesArgs = {
  member: Member;
  allCustomRoles: Map<string, Role>;
};

type StringRoleMap = {
  [key: string]: Role;
};

/**
 * Identify the list of custom roles that the member can access.
 * If the member is an owner or admin, return all of the custom roles.
 * Otherwise, return the custom roles the member has been assigned directly or through the teams they are on.
 *
 * Note: It would likely be better to move this logic to the backend so that we don't need to be aware of this complexity here.
 *
 * @param {GetMemberCustomRolesArgs}
 * @returns an immutable collection of custom roles that the member can access.
 */
export const getMemberCustomRoles = ({ member, allCustomRoles }: GetMemberCustomRolesArgs) => {
  // If the user is an admin or owner, they have access to all custom roles.
  if (member.isAdminOrOwner()) {
    return allCustomRoles;
  }

  // Create a map of custom role based on their key.
  // Why? Teams currently only have a list of custom role keys associated with them.
  //      So we do this to make it quick to identify the custom role based on the key.
  const customRolesByKey = allCustomRoles.reduce((map, role) => {
    // eslint-disable-next-line no-param-reassign
    map[role.get('key')] = role;
    return map;
  }, {} as StringRoleMap);

  // A member has multiple teams that each have a list of custom role keys.
  // We convert this list into a map of custom role key => custom role.
  // Why? Doing so ensures that we can get a unique list of custom roles.
  const memberRoles = (member.teams || List([])).reduce((teamRoles, team) => {
    const teamRoleKeys = team.get('customRoleKeys') || [];
    teamRoleKeys.forEach((customRoleKey) => {
      const customRole = customRolesByKey[customRoleKey];
      if (customRole && customRole._id) {
        // eslint-disable-next-line no-param-reassign
        teamRoles[customRole._id] = customRole;
      }
    });

    return teamRoles;
  }, {} as StringRoleMap);

  // Create a map of custom roles based on their id.
  // Why? The member.customRoles attribute is a list of custom role ids.
  //      So we do this to make it quick to identify the custom role based on the id.
  const customRolesById = allCustomRoles.reduce((map, role) => {
    if (role._id) {
      // eslint-disable-next-line no-param-reassign
      map[role._id] = role;
    }

    return map;
  }, {} as StringRoleMap);

  // member.customRoles contains a list of custom role ids.
  // Iterate over the list and add any valid custom roles to the map of member roles.
  member.customRoles.forEach((id) => {
    const customRole = customRolesById[id];
    if (customRole) {
      // eslint-disable-next-line no-param-reassign
      memberRoles[id] = customRole;
    }
  });

  // Return an immutable map of custom roles.
  return Map(memberRoles);
};

export const simpleRoles = Object.freeze(roles);

export const getSimpleRolesUpToMember = (member: Member) => {
  let rs: RoleName[] = [];
  if (member.isAdminOrOwner()) {
    rs = rs.concat(simpleRoles);
  } else if (member.isWriter()) {
    rs = rs.concat([RoleName.READER, RoleName.WRITER]);
  } else {
    rs = rs.concat([RoleName.READER]);
  }

  return rs;
};

const defaultPolicy = [
  {
    effect: 'deny',
    resources: [],
    actions: [],
  },
];

export const roleIdentifier = (role: Role) => role.key || role._id;

export type RoleType = {
  _links: ImmutableMap<{
    self: Link;
  }>;
  _id: string;
  _access: AccessChecks | null;
  name: string;
  key: string;
  description: string;
  basePermissions: RoleName.READER | RoleName.NO_ACCESS | null;
  policy: List<PolicyStatementObject>;
};

export type RoleFormType = Omit<RoleType, '_access' | 'policy'> & { policy: string };

export class Role extends Record<RoleType>({
  _access: null,
  _links: Map(),
  _id: '',
  name: '',
  key: '',
  description: '',
  basePermissions: RoleName.READER,
  policy: List(defaultPolicy),
}) {
  checkAccess({ profile }: { profile: Member }) {
    const access = this._access;
    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }
    if (profile.hasStrictWriterRights() || !(access && access.get('denied'))) {
      return (action: string) =>
        action === 'updateMembers'
          ? createAccessDecision({ isAllowed: false, appliedRoleName: 'Writer' })
          : createAccessDecision({ isAllowed: true });
    }
    return (action: string) => {
      const allowed = createAccessDecision({ isAllowed: true });

      if (!access) {
        return allowed;
      }

      const deniedAction = access.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }

      return allowed;
    };
  }

  identifier() {
    return roleIdentifier(this);
  }

  siteLink() {
    return `/settings/roles/${this.identifier()}/edit`;
  }

  viewLink() {
    return `/settings/roles/${this.identifier()}/view`;
  }

  selfLink() {
    return this.getIn(['_links', 'self', 'href']);
  }

  toForm() {
    const role = {
      _id: this._id,
      _links: this._links,
      name: this.name,
      key: this.key,
      description: this.description,
      basePermissions: this.basePermissions || RoleName.READER,
      policy: JSON.stringify(this.policy, null, 2),
    };
    return createRoleForm(role);
  }

  mergeForm(form: RoleForm) {
    return this.withMutations((role) => {
      role
        .set('name', form.name)
        .set('key', form.key)
        .set('description', form.description)
        .set('basePermissions', form.basePermissions)
        .set('policy', JSON.parse(form.policy));
    });
  }
}

export type RoleSummaryType = {
  _id: string;
  _links: ImmutableMap<{ self: Link }>;
  key: string;
  name: string;
};

export class RoleForm extends Record<RoleFormType>({
  _id: '',
  _links: Map(),
  name: '',
  key: '',
  description: '',
  basePermissions: RoleName.READER,
  policy: JSON.stringify(defaultPolicy, null, 2),
}) {
  validate() {
    return validateRecord(
      this,
      isNotEmpty('name'),
      isNotEmpty('key'),
      isNotEmpty('policy'),
      isJSON('policy'),
      isPolicyString('policy'),
    );
  }

  is(other: RoleForm) {
    try {
      return (
        this.name === other.name &&
        this.key === other.key &&
        this.description === other.description &&
        this.basePermissions === other.basePermissions &&
        isImmutable(fromJS(JSON.parse(this.policy)), fromJS(JSON.parse(other.policy)))
      );
    } catch (e) {
      // could fail to parse as JSON
      return false;
    }
  }

  toRep() {
    const role = {
      name: this.name,
      key: this.key,
      description: this.description,
      basePermissions: this.basePermissions || RoleName.READER,
      policy: JSON.parse(this.policy),
    };
    return createRole(role);
  }

  selfLink() {
    return this._links.getIn(['self', 'href']);
  }

  isNew() {
    return !this._id;
  }
}

export function createRole(props: CreateFunctionInput<Role>) {
  return props instanceof Role ? props : new Role(fromJS(props));
}

export function createRoleForm(props: CreateFunctionInput<RoleForm>) {
  return props instanceof RoleForm ? props : new RoleForm(fromJS(props));
}

export const formattedRoleName = (role: RoleName) => capitalize(role.replace('_', ' '));

export const roleDescription = (role: RoleName) => {
  switch (role) {
    case RoleName.NO_ACCESS:
      return 'Members can’t read or modify anything until assigned another role or to a team';
    case RoleName.READER:
      return 'Members can read everything, but can’t modify anything';
    case RoleName.WRITER:
      return 'Members can read everything and make changes to the platform, but can’t manage account members, manage billing details, or change the account owner';
    case RoleName.ADMIN:
      return 'Members can read and modify anything, but can’t change the account owner';
    case RoleName.CUSTOM:
      return 'Members will have specific custom roles that you’ll assign below';
    default:
      return '';
  }
};

export const samlRoleDescription = (role: RoleName) => {
  switch (role) {
    case RoleName.NO_ACCESS:
      return 'Members won’t be able to read or modify anything until assigned another role or to a team';
    case RoleName.READER:
      return 'Members will be able to read everything, but won’t be able to modify anything until assigned another role or to a team';
    case RoleName.CUSTOM:
      return 'Members will have a specific custom role that you’ll assign below';
    default:
      return '';
  }
};

const trackCustomRoles = createTrackerForCategory('CustomRoles');

export const trackCreateRoleViewed = () => trackCustomRoles('Custom Role Create Viewed');
export const trackCreateRoleSubmitted = () => trackCustomRoles('Custom Role Create Submitted');
export const trackEditRoleViewed = () => trackCustomRoles('Custom Role Edit Viewed');
export const trackEditRoleSubmitted = () => trackCustomRoles('Custom Role Edit Submitted');
export const trackAdvancedEditorLinkClicked = () => trackCustomRoles('Custom Role Advanced Editor Link Clicked');
export const trackSimpleEditorLinkClicked = () => trackCustomRoles('Custom Role Simple Editor Link Clicked');
export const trackCustomRoleDetailsViewed = () => trackCustomRoles('Custom Role Details Viewed');
export const trackResourceFinderViewed = () => trackCustomRoles('Custom Role Resource Finder Viewed');
export const trackResourceFinderResourceClicked = () =>
  trackCustomRoles('Custom Role Resource Finder Resource Clicked');
export const trackCustomRoleDefaultReaderCheckboxClicked = () =>
  trackCustomRoles('Custom Role Default Reader Checkbox Clicked');
