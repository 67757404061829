import { and, anyOf, equals, QueryFilter, queryfilterToString } from '@gonfalon/queryfilter';
import { type ResourceSpecifier, resourceSpecifierToString } from '@gonfalon/resource-specifiers';

import { GetApprovalRequestsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export interface GetApprovalRequestsQueryParams
  extends Omit<GetApprovalRequestsOpenAPIQueryParams, 'filter' | 'expand'> {
  expand?: Array<'flag' | 'project' | 'environments'>;
  filter?: {
    resourceId?: ResourceSpecifier;
    requestorId?: string;
    notifyMemberIds?: string[];
    reviewStatus?: Array<'approved' | 'declined' | 'pending'>;
    status?: Array<'pending' | 'scheduled' | 'failed' | 'completed'>;
  };
}

export function querySerializer(params: GetApprovalRequestsQueryParams) {
  const searchParams = new URLSearchParams();

  if (params.filter) {
    const serializedFilter = serializeApprovalRequestFilterParam(params.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  if (params.expand) {
    searchParams.set('expand', params.expand.join(','));
  }

  if (params.limit !== undefined && params.limit > 0) {
    searchParams.set('limit', params.limit.toString());
  }

  if (params.offset !== undefined && params.offset > 0) {
    searchParams.set('offset', params.offset.toString());
  }

  return searchParams.toString();
}

function serializeApprovalRequestFilterParam(value: NonNullable<GetApprovalRequestsQueryParams['filter']>) {
  const filter: QueryFilter[] = [];

  if (value.requestorId) {
    filter.push(equals('requestorId', value.requestorId));
  }

  if (value.status) {
    filter.push(anyOf('status', value.status));
  }

  if (value.reviewStatus) {
    filter.push(anyOf('reviewStatus', value.reviewStatus));
  }

  if (value.notifyMemberIds) {
    filter.push(anyOf('notifyMemberIds', value.notifyMemberIds));
  }

  if (value.resourceId) {
    filter.push(equals('resourceId', resourceSpecifierToString(value.resourceId)));
  }

  return queryfilterToString(and(filter));
}
