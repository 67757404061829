import { beastModeOptions } from '@gonfalon/dogfood-flags';

import { getExpiration } from './expiration';

export function shouldRefreshBeastMode(routeName: string) {
  // check if beast mode is expired
  if (getExpiration() >= new Date().getTime()) {
    return false;
  }
  const options = beastModeOptions();
  return options.all || options.routes.includes(routeName);
}
