import { GetFeatureFlagOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetFeatureFlagQueryParams = Omit<GetFeatureFlagOpenAPIQueryParams, 'env' | 'expand'> & {
  env?: string[];
  expand?: Array<'evaluation'>;
};

export const apiVersion = '20160426';

export function querySerializer(queryParams: GetFeatureFlagQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.env) {
    for (const env of queryParams.env) {
      searchParams.append('env', env);
    }
  }

  const expand: string[] = queryParams.expand ?? [];

  // NOTE: This is a temporary fix to ensure every query for a flag includes the migration settings.
  // In the future, we may update the endpoint to include migration settings for migration flags by default.
  expand.push('migrationSettings');

  searchParams.set('expand', expand.join(','));

  return searchParams.toString();
}
