import { ResourceSpecifier } from './ast';

const environmentScopedResources = [
  'segment',
  'experiment',
  'holdout',
  'user',
  'destination',
  'flag',
  'feature',
] as const satisfies Array<ResourceSpecifier['type']>;

export type EnvironmentScopedResource = Extract<
  ResourceSpecifier,
  { type: (typeof environmentScopedResources)[number] }
>;

export const isEnvironmentScopedResourceType = (resourceType: ResourceSpecifier['type']) =>
  (environmentScopedResources as readonly string[]).includes(resourceType);

export const isEnvironmentScopedResource = (resource: ResourceSpecifier): resource is EnvironmentScopedResource =>
  isEnvironmentScopedResourceType(resource.type);
