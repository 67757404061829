import { createQueryHook } from './internal/createQueryHook';
import { contexts } from './internal/queries';
import { PostContextsParams } from './internal/types';

export const useContexts = createQueryHook(
  ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params: PostContextsParams;
  }) => contexts.list({ projectKey, environmentKey, params }),
);
