import { Map, OrderedSet } from 'immutable';
import qs from 'qs';
import { AnyAction } from 'redux';

import { createImmutableState } from 'utils/immutableUtils';
import { PaginationType } from 'utils/paginationUtils';

const defaultState: PaginationType = createImmutableState({
  isFetching: false,
  nextPageUrl: undefined,
  prevPageUrl: undefined,
  firstPageUrl: undefined,
  lastPageUrl: undefined,
  prevPageOffset: 0,
  nextPageOffset: 0,
  lastPageOffset: 0,
  totalCount: 0,
  totalCountWithDifferences: 0,
  pageCount: 0,
  error: undefined,
  currentPageIds: OrderedSet(),
  ids: OrderedSet(),
});

/* eslint-disable import/no-default-export */
export default function paginate({
  types,
  mapActionToKey,
  invalidateTypes,
}: {
  types: string[];
  mapActionToKey(action: AnyAction): string;
  invalidateTypes?: string[];
}) {
  const [requestType, failureType, successType] = types;
  function updatePagination(state: PaginationType = defaultState, action: AnyAction): PaginationType {
    const pageCount: number = state.get('pageCount');
    switch (action.type) {
      case requestType:
        return state.set('isFetching', true);
      case failureType:
        return state.merge({
          isFetching: false,
          error: action.error,
        });
      case successType:
        return state.merge({
          isFetching: false,
          lastFetched: action.timestamp,
          ids: state.get('ids').union(action.response.getIn(['result', 'items']) as OrderedSet<string>),
          currentPageIds: action.response.getIn(['result', 'items']) as OrderedSet<string>,
          nextPageUrl: action.response.getIn(['result', '_links', 'next', 'href']) as string,
          prevPageUrl: action.response.getIn(['result', '_links', 'prev', 'href']) as string,
          firstPageUrl: action.response.getIn(['result', '_links', 'first', 'href']) as string,
          lastPageUrl: action.response.getIn(['result', '_links', 'last', 'href']) as string,
          prevPageOffset: +qs.parse(action.response.getIn(['result', '_links', 'prev', 'href']) as string).offset || 0,
          nextPageOffset: +qs.parse(action.response.getIn(['result', '_links', 'next', 'href']) as string).offset,
          lastPageOffset: +qs.parse(action.response.getIn(['result', '_links', 'last', 'href']) as string).offset,
          totalCount: action.response.getIn(['result', 'totalCount']) as number,
          totalCountWithDifferences: action.response.getIn(['result', 'totalCountWithDifferences']) as number,
          pageCount: pageCount + 1,
        });
      default:
        return state;
    }
  }

  return function updatePaginationByKey(
    state: Map<string, PaginationType> = createImmutableState({ all: defaultState }),
    action: AnyAction,
  ) {
    switch (action.type) {
      case requestType:
      case failureType:
      case successType:
        const key = mapActionToKey(action);
        return state.update(key, (p: PaginationType) => updatePagination(p, action));
      default:
        if (invalidateTypes?.includes(action.type)) {
          return state.clear();
        }
        return state;
    }
  };
}
