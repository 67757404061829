import { useRef, useState } from 'react';
import { useResizeObserver } from '@react-aria/utils';

/**
 * Return a ref and a descriptor indicating if the element overflow its container.
 */
export function useElementOverflow<T extends HTMLElement>() {
  const [overflow, setOverflow] = useState({ block: false, inline: false });
  const ref = useRef<T>(null);

  useResizeObserver({
    ref,
    onResize() {
      if (ref.current) {
        const block = ref.current.scrollHeight > ref.current.clientHeight;
        const inline = ref.current.scrollWidth > ref.current.clientWidth;

        if (block !== overflow.block || inline !== overflow.inline) {
          setOverflow({
            block,
            inline,
          });
        }
      }
    },
  });

  return { ref, overflow } as const;
}
