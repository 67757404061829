import { DEFAULT_LOCALE } from './constants';

export function abbreviateNumber(value: number, options: Intl.NumberFormatOptions = {}) {
  let localeString = '';
  try {
    localeString = value.toLocaleString(DEFAULT_LOCALE, { ...options, notation: 'compact', compactDisplay: 'short' });
  } catch (e) {
    localeString = value.toLocaleString(DEFAULT_LOCALE, options);
  }
  return localeString;
}
