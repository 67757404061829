import { type GetAllReleaseProgressionsForReleasePipelineQueryParams } from '@gonfalon/openapi';

import { createQueryFetcher } from './internal/createQueryFetcher';
import { releasePipelines } from './internal/queries';

export const fetchReleaseProgressions = createQueryFetcher(
  ({
    projectKey,
    releasePipelineKey,
    params,
  }: {
    projectKey: string;
    releasePipelineKey: string;
    params?: GetAllReleaseProgressionsForReleasePipelineQueryParams;
  }) => releasePipelines.detail({ projectKey, releasePipelineKey })._ctx.releaseProgressions({ params }),
);
