import { type GetEvaluationsUsageQueryParams } from '@gonfalon/openapi';

import { createSuspenseQueryHook } from './internal/createSuspenseQueryHook';
import { flags } from './internal/queries';

export const useSuspenseFlagEvaluationsForEnvironment = createSuspenseQueryHook(
  ({
    projectKey,
    flagKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    params?: GetEvaluationsUsageQueryParams;
  }) => flags.detail({ projectKey, flagKey })._ctx.evaluations({ environmentKey, flagEvaluationParams: params }),
);
