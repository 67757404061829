import { type GetHoldoutQueryParams } from '@gonfalon/openapi';

import { createQueryFetcher } from './internal/createQueryFetcher';
import { holdouts } from './internal/queries';

export const fetchHoldout = createQueryFetcher(
  ({
    projectKey,
    environmentKey,
    holdoutKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    holdoutKey: string;
    params: GetHoldoutQueryParams;
  }) => holdouts.detail({ projectKey, environmentKey, holdoutKey, params }),
);
