/**
 * Splits an array into chunks of the given size
 *
 * @param array
 * @param size
 * @returns an array of arrays with the given size
 */
export function chunk<T>(array: T[], size: number): T[][] {
  const chunks: T[][] = [];
  let index = 0;

  while (index < array.length) {
    chunks.push(array.slice(index, (index += size)));
  }

  return chunks;
}
