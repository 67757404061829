import { createQueryFetcher } from './internal/createQueryFetcher';
import { contexts } from './internal/queries';
import { PostContextsParams } from './internal/types';

export const fetchContexts = createQueryFetcher(
  ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params: PostContextsParams;
  }) => contexts.list({ projectKey, environmentKey, params }),
);
