import { Record } from 'immutable';
import { AnyAction } from 'redux';

import { ImmutableServerError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { ready } from 'utils/reduxUtils';

export type RequestProps = {
  isSummaryRep?: boolean;
  isFetching: boolean;
  lastFetched?: number | null;
  error: ImmutableServerError | null;
  controller?: AbortController;
};

export type RequestAction = AnyAction & {
  isSummaryRep?: boolean;
  timestamp?: number | null;
  error?: ImmutableServerError | null;
  controller?: AbortController;
};

export type StateWithRequestInfo<T = {}> = ImmutableMap<RequestProps & T>;

export class Request extends Record<RequestProps>({
  isFetching: false,
  lastFetched: null,
  error: null,
  isSummaryRep: false,
  controller: undefined,
}) {
  start(action?: RequestAction) {
    if (action && action.controller) {
      return this.merge({
        isFetching: true,
        controller: action.controller,
      });
    }
    return this.merge({
      isFetching: true,
    });
  }

  done(action: RequestAction) {
    const attr: RequestProps = {
      isFetching: false,
      lastFetched: action.timestamp,
      error: null,
    };
    if ('isSummaryRep' in action && typeof action.isSummaryRep === 'boolean') {
      attr.isSummaryRep = action.isSummaryRep;
    }
    return this.merge(attr);
  }

  failed(action: RequestAction) {
    return this.merge({
      isFetching: false,
      lastFetched: action.timestamp,
      error: action.error,
    });
  }

  shouldFetch() {
    return !this.lastFetched && !this.isFetching;
  }

  isReady() {
    return ready(this);
  }
}

export function createRequest(props = {}) {
  return props instanceof Request ? props : new Request(props);
}
