import { GetHoldoutOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

import { GetExperimentQueryParams } from './getExperimentOverrides';

export const apiVersion = 'beta';

export interface GetHoldoutQueryParams extends Omit<GetHoldoutOpenAPIQueryParams, 'expand'> {
  expand?:
    | GetExperimentQueryParams['expand']
    | Array<
        'rel-previousIterations' | 'rel-draftIteration' | 'rel-treatments' | 'rel-secondaryMetrics' | 'rel-metrics'
      >;
}

export function querySerializer(queryParams: GetHoldoutQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.expand) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  return searchParams.toString();
}
