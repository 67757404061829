import { trackWithDetails } from './trackWithDetails';
import { AnalyticEventData } from './types';

const createTrackerForCategory = (category?: string) => {
  /**
   *
   * @param {String} event - The name of the event to track
   * @param {AnalyticEventData} [attrs] - {@link AnalyticEventData} to send with the event.
   * - Note: `url` and `path` are baked into this function - no need to supply these attributes.
   */
  const track = (
    event: string,
    { component, cta, metricValue, type, ...additionalAttributes }: AnalyticEventData = {},
  ) => {
    trackWithDetails({
      event,
      category,
      component,
      cta,
      metricValue,
      type,
      ...additionalAttributes,
    });
  };

  return track;
};

export { createTrackerForCategory };
