import { pluralize, wasOrWere } from '@gonfalon/strings';

import { BulkMemberActionNotification } from './types';

// We need a notification message when the bulk add members flow is complete.
// It's a little tricky to get the right message (based on the various inputs)
// so we have a helper utility to generate it.
const bulkAddMembersSuccessOrErrorMessage = ({
  errorsCount,
  memberCount,
  teamCount,
  warningsCount,
}: BulkMemberActionNotification & { teamCount: number }) => {
  const teamCountText = pluralize('team', teamCount, true);

  let successMessage;
  let errorsMessage;

  if (memberCount) {
    const memberCountText = pluralize('member', memberCount, true);
    const successText = `${memberCountText} ${wasOrWere(memberCount)} added to ${teamCountText}.`;

    if (!warningsCount) {
      // All succeed
      successMessage = successText;
    } else {
      // Some succeed, some fail because they already exist on that team
      const warningsCountText = pluralize('member', warningsCount, true);
      const warningsText = `${warningsCountText} ${wasOrWere(warningsCount)} already on ${teamCountText}.`;
      successMessage = `${successText} ${warningsText}`;
    }
  }

  if (errorsCount) {
    // Some fail outright
    const errorsCountText = pluralize('member', errorsCount, true);
    errorsMessage = `${errorsCountText} ${wasOrWere(errorsCount)} not added to ${teamCountText}.`;
  }

  return { successMessage, errorsMessage };
};

export { bulkAddMembersSuccessOrErrorMessage };
