import invariant from 'tiny-invariant';

import { MatchExplanation } from './builder';
import { safeJSONParse, safeJSONStringParse } from './json';
import { parse } from './parser';
import { Token, TokenType } from './token';

export type ContentAssistSuggestion = {
  tokenType: TokenType;
  context?: { matchname?: string; matchoperator?: string; matchvalue?: string };
};

/**
 * Compute a set of content-assist hints given an input that satisfies our queryfilter grammar[1].
 *
 * If a caretPosition is provided, we compute hints for that position in the input.
 *
 * If a caretPosition is not provided, we assume the caret is at the end of the input.
 *
 * [1]: https://launchdarkly.atlassian.net/wiki/spaces/ENG/pages/2161508659/Context+API+Filtering
 *
 * @param input input string
 * @param caretPosition optional position of the caret (if the input comes from an input field)
 * @returns a set of assistive hints to support user entry
 */
export function getContentAssistSuggestions(
  input: string,
  caretPosition?: number,
): ContentAssistSuggestion | undefined {
  const result = parse(input);

  // This should rarely happen. If it does, not much we can do.
  if (result.err) {
    return;
  }

  const position = caretPosition ?? input.length;

  let explanation: MatchExplanation | undefined;
  let token: Token | undefined;

  const stack = [result.val];
  let node = stack.pop();

  // We put the node on the stack, so pop() will return a valid node.
  invariant(node !== undefined);

  // If the first node is empty, we can stop now.
  if (node.type === 'empty') {
    return { tokenType: 'matchname' };
  }

  iterate: while (node !== undefined) {
    switch (node.type) {
      case 'empty':
        break;
      case 'match':
        // input was invalid: find explanation based on caret position
        if (node.explanation) {
          switch (node.explanation.type) {
            case 'standalone_token':
              if (isInTokenRange(position, node.explanation.failedToken)) {
                explanation = node.explanation;
                break iterate;
              }
            // eslint-disable-next-line no-fallthrough
            case 'expected_token':
              if (isInTokenRange(position, node.explanation.failedToken)) {
                explanation = node.explanation;
                break iterate;
              }
            // eslint-disable-next-line no-fallthrough
            case 'unknown_operator':
              if (isInTokenRange(position, node.explanation.failedToken)) {
                explanation = node.explanation;
                break iterate;
              }
          }
        }

        // input was valid: find token based on caret position
        if (node.tokens) {
          const { matchname, matchoperator, matchvalue } = node.tokens;

          if (isInTokenRange(position, matchname)) {
            token = matchname;
            break iterate;
          } else if (isInTokenRange(position, matchoperator)) {
            token = matchoperator;
            break iterate;
          } else if (isInTokenRange(position, matchvalue)) {
            token = matchvalue;
            break iterate;
          }
        }

        break;
      case 'and':
        stack.push(...node.children);
        break;
      case 'or':
        stack.push(...node.children);
        break;
    }

    node = stack.pop();
  }

  // We didn't find anything
  if (node === undefined || (token === undefined && explanation === undefined)) {
    return;
  }

  // Valid input
  if (token) {
    switch (token.type) {
      case 'matchname':
        return {
          tokenType: 'matchname',
          context: {
            matchname: node.matchname,
            matchoperator: node.matchoperator,
            matchvalue: JSON.stringify(node.matchvalue),
          },
        };
      case 'matchoperator':
        return {
          tokenType: 'matchoperator',
          context: {
            matchname: node.matchname,
            matchoperator: node.matchoperator,
            matchvalue: JSON.stringify(node.matchvalue),
          },
        };
      case 'matchvalue':
        return {
          tokenType: 'matchvalue',
          context: {
            matchname: node.matchname,
            matchoperator: node.matchoperator,
            matchvalue: JSON.stringify(node.matchvalue),
          },
        };
    }
  }

  // Part of the input was invalid
  if (explanation) {
    let suggestion: ContentAssistSuggestion;

    switch (explanation.type) {
      case 'standalone_token':
        suggestion = {
          tokenType: explanation.expectedTokenType,
        };

        return suggestion;
      case 'expected_token':
      case 'unknown_operator':
        suggestion = {
          tokenType: explanation.expectedTokenType,
        };

        if (explanation.previousTokens) {
          suggestion.context = {};

          for (const previousToken of explanation.previousTokens) {
            const parsed = safeJSONParse(previousToken.text);
            if (parsed.ok) {
              switch (previousToken.type) {
                case 'matchname':
                case 'matchoperator':
                  const val = safeJSONStringParse(previousToken.text);
                  if (val.ok) {
                    suggestion.context[previousToken.type] = val.val;
                  }

                  break;
              }
            }
          }
        }

        return suggestion;
      case 'invalid_data_type':
        break;
      case 'malformed_json':
        break;
    }
  }

  return;
}

function isInTokenRange(position: number, token: Token) {
  const startPosition = token.startPosition;
  const endPosition = token.endPosition;

  return startPosition <= position && position <= endPosition;
}
