import { Member } from './types';

export function createUnknownMember(): Member {
  return {
    _id: Math.random().toString(),
    _lastSeen: 0,
    _links: {},
    _pendingInvite: false,
    _verified: false,
    creationDate: 0,
    customRoles: [],
    email: '',
    mfa: '',
    role: 'reader',
    firstName: 'Unknown' as const,
    lastName: 'member' as const,
  };
}
