import { createTaskRunner } from '@gonfalon/async';
import { chunk } from '@gonfalon/collections';
import { internalPostProgressiveRolloutsStatus, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export interface ProgressiveRolloutStatusTask {
  projectKey: string;
  environmentKey: string;
  flagKeys: string[];
}

export const internalPostProgressiveRolloutsStatusTaskRunner = createTaskRunner<
  ProgressiveRolloutStatusTask,
  Array<schemas['ProgressiveRolloutStatusForFlag']>,
  NonNullable<Awaited<ReturnType<typeof internalPostProgressiveRolloutsStatus>>['data']>
>({
  runner: async (tasks) => {
    const results = await Promise.all(
      tasks.map(async (task) => {
        return reactQueryResponseAdapter(
          internalPostProgressiveRolloutsStatus({
            projectKey: task.projectKey,
            environmentKey: task.environmentKey,
            body: { flagKeys: task.flagKeys },
          }),
        );
      }),
    );

    return {
      items: results.flatMap((result) => result.items),
    };
  },
  resolver(data, task) {
    return data.items.filter((i) => i._environmentKey === task.environmentKey && task.flagKeys.includes(i._flagKey));
  },
  batching: {
    batcher: internalPostProgressiveRolloutsStatusTaskBatcher,
    resultMerger(data) {
      return {
        items: data.flatMap((c) => c.items),
      };
    },
  },
});

function internalPostProgressiveRolloutsStatusTaskBatcher(inputs: ProgressiveRolloutStatusTask[]) {
  const flagKeysByEnvironmentKey: { [environmentKey: string]: string[] } = {};

  for (const input of inputs) {
    flagKeysByEnvironmentKey[input.environmentKey] ??= [];
    flagKeysByEnvironmentKey[input.environmentKey].push(...input.flagKeys);
  }

  const batches: ProgressiveRolloutStatusTask[][] = [];

  for (const [environmentKey, flagKeys] of Object.entries(flagKeysByEnvironmentKey)) {
    const flagKeyChunks = chunk(Array.from(new Set(flagKeys)), 20);
    const batch: ProgressiveRolloutStatusTask[] = [];

    for (const flagKeyChunk of flagKeyChunks) {
      batch.push({ projectKey: inputs[0].projectKey, environmentKey, flagKeys: flagKeyChunk });
    }

    batches.push(batch);
  }

  return batches;
}
