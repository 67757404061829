import { patchProject } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { projectsDetail } from './internal/queries';

// TODO: move this somewhere else: it's a wrapper around patchProject that does not belong in this package.
export const useUpdateProjectFlagTemplate = createMutationHook(patchProject, {
  onSuccess: async (data, { projectKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: projectsDetail({ projectKey }).queryKey });
  },
});
