import { and, equals, QueryFilter, queryfilterToString } from '@gonfalon/queryfilter';

import { GetAllReleaseProgressionsForReleasePipelineOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export interface GetAllReleaseProgressionsForReleasePipelineQueryParams
  extends Omit<GetAllReleaseProgressionsForReleasePipelineOpenAPIQueryParams, 'filter'> {
  filter?:
    | {
        status?: 'active';
        activePhaseId?: string;
      }
    | { status: 'completed'; activePhaseId?: never };
}

export const apiVersion = 'beta';

export function querySerializer(params: GetAllReleaseProgressionsForReleasePipelineQueryParams) {
  const searchParams = new URLSearchParams();

  if ((params.limit ?? 0) > 0) {
    searchParams.set('limit', String(params.limit));
  }

  if ((params.offset ?? 0) > 0) {
    searchParams.set('offset', String(params.offset));
  }

  if (params.filter) {
    const serializedFilter = serializeReleaseProgressionFilterParam(params.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  return searchParams.toString();
}

function serializeReleaseProgressionFilterParam(
  value: NonNullable<GetAllReleaseProgressionsForReleasePipelineQueryParams['filter']>,
) {
  const filters: QueryFilter[] = [];

  if (value.status && ['active', 'completed'].includes(value.status)) {
    filters.push(equals('status', value.status));
  }

  if (value.activePhaseId) {
    filters.push(equals('activePhaseId', value.activePhaseId));
  }

  return queryfilterToString(and(filters));
}
