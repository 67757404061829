// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { throwErrorsInRouterHooks } from '@gonfalon/dogfood-flags';
import { trackError } from '@gonfalon/telemetry';

/**
 * Type-safe function for reading the `:projKey` or `:projectKey` URL parameter.
 * Will throw error if `:projKey` or `:projectKey` is not present in the URL.
 */
// @ts-expect-error Technically, this function can return `undefined`. Once we remove the flagged code, it won't be able to.
export function useProjectKey(): string {
  const shouldThrowError = throwErrorsInRouterHooks();
  const { projKey, projectKey } = useParams();

  if (projKey) {
    return projKey;
  }

  if (projectKey) {
    return projectKey;
  }

  const error = new Error('Parameter "projKey" or "projectKey" not present in URL');

  if (shouldThrowError) {
    throw error;
  } else {
    trackError(error);
  }
}
