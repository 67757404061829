import { createReleaseForFlag } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { flags, releasePipelines } from './internal/queries';

export const useUpdateRelease = createMutationHook(createReleaseForFlag, {
  onSuccess: async (data, { projectKey, flagKey, body: { releasePipelineKey } }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flags.list({ projectKey }).queryKey });
    // invalidate the flag query so that the flag will have the correct "releasePipelineKey" property
    await queryClient.invalidateQueries({ queryKey: flags.detail({ projectKey, flagKey }).queryKey });
    await queryClient.invalidateQueries({ queryKey: flags.detail({ projectKey, flagKey })._ctx.release.queryKey });
    await queryClient.invalidateQueries({
      queryKey: releasePipelines.detail({ projectKey, releasePipelineKey }).queryKey,
    });
  },
});
