import styles from './DividerWithText.module.css';

export function DividerWithText({ text }: { text: string }) {
  return (
    <div className={styles.separatorContainer}>
      <hr />
      <h3> {text} </h3>
      <hr />
    </div>
  );
}
