import { Map, OrderedMap } from 'immutable';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function intersperse<T>(array: T[], separator: any, lastSeparator?: any) {
  if (array.length === 0) {
    return [];
  }

  return array.slice(1).reduce(
    (xs, x, i) => {
      const isLast = array[array.length - 2] === array[i];
      return xs.concat([lastSeparator && isLast ? lastSeparator : separator, x]);
    },
    [array[0]],
  );
}

/**
 * Convert an immutable map into an ordered map given a key sequence.
 *
 * And immutable.OrderedMap guarantees that the iteration order of entries
 * will be the order in which they were set.
 *
 * @param  {immutable.Map} map    map to convert
 * @param  {Array} keySequence    sequence of keys used to set order
 * @return {immutable.OrderedMap} ordered map
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertMapToOrderedMap<K = string, V = any>(map: Map<K, V>, keySequence: K[]): OrderedMap<K, V> {
  return keySequence.reduce(
    (ordered, key) =>
      ordered.set(
        key,
        // Cast as V since it cannot be undefined
        map.get(key) as V,
      ),
    OrderedMap<K, V>(),
  );
}

/**
 *
 * Object.keys in TypeScript returns `string[]`. (https://github.com/microsoft/TypeScript/blob/master/src/lib/es5.d.ts#L241)
 *
 * objectKeys is useful when you are confident that the keys of object you're working with
 * are well defined (and won't vary at runtime).
 *
 * @param {T} t the object whose keys you want
 * @return {keyof T[]} the array of property names on t
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectKeys<T extends Record<string, any>>(t: T) {
  return Object.keys(t) as Array<keyof T>;
}

export function nilFilter<T>(v: T | undefined): v is T {
  return v !== undefined;
}
