import { deleteDomainVerification } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { ssoDomains } from './internal/queries';

export const useDeleteSSODomain = createMutationHook(deleteDomainVerification, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: ssoDomains._def });
  },
});
