import { GetCustomRolesOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

// unpaginated
export const apiVersion = '20220603';

export type GetCustomRolesQueryParams = GetCustomRolesOpenAPIQueryParams & {
  filter?: {
    query?: string;
  };
};

export function querySerializer(queryParams: GetCustomRolesQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  // only available on API version '20240415' and above
  if (queryParams.filter) {
    searchParams.set('filter', serializeCustomRolesFilterParam(queryParams.filter));
  }

  return searchParams.toString();
}

export function serializeCustomRolesFilterParam(value: NonNullable<GetCustomRolesQueryParams['filter']>) {
  const filter: string[] = [];

  if (value.query) {
    filter.push(`query:${value.query}`);
  }

  filter.sort();

  return filter.join(',');
}
