import { createSuspenseQueryHook } from './internal/createSuspenseQueryHook';
import { workflows } from './internal/queries';

export const useSuspenseWorkflow = createSuspenseQueryHook(
  ({
    projectKey,
    featureFlagKey,
    environmentKey,
    workflowId,
  }: {
    projectKey: string;
    featureFlagKey: string;
    environmentKey: string;
    workflowId: string;
  }) => workflows.detail({ projectKey, featureFlagKey, environmentKey, workflowId }),
);
