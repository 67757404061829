import { and, anyOf, equals, exists, QueryFilter, queryfilterToString } from '@gonfalon/queryfilter';

import { GetSegmentsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetSegmentsQueryParams = Omit<GetSegmentsOpenAPIQueryParams, 'filter' | 'sort' | 'expand'> & {
  sort?: 'name' | '-name' | 'createdOn' | '-createdOn' | 'lastModified' | '-lastModified';
  expand?: 'flags';
  filter?: {
    query?: string;
    tags?: string[];
    kind?: 'standard' | 'list' | 'synced';
    keys?: string[];
    includedKeys?: string[];
    excludedKeys?: string[];
  };
};

export function querySerializer(queryParams: GetSegmentsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.filter) {
    const serializedFilter = serializeSegmentFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  if (queryParams.expand) {
    searchParams.set('expand', queryParams.expand);
  }

  if (queryParams.sort) {
    searchParams.set('sort', queryParams.sort);
  }

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  return searchParams.toString();
}

function serializeSegmentFilterParam(value: NonNullable<GetSegmentsQueryParams['filter']>) {
  const filter: QueryFilter[] = [];

  if (value.query) {
    filter.push(equals('query', value.query));
  }

  if (value.tags?.length) {
    filter.push(anyOf('tags', value.tags));
  }

  if (value.keys?.length) {
    filter.push(anyOf('keys', value.keys));
  }

  if (value.kind) {
    switch (value.kind) {
      case 'standard':
        filter.push(equals('unbounded', false));
        filter.push(exists('external', false));
        break;
      case 'list':
        filter.push(equals('unbounded', true));
        filter.push(exists('external', false));
        break;
      case 'synced':
        filter.push(exists('external', true));
        break;
      default:
    }
  }

  if (value.excludedKeys?.length) {
    filter.push(anyOf('excludedKeys', value.excludedKeys));
  }
  return queryfilterToString(and(filter));
}
