import { enableMostRecentAuditLogHistory } from '@gonfalon/dogfood-flags';
import { DateFormat } from '@gonfalon/format';
import {
  differenceInHours,
  differenceInMonths,
  endOfDay,
  format,
  getMonth,
  getYear,
  isSameDay,
  isValid,
  startOfDay,
  startOfToday,
  startOfYesterday,
  subDays,
  subHours,
  subMonths,
  subYears,
} from 'date-fns';
import { format as formatTz, toZonedTime } from 'date-fns-tz';

import { load, save } from 'sources/AccountLocalStorage';

export type dateType = Date | number;
export type dateShortcutType = {
  [s: string]: {
    label: string;
    dateRange: Date[];
  };
};
export function getDateNow() {
  return Date.now();
}

export function getLocalTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles'; // eslint-disable-line new-cap
}

export const saveTzidToCache = (tzid: string) => save(load().set('tzid', tzid));
export const getTzidFromCache = () => load().get('tzid');

export function lastUsedTzid() {
  return getTzidFromCache() || getLocalTimeZone();
}

export function getToday() {
  return toZonedTime(new Date(), lastUsedTzid());
}

export function convertToTZDate(v: dateType | string | undefined) {
  const tzid = lastUsedTzid();
  if (v) {
    if (!isValid(new Date(v))) {
      return null;
    }
    return toZonedTime(v, tzid);
  }
  return null;
}

export function toFormattedString(date: dateType | undefined, tzid = lastUsedTzid(), dateFormat?: DateFormat) {
  if (date) {
    const converted = toZonedTime(date, tzid);
    return formatTz(converted, dateFormat ?? DateFormat.DEFAULT, { timeZone: tzid });
  }
  return null;
}

export const getMonthDay = (date: dateType) => {
  if (isValid(date)) {
    const month = date && getMonth(date);
    const year = date && getYear(date);
    return year !== undefined && month !== undefined ? new Date(year, month, 1) : undefined;
  }
  return undefined;
};

export const getNextMonth = (prevMonth?: undefined | Date, nextMonth?: undefined | Date) => {
  let month;
  if (!prevMonth && !nextMonth) {
    month = undefined;
  } else if (!prevMonth || (nextMonth && !isSameDay(prevMonth, nextMonth))) {
    month = nextMonth;
  } else {
    month = prevMonth;
  }
  return month;
};

const createShortcut = (label: string, dateRange: Date[]) => ({
  label,
  dateRange: [dateRange[0], dateRange[1]],
});

export const today = new Date();

const modify = (fn: (val: dateType) => Date) => {
  const val = new Date(today);
  return fn(val);
};

const pastHour = modify((d: dateType) => subHours(d, 1));
const past24Hours = modify((d: dateType) => subHours(d, 24));
const yesterday = modify(() => startOfYesterday());
const oneWeekAgo = modify((d: dateType) => subDays(d, 7));
const twoWeeksAgo = modify((d: dateType) => subDays(d, 14));
const oneMonthAgo = modify((d: dateType) => subMonths(d, 1));
const twoMonthsAgo = modify((d: dateType) => subMonths(d, 2));
const threeMonthsAgo = modify((d: dateType) => subMonths(d, 3));
const sixMonthsAgo = modify((d: dateType) => subMonths(d, 6));
const oneYearAgo = modify((d: dateType) => subYears(d, 1));

enum DateRange {
  HOUR = 'hour',
  DAY = 'day',
}

export const createOverviewDates = (range: string) => {
  if (range === DateRange.HOUR) {
    return [pastHour.valueOf(), today.valueOf()];
  } else if (range === DateRange.DAY) {
    return [past24Hours.valueOf(), new Date().valueOf()];
  } else {
    return [oneMonthAgo.valueOf(), endOfDay(new Date()).valueOf()];
  }
};

export const aWeekAgoTimeRange = [startOfDay(oneWeekAgo), endOfDay(today)];

export const createDefaultShortcuts = () => {
  const mostRecentShortcut = { label: 'Most recent', dateRange: [undefined, undefined] };

  const shortcuts = [
    ...(enableMostRecentAuditLogHistory() ? [mostRecentShortcut] : []),
    createShortcut('Today', [startOfToday(), endOfDay(today)]),
    createShortcut('Yesterday', [startOfDay(yesterday), endOfDay(yesterday)]),
    createShortcut('Last week', [startOfDay(oneWeekAgo), endOfDay(today)]),
    createShortcut('Last month', [startOfDay(oneMonthAgo), endOfDay(today)]),
    createShortcut('Last 3 months', [startOfDay(threeMonthsAgo), endOfDay(today)]),
    createShortcut('Last 6 months', [startOfDay(sixMonthsAgo), endOfDay(today)]),
    createShortcut('Last 12 months', [startOfDay(oneYearAgo), endOfDay(today)]),
  ];
  return shortcuts;
};

export const createUsageShortcuts = () => [
  { last60Min: createShortcut('Last 60 minutes', [pastHour, today]) },
  { last24Hrs: createShortcut('Last 24 hours', [past24Hours, today]) },
  { last7Days: createShortcut('Last 7 days', [startOfDay(oneWeekAgo), endOfDay(today)]) },
  { last14Days: createShortcut('Last 14 days', [startOfDay(twoWeeksAgo), endOfDay(today)]) },
  { last30Days: createShortcut('Last 30 days', [startOfDay(oneMonthAgo), endOfDay(today)]) },
  { last60Days: createShortcut('Last 60 days', [startOfDay(twoMonthsAgo), endOfDay(today)]) },
];

export const createMAUShortcuts = () => [
  { last7Days: createShortcut('Last 7 days', [startOfDay(oneWeekAgo), endOfDay(today)]) },
  { last14Days: createShortcut('Last 14 days', [startOfDay(twoWeeksAgo), endOfDay(today)]) },
  { last30Days: createShortcut('Last 30 days', [startOfDay(oneMonthAgo), endOfDay(today)]) },
  { last60Days: createShortcut('Last 60 days', [startOfDay(twoMonthsAgo), endOfDay(today)]) },
];

export const createClientMAUShortcuts = () => [
  { last7Days: createShortcut('Last 7 days', [startOfDay(oneWeekAgo), endOfDay(today)]) },
  { last14Days: createShortcut('Last 14 days', [startOfDay(twoWeeksAgo), endOfDay(today)]) },
  { last30Days: createShortcut('Last 30 days', [startOfDay(oneMonthAgo), endOfDay(today)]) },
  { last60Days: createShortcut('Last 60 days', [startOfDay(twoMonthsAgo), endOfDay(today)]) },
  { lastSixMonths: createShortcut('Last 6 months', [startOfDay(sixMonthsAgo), endOfDay(today)]) },
  { lastYear: createShortcut('Last 12 months', [startOfDay(oneYearAgo), endOfDay(today)]) },
];

export const createExperimentResultsShortcuts = (experimentDefaultEvents: Array<{ label: string; date: Date }>) => [
  ...experimentDefaultEvents.map((e) => createShortcut(`Since ${e.label}`, [e.date, today])),
  createShortcut('Last 7 days', [oneWeekAgo, today]),
];

export function convertValueToTimeRange(dates: dateType[] | string[], createShortcuts?: () => dateShortcutType[]) {
  let [startDate, endDate] = dates;
  if (typeof startDate === 'string' && typeof endDate === 'string') {
    startDate = parseInt(dates[0] as string, 10);
    endDate = parseInt(dates[1] as string, 10);
  }

  const hourDiff = differenceInHours(endDate as dateType, startDate as dateType);
  const monthDiff = differenceInMonths(endDate as dateType, startDate as dateType);
  let dateFormat = 'MMM d';

  if (hourDiff > 0 && hourDiff < 24) {
    dateFormat = DateFormat.H_MM_A;
  } else if (monthDiff > 11) {
    dateFormat = DateFormat.MMM_D_YYY;
  }

  const shortcutRange = createShortcuts ? getMatchingShortcutDate(dates as string[], createShortcuts) : undefined;
  if (shortcutRange) {
    return shortcutRange.label;
  } else {
    return `${format(startDate as dateType, dateFormat)} - ${format(endDate as dateType, dateFormat)}`;
  }
}

export function getMatchingShortcutDate(dates: string[], createShortcuts: () => dateShortcutType[]) {
  const [startDate, endDate] = dates.map((date) => new Date(parseInt(date, 10)));
  return createShortcuts()
    .map((shortcut) => shortcut[Object.keys(shortcut)[0]])
    .find(
      (shortcut) =>
        shortcut.dateRange[0].getTime() === startDate.getTime() &&
        shortcut.dateRange[1].getTime() === endDate.getTime(),
    );
}

export function convertTimeRangeToValues(dates: Date[] | number[]) {
  const [startDate, endDate] = dates;
  return [startDate.valueOf(), endDate.valueOf()];
}

export function renderTimeRangeLabel(
  defaultDate: string,
  createShortcuts: () => Array<{ [s: string]: { label: string; date: number } }>,
) {
  return createShortcuts().filter((sc) => sc[defaultDate])[0][defaultDate].label;
}
