import { patchFollowPreferences } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { followPreferences } from './internal/queries';

export const useUpdateFollowPreferences = createMutationHook(patchFollowPreferences, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: followPreferences.detail.queryKey });
  },
});
