import { patchProfileBeautyMode } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { members } from './internal/queries';

export const useUpdateMemberQuickstartStatus = createMutationHook(patchProfileBeautyMode, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: members.profile.queryKey });
  },
});
