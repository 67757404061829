import { getQueryClient } from '@gonfalon/react-query-client';
import { FetchQueryOptions, QueryKey as RQQueryKey } from '@tanstack/react-query';

type SafeQueryOptions<QueryFnData, Error, Data, QueryKey extends RQQueryKey> = Omit<
  FetchQueryOptions<QueryFnData, Error, Data, QueryKey>,
  'queryKey' | 'queryFn' | 'queryKeyHashFn'
>;

export function createQueryPrefetcher<
  Dependencies,
  QueryFnData = unknown,
  Error = unknown,
  Data = QueryFnData,
  QueryKey extends RQQueryKey = RQQueryKey,
>(queryFactory: (deps: Dependencies) => FetchQueryOptions<QueryFnData, Error, Data, QueryKey>) {
  return async (deps: Dependencies, options?: SafeQueryOptions<QueryFnData, Error, Data, QueryKey>) => {
    return getQueryClient().prefetchQuery({ ...options, ...queryFactory(deps) });
  };
}
