// eslint-disable-next-line no-restricted-imports
import { fromJS, Map } from 'immutable';
import qs from 'qs';
import { AnyAction } from 'redux';
import { createSelector } from 'reselect';

// eslint-disable-next-line import/no-namespace
import * as actions from 'actions/router';
import { GlobalState } from 'reducers';
import registry from 'reducers/registry';
import { ImmutableMap } from 'utils/immutableUtils';

type RouterActions = ReturnType<typeof actions.updateLocation>;

export type SearchParams<Params = {}> = { [K in keyof Params]?: string };
export type QueryParams<Params extends string = string> = ImmutableMap<{ [K in Params]?: string | string[] }>;

type RouterState = ImmutableMap<
  Location & {
    query: QueryParams;
  }
>;

export function router(state: RouterState = Map(), action: AnyAction) {
  const routerAction = action as RouterActions;
  switch (routerAction.type) {
    case 'router/UPDATE_LOCATION':
      const { location } = routerAction;
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      return fromJS({ ...location, query });
    default:
      return state;
  }
}

export function querySelector<T extends string>(state: GlobalState): QueryParams<T> {
  return state.router.get('query', Map() as QueryParams<T>);
}

const queryStringSelector = (state: GlobalState) => state.router.get('search', '');

export const searchSelector = createSelector(queryStringSelector, (queryString) =>
  qs.parse(queryString, { ignoreQueryPrefix: true }),
);

export const pathnameSelector = (state: GlobalState) => state.router.get('pathname', '');

registry.addReducers({ router });
