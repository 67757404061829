import { Path } from 'react-router-dom';
import {
  type ProjectContextSearchParams,
  projectContextSearchParamsUpdater,
  readProjectContextFromPath,
} from '@gonfalon/context';
import { halfwayIA } from '@gonfalon/dogfood-flags';

export function withProjectContext(overrides: Partial<ProjectContextSearchParams> = {}) {
  return (path: Path) => {
    if (!halfwayIA()) {
      return path;
    }

    const context = readProjectContextFromPath(window.location);

    if (!context) {
      return path;
    }

    const searchParams = new URLSearchParams(path.search);

    const nextContext = { ...context };
    if (overrides.environmentKeys !== undefined && overrides.environmentKeys.size > 0) {
      const nextEnvironmentKeys = new Set(context.environmentKeys);
      for (const key of overrides.environmentKeys) {
        if (key !== undefined && key !== '') {
          nextEnvironmentKeys.add(key);
        }
      }
      nextContext.environmentKeys = nextEnvironmentKeys;
    }

    if (overrides.selectedEnvironmentKey !== undefined && overrides.selectedEnvironmentKey !== '') {
      nextContext.selectedEnvironmentKey = overrides.selectedEnvironmentKey;

      if (!context.environmentKeys.has(overrides.selectedEnvironmentKey)) {
        nextContext.environmentKeys.add(overrides.selectedEnvironmentKey);
      }
    }

    const nextSearchParams = projectContextSearchParamsUpdater(searchParams, nextContext);

    return {
      ...path,
      search: nextSearchParams.toString(),
    };
  };
}
