import { Access } from './internal/types';

export function hasDeniedActions(access: Access | undefined) {
  if (access === undefined) {
    return false;
  }

  if (access.denied === undefined || access.denied.length === 0) {
    return false;
  }

  return true;
}
