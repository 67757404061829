import { type Access, checkAccess } from '@gonfalon/permissions';

import { Member } from './types';

// Ideally this would come from OpenAPI, but
// our docs aren't structured that way yet.
// internal/roles/action.go -> Member actions
type Action =
  | 'createMember'
  | 'updateRole'
  | 'updateCustomRole'
  | 'deleteMember'
  | 'sendMfaRequest'
  | 'sendMfaRecoveryCode'
  | 'autoProvisionDomainMatchedMember'
  | 'approveDomainMatchedMember'
  | 'updatePendingRequest';

export function checkMemberAccess(member: Member, action: Action, memberAccess?: Access) {
  return checkAccess(member, memberAccess, action);
}
