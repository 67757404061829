import { ChangeHistorySearchParams } from './parseChangehistorySearchParams';

export function serializeChangehistorySearchParams(params: ChangeHistorySearchParams) {
  const searchParams = new URLSearchParams();

  if (params.activity) {
    searchParams.set('activity', 'true');
  }

  if (params.auditId) {
    searchParams.set('activity-auditId', params.auditId);
  }

  return searchParams;
}
