import { type Member, hasStrictWriterRights, isReader } from '@gonfalon/members';

import type { Access, AccessCheck } from './internal/types';
import { hasDeniedActions } from './hasDeniedActions';

const defaultDenyCheck = isReader;
const defaultAllowCheck = hasStrictWriterRights;

type MemberPermissionCheck = (member: Member) => boolean;

export function checkAccess(
  member: Member,
  access: Access | undefined,
  action: string,
  options?: { builtInAllowCheck?: MemberPermissionCheck; builtInDenyCheck?: MemberPermissionCheck },
): AccessCheck {
  const builtInDenyCheck = options?.builtInDenyCheck ?? defaultDenyCheck;
  const builtInAllowCheck = options?.builtInAllowCheck ?? defaultAllowCheck;
  if (builtInDenyCheck(member)) {
    return { isAllowed: false, appliedRoleName: 'reader' };
  }

  if (builtInAllowCheck(member) || !hasDeniedActions(access)) {
    return { isAllowed: true };
  }

  const denied = access?.denied ?? [];
  const deniedAction = denied.find((v) => v.action === action);

  if (deniedAction === undefined) {
    return { isAllowed: true };
  }

  const reason = deniedAction.reason;
  const roleName = reason?.role_name;

  return {
    isAllowed: false,
    appliedRoleName: roleName,
    reason,
  };
}
