import { hasAdminRights, Member } from '@gonfalon/members';
import { type Access, checkAccess } from '@gonfalon/permissions';

// Ideally this would come from OpenAPI, but
// our docs aren't structured that way yet.
// internal/roles/action.go -> Account actions
export type Action =
  | 'updateOrganization'
  | 'updateSubscription'
  | 'updateBillingContact'
  | 'deleteSubscription'
  | 'getPaymentCard'
  | 'updatePaymentCard'
  | 'updateRequireMfa'
  | 'updateEnableAccountImpersonation'
  | 'updateAccountToken'
  | 'deleteAccountToken'
  | 'createSamlConfig'
  | 'deleteSamlConfig'
  | 'updateSamlEnabled'
  | 'deleteContextInstance'
  | 'updateSamlX509Certificate'
  | 'updateSamlSsoUrl'
  | 'updateSamlLogoutUrl'
  | 'updateSamlRequireSso'
  | 'updateSamlDefaultRole'
  | 'createSamlEncryptionCertificate' // TODO: remove
  | 'disableIdPManagingTeams'
  | 'enableIdPManagingTeams'
  | 'updateSamlDecryptionEnabled'
  | 'updateSamlX509KeystoreId'
  | 'createScimConfig'
  | 'deleteScimConfig'
  | 'updateSessionRefresh'
  | 'updateSessionDuration'
  | 'revokeSessions'
  | 'deleteAccount'
  | 'updateIsDomainMatchingEnabled'
  | 'updateIsAutoSeatProvisioningEnabled'
  | 'updateIsSupportGenAiEnabled';

export function checkAccountAccess(member: Member, action: Action, accountAccess?: Access) {
  return checkAccess(member, accountAccess, action, {
    builtInAllowCheck: hasAdminRights,
  });
}
