import { useState } from 'react';
import { JSONPatch } from '@gonfalon/rest-api';
import {
  Alert,
  Button,
  Dialog,
  DialogTrigger,
  Heading,
  IconButton,
  Modal,
  ModalOverlay,
} from '@launchpad-ui/components';
import { Box } from '@launchpad-ui/core';

import { CustomRole, Member, Team } from '../internal/types';
import { RoleSelect } from '../RoleSelect';

import { AssignAccessForm } from './AssignAccessForm';

import styles from './AssignAccessModal.module.css';

export type AssignAccessModalProps = {
  forResource: 'member' | 'team';
  member?: Member;
  team?: Team;
  createJsonPatch: <T>(
    prev: T,
    next: T,
  ) =>
    | JSONPatch
    | {
        comment: string;
        patch: JSONPatch;
      };
};

export const AssignAccessModal = ({ forResource, member, team, createJsonPatch }: AssignAccessModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isForMember = forResource === 'member';
  const [selectedRole, setSelectedRole] = useState<CustomRole | undefined>(undefined);

  const handleOpenChange = (nextIsOpen: boolean) => {
    if (nextIsOpen) {
      setIsOpen(true);
      return;
    }
    setSelectedRole(undefined);
    setIsOpen(false);
  };

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
      <Button variant="primary">Assign access</Button>
      <ModalOverlay>
        <Modal>
          <Dialog>
            <>
              <div slot="header">
                <Heading slot="title">Assign access</Heading>
                <IconButton
                  aria-label="close modal"
                  icon="cancel"
                  variant="minimal"
                  onPress={() => handleOpenChange(false)}
                />
              </div>
              <Box slot="body" display="grid" gap="$500">
                <p>
                  Select a project role and corresponding resource(s) where appropriate, that the selected role will
                  provide access to:
                </p>

                {isForMember && (
                  <Alert status="info" isDismissable>
                    To give more than 1 member access to the same set of resources, we recommend assigning access from
                    the Team page.
                  </Alert>
                )}

                <RoleSelect
                  onSelectionChange={(r) => {
                    setSelectedRole(r);
                  }}
                />

                {selectedRole && (
                  <>
                    <p className={styles.formDescription}>
                      Select the specific resource(s) that will apply in place of the defined role attributes:
                    </p>
                    <AssignAccessForm
                      role={selectedRole}
                      forResource={forResource}
                      member={member}
                      team={team}
                      createJsonPatch={createJsonPatch}
                      closeModal={() => handleOpenChange(false)}
                    />
                  </>
                )}
              </Box>
            </>
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
};
