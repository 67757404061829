import { createQueryHook } from './internal/createQueryHook';
import { metricAnalytics } from './internal/queries';

type Props = {
  contextKindKey?: string;
  dimensionKey: string;
  environmentKey: string;
  limit?: number;
  metricKey: string;
  projectKey: string;
  startsWith?: string;
};

export const useProductAnalyticsMetricDimensionValues = createQueryHook(
  ({ contextKindKey, dimensionKey, environmentKey, limit, metricKey, projectKey, startsWith }: Props) =>
    metricAnalytics.metricDimensionValues({
      contextKindKey,
      dimensionKey,
      environmentKey,
      limit,
      metricKey,
      projectKey,
      startsWith,
    }),
);
