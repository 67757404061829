import { useContext } from 'react';
import invariant from 'tiny-invariant';

import { ThemeContext } from './ThemeContext';

export const useThemeValue = () => {
  const { theme } = useContext(ThemeContext);
  invariant(theme, 'useThemeValue must be used within a ThemeProvider');
  return theme;
};
