import { ApprovalResourceKind } from 'utils/environmentUtils';

export const FIELD_PREFIX_DELIMITER = '_';
export const getFieldPrefix = (resourceKind: ApprovalResourceKind) => `${resourceKind}${FIELD_PREFIX_DELIMITER}`;
export enum FieldIds {
  REQUIRE_APPROVALS_CHECKBOX = 'requireApprovals',
  AUTO_APPLY_APPROVALS_CHECKBOX = 'autoApplyApprovals',
  REQUIRE_APPROVALS_CHECKBOX_LEGACY = 'required',
  APPROVAL_REQUIREMENT_STRATEGY_RADIO_GROUP = 'approvalRequirementStrategy',
  APPROVAL_REQUIREMENT_STRATEGY_RADIO_ALL = 'approvalRequirementStrategyAll',
  APPROVAL_REQUIREMENT_STRATEGY_RADIO_BY_TAG = 'approvalRequirementStrategyByTag',
  REQUIRED_APPROVAL_TAGS_SELECT = 'requiredApprovalTags',
  ALLOW_DELETING_SCHEDULED_CHANGES_CHECKBOX = 'bypassApprovalsForPendingChanges',
  CAN_REVIEW_OWN_REQUEST_CHECKBOX = 'canReviewOwnRequest',
  MIN_NUM_APPROVALS_INPUT = 'minNumApprovals',
  CAN_APPLY_DECLINED_CHANGES_CHECKBOX = 'canApplyDeclinedChanges',
}
