import { createTrackerForCategory } from '@gonfalon/analytics';

import { ApplicationRep, ApplicationVersionRep } from './types';

const trackApplicationsEvent = createTrackerForCategory('Applications');

// Applications

const trackNewApplicationCreated = ({ name, key, autoAdded, kind }: ApplicationRep) =>
  trackApplicationsEvent('New Application Created', { name, key, autoAdded, kind });

const trackApplicationUpdated = ({ key, kind, name }: Partial<ApplicationRep>) =>
  trackApplicationsEvent('Application Updated', { key, kind, name });

const trackApplicationDeleted = ({ name, key }: Partial<ApplicationRep>) =>
  trackApplicationsEvent('Application Deleted', { name, key });

type ApplicationVersionEventProps = Partial<ApplicationVersionRep> & { applicationKey: string };

// Application Versions

const trackNewApplicationVersionCreated = ({ key, name, supported, applicationKey }: ApplicationVersionEventProps) =>
  trackApplicationsEvent('New Application Version Created', { key, name, supported, applicationKey });

const trackApplicationVersionUpdated = ({ key, name, supported, applicationKey }: ApplicationVersionEventProps) =>
  trackApplicationsEvent('Application Version Updated', { key, name, supported, applicationKey });

const trackApplicationVersionDeleted = ({ key, name, supported, applicationKey }: ApplicationVersionEventProps) =>
  trackApplicationsEvent('Application Version Deleted', { key, name, supported, applicationKey });

export {
  trackApplicationDeleted,
  trackApplicationUpdated,
  trackApplicationVersionDeleted,
  trackApplicationVersionUpdated,
  trackNewApplicationCreated,
  trackNewApplicationVersionCreated,
};
