import { GetProjectOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetProjectQueryParams = Omit<GetProjectOpenAPIQueryParams, 'expand'> & { expand?: Array<'environments'> };

export const apiVersion = '20220603';

export function querySerializer(queryParams: GetProjectQueryParams) {
  const searchParams = new URLSearchParams();
  if (queryParams.expand && queryParams.expand.length > 0) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  return searchParams.toString();
}
