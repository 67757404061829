import { ProjectContextSearchParams } from './types';

export function serializeProjectContextSearchParams(params: ProjectContextSearchParams) {
  const searchParams = new URLSearchParams();

  for (const environmentKey of params.environmentKeys) {
    if (environmentKey.length > 0) {
      searchParams.append('env', environmentKey);
    }
  }

  if (params.selectedEnvironmentKey.length > 0) {
    searchParams.set('selected-env', params.selectedEnvironmentKey);
  }

  return searchParams;
}
