import { putReleasePipeline } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { releasePipelines } from './internal/queries';

export const usePutUpdateReleasePipeline = createMutationHook(putReleasePipeline, {
  onSuccess: async (data, { projectKey, pipelineKey: releasePipelineKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: releasePipelines.detail({ projectKey, releasePipelineKey }).queryKey,
    });
  },
});
