import {
  // This is the only place where we should be using matchPath
  // eslint-disable-next-line no-restricted-imports
  matchPath,
  Path,
  resolvePath,
} from 'react-router-dom';
import type { AppRoute } from '@gonfalon/router';

export function matchAppRoute(pattern: AppRoute, path: Path) {
  return matchPath(pattern, resolvePath(path).pathname);
}
