import { GetEnvironmentsByProjectOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export interface GetEnvironmentsByProjectQueryParams
  extends Omit<GetEnvironmentsByProjectOpenAPIQueryParams, 'filter' | 'sort'> {
  filter?: {
    query?: string;
    tags?: string[];
  };
  sort?: 'name' | '-name' | 'createdOn' | '-createdOn';
  // Note: remove this when we retire the environments list, this exists for backwards compatibility
  textFilter?: string;
}

export function querySerializer(queryParams: GetEnvironmentsByProjectQueryParams) {
  const searchParams = new URLSearchParams();

  if ((queryParams.limit ?? 0) > 0) {
    searchParams.set('limit', String(queryParams.limit));
  }

  if ((queryParams.offset ?? 0) > 0) {
    searchParams.set('offset', String(queryParams.offset));
  }

  if (queryParams.sort) {
    searchParams.set('sort', queryParams.sort);
  }

  if (queryParams.filter) {
    const serializedFilter = serializeEnvironmentListFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  return searchParams.toString();
}

function serializeEnvironmentListFilterParam(value: NonNullable<GetEnvironmentsByProjectQueryParams['filter']>) {
  const filter: string[] = [];

  if (value.query) {
    filter.push(`query:${encodeURIComponent(value.query)}`);
  }

  if (value.tags) {
    filter.push(`tags:${value.tags.map(encodeURIComponent).join('+')}`);
  }

  filter.sort();

  return filter.join(',');
}
