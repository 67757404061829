import type { Prettify } from '@gonfalon/types';
import { queryOptions } from '@tanstack/react-query';
import type { FetchResponse } from 'openapi-fetch';

import { makeQueryId } from './makeQueryId';
import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

type OpenAPIResponse<T, O, M extends `${string}/${string}`> = NonNullable<FetchResponse<T, O, M>['data']>;

type QueryKey<Input> = readonly [{ type: string } & Input];

/**
 * Create a set of query options to be shared for all instances of a given query function.
 *
 * The query will be cached in a way to correctly encodes all dependencies to that function.
 * Importantly, the query key and query function will be intertwined correctly for you, and
 * your query will therefore be cached correctly.
 *
 * You may specify additional options in the package.
 *
 * You may also safely compose these options with additional options in consumer code, in
 * case you need to tweak certain aspects of the query for your specific use case.
 */
export function createQueryOptions<T, O, M extends `${string}/${string}`, Input, Data = OpenAPIResponse<T, O, M>>(
  fetcher: (input: Input, context: { signal: AbortSignal }) => Promise<FetchResponse<T, O, M>>,
  options: Omit<
    ReturnType<typeof queryOptions<OpenAPIResponse<T, O, M>, unknown, Data, QueryKey<Input>>>,
    'queryKey' | 'queryFn'
  > = {},
) {
  const type = makeQueryId(fetcher.name);

  function factory(args: Input) {
    return queryOptions({
      queryKey: [{ type, ...args }] as const,
      queryFn: async (context) => reactQueryResponseAdapter(fetcher(args, context)),
      ...options,
    });
  }

  function partialQueryKey(): readonly [{ type: string }];
  function partialQueryKey<const PartialArgs extends Partial<Input>>(
    partialArgs: PartialArgs,
  ): readonly [Prettify<{ type: string } & PartialArgs>];
  function partialQueryKey(
    /* We're OK with any here since the implementation signature is internal. */
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    partialArgs?: any,
  ) {
    return [
      {
        ...partialArgs,
        type,
      },
    ] as const;
  }

  factory.partialQueryKey = partialQueryKey;

  return factory;
}
