import {
  deleteAIConfig,
  deleteAIConfigVersion,
  getAIConfig,
  getAIConfigs,
  patchAIConfig,
  patchAIConfigVersion,
  postAIConfig,
  postAIConfigVersion,
} from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { createQueryOptions } from './internal/createQueryOptions';

// fetch a list of AI Configs for dashboard
export const getAiConfigsQuery = createQueryOptions(getAIConfigs);
// fetch all versions of a single AI Config for detail page. Render models and prompts
export const getAiConfigQuery = createQueryOptions(getAIConfig);

// create an AI Config
export const useCreateAiConfig = createMutationHook(postAIConfig);
// create a new version (modal & prompt) in an AI Config.
export const usePostVersionAiConfig = createMutationHook(postAIConfigVersion);

// update the selected version (modal & prompt) in an AI Config. Fetch latest version afer update
export const useUpdateAiConfigVersion = createMutationHook(patchAIConfigVersion);

// update an AI Config. Fetch latest version afer update
export const useUpdateAiConfig = createMutationHook(patchAIConfig, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getAiConfigQuery.partialQueryKey() });
  },
});

// delete all ai config versions. Basically delete everything. Refetch a list of AI configs
export const useDeleteAiConfig = createMutationHook(deleteAIConfig, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.refetchQueries({ queryKey: getAiConfigsQuery.partialQueryKey() });
  },
});

// delete a single ai config version (model & prompt). Retain any existing version (model & prompts). Refetch vall there AI Config versions
export const useDeleteAiConfigVersion = createMutationHook(deleteAIConfigVersion, {
  onSuccess: async (data, { configKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getAiConfigQuery.partialQueryKey({ configKey }) });
  },
});
