/* eslint-disable @typescript-eslint/naming-convention,new-cap */
import { dogfoodContext, intercomFinAppAppId, intercomFinAppUserIdentityHash } from '@gonfalon/constants';

import { trackIntercomEvents } from './internal/trackIntercomEvents';
import { InitIntercomFinAppParams } from './types';

export function initialBoot(appId = intercomFinAppAppId(), options?: InitIntercomFinAppParams) {
  window?.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: appId,
    // @ts-expect-error member does exist
    name: dogfoodContext()?.member?.name,
    // @ts-expect-error member does exist
    email: dogfoodContext()?.member?.email,
    // @ts-expect-error intercomFinAppUserIdentityHash does exist
    user_hash: options?.identityVerificationEnabled ? intercomFinAppUserIdentityHash() : null,
    // @ts-expect-error dogfood context member key exists
    user_id: options?.identityVerificationEnabled ? dogfoodContext()?.member?.key : null,
    custom_launcher_selector: options?.iaExperience ? '#ia-fin-launcher' : '#fin-launcher',
    hide_default_launcher: true,
    flowName: options?.flowName,
  });
  trackIntercomEvents();
}
