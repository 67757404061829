import { type RefObject, useEffect } from 'react';

/**
 * Observe an element's dimensions off the main thread.
 *
 * This hook returns all the size information computed by
 * the browser when a change in size occurred.
 *
 */
export function useResizeObserver<T extends Element>({
  ref,
  box,
  enabled,
  onResize,
}: {
  ref: RefObject<T | undefined | null> | undefined;
  box?: ResizeObserverBoxOptions | undefined;
  enabled?: boolean | undefined;
  onResize: (entries: ResizeObserverEntry) => void;
}) {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const element = ref?.current;
    if (!element) {
      return;
    }

    const observer = new window.ResizeObserver((entries) => {
      if (!entries.length) {
        return;
      }

      for (const entry of entries) {
        if (entry.target === element) {
          onResize(entry);
        }
      }
    });

    observer.observe(element, { box });

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [onResize, ref, enabled, box]);
}
