import { hasCustomRoles } from './hasCustomRoles';
import { hasPermissionGrants } from './hasPermissionGrants';
import { hasTeamCustomRoles } from './hasTeamCustomRoles';
import { Member } from './types';

export function isWriter(member: Member) {
  return (
    member.role === 'writer' && !hasCustomRoles(member) && !hasTeamCustomRoles(member) && !hasPermissionGrants(member)
  );
}
