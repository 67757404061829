import { GetExperimentAnalysisQueryParams, schemas } from '@gonfalon/openapi';
import { QueryOptions, useQueries } from '@tanstack/react-query';

import { createQueryHook } from './internal/createQueryHook';
import { experiments } from './internal/queries';

export const useExperimentAnalysis = createQueryHook(experimentAnalysisQuery);

export function useExperimentAnalyses(
  {
    projectKey,
    environmentKey,
    experimentKey,
    slices,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    slices: Array<{ metricKey: string; attribute?: { name: string; value: string | number | boolean } }>;
    params?: GetExperimentAnalysisQueryParams;
  },
  options?: QueryOptions,
) {
  return useQueries({
    ...options,
    queries: slices.map(({ metricKey, attribute }) =>
      experimentAnalysisQuery({
        projectKey,
        environmentKey,
        experimentKey,
        metricKey,
        params: {
          ...params,
          attributes: attribute ? [{ name: attribute.name, values: [attribute.value] }] : undefined,
        },
      }),
    ),
  });
}

function experimentAnalysisQuery({
  projectKey,
  environmentKey,
  experimentKey,
  metricKey,
  params,
}: {
  projectKey: string;
  environmentKey: string;
  experimentKey: string;
  metricKey: string;
  params?: GetExperimentAnalysisQueryParams;
}) {
  return {
    ...experiments.detail({ projectKey, environmentKey, experimentKey })._ctx.analysis({ metricKey, params }),
    select: experimentAnalysisSelect,
  };
}

// tighten up the TreatmentAnalysis type a bit
// 1. validate that treatments have a valid analysis model
// 2. adds a methodology and pdf field to each treatment
// 3. renames (bayesian|frequentist)(Beta|Normal) to modelAnalysis
export type TreatmentAnalysis = Omit<
  schemas['TreatmentAnalysisRep'],
  'bayesianBeta' | 'bayesianNormal' | 'frequentistBeta' | 'frequentistNormal'
> &
  (
    | {
        model: 'bayesianBeta';
        methodology: 'bayesian';
        pdf: 'beta';
        modelAnalysis: schemas['BayesianBetaAnalysisRep'];
      }
    | {
        model: 'bayesianNormal';
        methodology: 'bayesian';
        pdf: 'normal';
        modelAnalysis: schemas['BayesianNormalAnalysisRep'];
      }
    | {
        model: 'frequentistBeta';
        methodology: 'frequentist';
        pdf: 'beta';
        modelAnalysis: schemas['FrequentistBetaAnalysisRep'];
      }
    | {
        model: 'frequentistNormal';
        methodology: 'frequentist';
        pdf: 'normal';
        modelAnalysis: schemas['FrequentistNormalAnalysisRep'];
      }
  );

export type ExperimentAnalysis = Omit<schemas['ExperimentAnalysisRep'], 'treatments'> & {
  treatments: TreatmentAnalysis[];
};

function experimentAnalysisSelect(analysis: schemas['ExperimentAnalysisRep']): ExperimentAnalysis {
  return {
    ...analysis,
    treatments: (analysis.treatments ?? []).map(
      ({ model, bayesianBeta, bayesianNormal, frequentistBeta, frequentistNormal, ...rest }) => {
        if (model === 'bayesianBeta') {
          if (!bayesianBeta) {
            throw new Error('Bayesian beta treatment analysis is missing');
          }
          return { ...rest, model, modelAnalysis: bayesianBeta, methodology: 'bayesian', pdf: 'beta' };
        }
        if (model === 'bayesianNormal') {
          if (!bayesianNormal) {
            throw new Error('Bayesian normal treatment analysis is missing');
          }
          return { ...rest, model, modelAnalysis: bayesianNormal, methodology: 'bayesian', pdf: 'normal' };
        }
        if (model === 'frequentistBeta') {
          if (!frequentistBeta) {
            throw new Error('Frequentist beta treatment analysis is missing');
          }
          return { ...rest, model, modelAnalysis: frequentistBeta, methodology: 'frequentist', pdf: 'beta' };
        }
        if (model === 'frequentistNormal') {
          if (!frequentistNormal) {
            throw new Error('Frequentist normal treatment analysis is missing');
          }
          return { ...rest, model, modelAnalysis: frequentistNormal, methodology: 'frequentist', pdf: 'normal' };
        }
        throw new Error(`Unknown treatment model: ${model}`);
      },
    ),
  };
}
