import { Err, Ok, Result } from 'ts-results';

import { EOF } from './constants';
import { Token } from './token';

type ReadResult = Result<string, string>;

export class Lexer {
  // current position in input (current character)
  private position: number;
  // current reading position in input (next character)
  private readPosition: number;
  // current character
  private character: string;
  // keep track of some context so we can properly
  // resolve the filter pieces.
  private previousMatchName: string | undefined;
  private previousMatchOperator: string | undefined;

  public readonly input: string;

  constructor(input: string) {
    this.input = input;
    this.position = 0;
    this.readPosition = 0;
    this.character = EOF;

    // Move into initial position
    this.readCharacter();
  }

  public nextToken(): Token {
    let token: Token;

    this.skipWhitespace();

    const startPosition = this.position;

    switch (this.character) {
      case EOF:
        token = { type: 'eof', text: EOF, startPosition, endPosition: startPosition };
        break;
      case ',':
        token = { type: 'and', text: ',', startPosition, endPosition: startPosition + 1 };
        this.readCharacter();
        break;
      case '|':
        token = { type: 'or', text: '|', startPosition, endPosition: startPosition + 1 };
        this.readCharacter();
        break;
      case '(':
        token = { type: 'openparen', text: '(', startPosition, endPosition: startPosition + 1 };
        this.readCharacter();
        break;
      case ')':
        token = { type: 'closeparen', text: ')', startPosition, endPosition: startPosition + 1 };
        this.readCharacter();
        break;
      default:
        if (this.previousMatchName === undefined) {
          token = this.readMatchName();
          this.previousMatchName = token.text;
        } else if (this.previousMatchOperator === undefined) {
          token = this.readMatchOperator();
          if (token.type === 'illegal') {
            return token;
          }
          token.type = 'matchoperator';
          this.previousMatchOperator = token.text;
        } else {
          token = this.readMatchValue();
          this.previousMatchName = undefined;
          this.previousMatchOperator = undefined;
        }
    }

    return token;
  }

  private readMatchName(): Token {
    const startPosition = this.position;
    let token: Token;
    let result: ReadResult;

    switch (this.character) {
      case '"':
        // Skip first quote
        this.readCharacter();

        result = this.readUntil(characterIn('"'));
        if (result.err) {
          return {
            type: 'illegal',
            text: `"${result.val}`,
            startPosition,
            endPosition: startPosition + `"${result.val}`.length,
            reason: 'unmatched_quote',
          };
        }

        // Skip closing quote
        this.readCharacter();
        token = {
          type: 'matchname',
          text: `"${result.val}"`,
          startPosition,
          endPosition: startPosition + `"${result.val}`.length,
        };
        break;
      default:
        result = this.readUntil(
          (character) => characterIn(',', '|', ')', '(')(character) || /^\p{White_Space}$/u.test(character),
        );
        if (result.err) {
          return {
            type: 'illegal',
            text: result.val,
            startPosition,
            endPosition: startPosition + result.val.length,
            reason: 'eof',
          };
        }

        // Empty value
        if (result.val.length === 0) {
          return {
            type: 'illegal',
            text: '',
            startPosition,
            endPosition: startPosition,
            reason: 'empty',
          };
        }

        token = {
          type: 'matchname',
          text: JSON.stringify(result.val),
          startPosition,
          endPosition: startPosition + result.val.length,
        };
    }

    return token;
  }

  private readMatchOperator(): Token {
    return this.readMatchName();
  }

  private readMatchValue(): Token {
    const startPosition = this.position;
    let token: Token;
    let result: ReadResult;

    switch (this.character) {
      case '{':
        token = this.readObject('{', '}');
        break;
      case '[':
        token = this.readObject('[', ']');
        break;
      case '"':
        // Skip first quote
        this.readCharacter();

        result = this.readUntil(characterIn('"'));

        // Open-ended quote
        if (result.err) {
          return {
            type: 'illegal',
            text: `"${result.val}`,
            startPosition,
            endPosition: startPosition + `"${result.val}`.length,
          };
        }

        // Skip closing quote
        this.readCharacter();

        token = {
          type: 'matchvalue',
          text: `"${result.val}"`,
          startPosition,
          endPosition: startPosition + result.val.length,
        };
        break;
      default:
        result = this.readUntil(
          (character) => characterIn(',', '|', ')', '(')(character) || /^\p{White_Space}$/u.test(character),
        );

        if (result.val.length === 0) {
          return { type: 'illegal', text: '', startPosition, endPosition: startPosition };
        }

        // Apply implicit "equals" operator
        token = {
          type: 'matchvalue',
          text: this.maybeRequoteNakedString(result.val),
          startPosition,
          endPosition: startPosition + result.val.length,
        };
    }

    return token;
  }

  private maybeRequoteNakedString(input: string) {
    switch (input) {
      case '':
        return 'null';
      case 'true':
      case 'false':
      case 'null':
        return input;
      default:
        if (!Number.isNaN(Number.parseFloat(input))) {
          return input;
        }

        return `"${input}"`;
    }
  }

  /**
   * readObject reads the input between two braces,
   * and includes the braces in the result.
   *
   * @param openBrace Symbol that opens the object
   * @param closeBrace Symbol that closes the object
   * @returns Token
   */
  private readObject(openBrace: string, closeBrace: string): Token {
    const startPosition = this.position;

    if (this.character !== openBrace) {
      return { type: 'illegal', text: this.character, startPosition: startPosition - 1, endPosition: startPosition };
    }

    let text = '';
    text += this.character;

    // Move past the first open brace
    this.readCharacter();

    let openBraces = 1;

    while (this.character !== EOF) {
      switch (this.character) {
        case openBrace:
          openBraces += 1;
          break;
        case closeBrace:
          openBraces -= 1;
          break;
      }

      text += this.character;
      this.readCharacter();

      if (openBraces === 0) {
        return { type: 'matchvalue', text, startPosition, endPosition: startPosition + text.length };
      }
    }

    return { type: 'illegal', text, startPosition, endPosition: startPosition + text.length };
  }

  private readUntil(condition: (character: string) => boolean): ReadResult {
    let text = '';

    while (this.character !== EOF) {
      if (condition(this.character)) {
        return Ok(text);
      }

      text += this.character;

      this.readCharacter();
    }

    return Err(text);
  }

  private readCharacter() {
    if (this.readPosition >= this.input.length) {
      this.character = EOF;
    } else {
      this.character = this.input[this.readPosition];
    }
    this.position = this.readPosition;
    this.readPosition += 1;
  }

  private skipWhitespace() {
    while (this.isWhitespace(this.character)) {
      this.readCharacter();
    }
  }

  private isWhitespace(character: string) {
    return /^\p{White_Space}$/u.test(character);
  }
}

export function lex(input: string): Token[] {
  const lexer = new Lexer(input);
  const tokens: Token[] = [];
  let token = lexer.nextToken();
  while (token.type !== 'eof') {
    tokens.push(token);
    token = lexer.nextToken();
  }

  return tokens;
}

function characterIn(...characters: string[]) {
  return (character: string) => {
    for (const ch of characters) {
      if (ch === character) {
        return true;
      }
    }

    return false;
  };
}
