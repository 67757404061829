import { trackNewApplicationCreated } from '@gonfalon/applications';
import { createApplication } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { applications } from './internal/queries';

export const useCreateApplication = createMutationHook(createApplication, {
  onSuccess: async (data) => {
    trackNewApplicationCreated({ name: data.name, key: data.key, autoAdded: data.autoAdded, kind: data.kind });
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: applications.list._def });
  },
});
