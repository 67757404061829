import { camelCase, kebabCase, snakeCase, upperFirst } from '@gonfalon/es6-utils';

export const KEY_MAX_LENGTH = 256;

export const keyRegexp = /^[0-9A-Za-z][.A-Za-z_\-0-9]*$/;

export const keyFormatMsg =
  "Keys must start with a letter or number and only contain letters, numbers, '.', '_' or '-'";

export const keyInvalidMsg = `Invalid key. ${keyFormatMsg}` as const;

export const hookFormKeyInvalid = {
  value: keyRegexp,
  message: keyInvalidMsg,
};
export const capitalizedWordRegexp = /^[A-Z0-9][a-z0-9]*$/;

export const isValidRegex = (pattern: string) => {
  try {
    new RegExp(pattern);
    return true;
  } catch (e) {
    return false;
  }
};

export const transformKeyConvention = (nameValue: string, pattern?: string, prefix?: string) => {
  if ((pattern && pattern === 'upperCamelCase') || (pattern && pattern === 'camelCase' && prefix)) {
    return upperFirst(camelCase(nameValue));
  } else if (pattern && pattern === 'camelCase') {
    return camelCase(nameValue);
  } else if (pattern && pattern === 'snakeCase') {
    return snakeCase(nameValue);
  } else if ((pattern && pattern === 'kebabCase') || (!pattern && prefix)) {
    return kebabCase(nameValue);
  }
  return nameValue;
};

export const camelCaseRegex = /^[a-z]+(?:[A-Z]+[a-z0-9]*)*$/;
export const pascalCaseRegex = /^[A-Z]+(?:[a-z0-9]*[A-Z]+)*[a-z0-9]*$/;

export const snakeCaseRegex = /^[a-z0-9]+(?:_[a-z0-9]+)*$/;
export const kebabCaseRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

export const camelCaseFormatMsg = 'Use camelCase: Start with a lowercase letter, no spaces or special characters.';
export const pascalCaseFormatMsg = 'Use PascalCase: Start with an uppercase letter, no spaces or special characters.';
export const snakeCaseFormatMsg = 'Use snake_case: Lowercase letters, words separated by underscores.';
export const kebabCaseFormatMsg = 'Use kebab-case: Lowercase letters, words separated by hyphens.';

export const validatePrefixRegex = (selectedOption: string) => {
  if (selectedOption === 'camelCase') {
    return {
      regex: camelCaseRegex,
      message: camelCaseFormatMsg,
    };
  } else if (selectedOption === 'upperCamelCase') {
    return {
      regex: pascalCaseRegex,
      message: pascalCaseFormatMsg,
    };
  } else if (selectedOption === 'snakeCase') {
    return {
      regex: snakeCaseRegex,
      message: snakeCaseFormatMsg,
    };
  } else if (selectedOption === 'kebabCase') {
    return {
      regex: kebabCaseRegex,
      message: kebabCaseFormatMsg,
    };
  } else {
    return {
      regex: keyRegexp,
      message: keyFormatMsg,
    };
  }
};
