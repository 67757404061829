import { Record } from 'immutable';

import { CreateFunctionInput } from 'utils/immutableUtils';
import { isNotEmpty, validateRecord } from 'utils/validationUtils';

type SessionConfigProps = {
  durationMins: number;
  refresh: boolean;
};

export class SessionConfig extends Record<SessionConfigProps>({
  durationMins: 0,
  refresh: false,
}) {
  validate() {
    return validateRecord(this, isNotEmpty('duration'), isNotEmpty('refresh'));
  }
}

type SessionConfigFormProps = {
  sessionDuration: number;
  sessionDurationUnits: string;
  refresh: boolean;
};

export class SessionConfigForm extends Record<SessionConfigFormProps>({
  sessionDuration: 0,
  sessionDurationUnits: 'minutes',
  refresh: false,
}) {
  validate() {
    return validateRecord(
      this,
      isNotEmpty('sessionDuration'),
      isNotEmpty('sessionDurationUnits'),
      isNotEmpty('refresh'),
    );
  }
}

export function toMinutes({
  sessionDuration,
  sessionDurationUnits,
}: {
  sessionDuration: number;
  sessionDurationUnits: string;
}) {
  if (sessionDurationUnits === 'days') {
    return sessionDuration * 1440;
  } else if (sessionDurationUnits === 'hours') {
    return sessionDuration * 60;
  }
  return sessionDuration;
}

export function toNearestWholeUnitTime({
  sessionDuration,
  sessionDurationUnits,
}: {
  sessionDuration: number;
  sessionDurationUnits: string;
}) {
  const sessionDurationMinutes = toMinutes({
    sessionDuration,
    sessionDurationUnits,
  });
  if (sessionDurationMinutes % 1440 === 0) {
    return {
      sessionDuration: sessionDurationMinutes / 1440,
      sessionDurationUnits: 'days',
    };
  } else if (sessionDurationMinutes % 60 === 0) {
    return {
      sessionDuration: sessionDurationMinutes / 60,
      sessionDurationUnits: 'hours',
    };
  }
  return {
    sessionDuration: sessionDurationMinutes,
    sessionDurationUnits: 'minutes',
  };
}

export function createSessionConfigForm(props: CreateFunctionInput<SessionConfigForm>) {
  return new SessionConfigForm(props);
}

export function createSessionConfig(props: CreateFunctionInput<SessionConfig>) {
  return new SessionConfig(props);
}
