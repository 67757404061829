import { GetExperimentOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface GetExperimentQueryParams extends Omit<GetExperimentOpenAPIQueryParams, 'expand'> {
  expand?: Array<
    | 'previousIterations'
    | 'draftIteration'
    | 'draftDesign'
    | 'treatments'
    | 'secondaryMetrics'
    | 'metrics'
    | 'analysisConfig'
  >;
}

export function querySerializer(queryParams: GetExperimentQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.expand) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  return searchParams.toString();
}
