import { accountId, userId } from '@gonfalon/constants';
import { waitForDogfoodClient } from '@gonfalon/dogfood-client';
import { isAnalyticsTrackingEnabled, shouldSendFrontEndTrackEventsToCatfood } from '@gonfalon/dogfood-flags';

import { AnalyticEventData } from './types';

declare const window: Window & {
  analytics?: SegmentAnalytics.AnalyticsJS;
  FS?: {
    getCurrentSessionURL: (includeCompleteURL: boolean) => string;
  };
};

const track = (event: string, category?: string, attributes?: AnalyticEventData, metricValue?: number) => {
  if (isAnalyticsTrackingEnabled()) {
    window.analytics?.track?.(
      event,
      {
        category,
        userId: userId(),
        accountId: accountId(),
        ...attributes,
      },
      {
        integrations: {
          Intercom: false,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Amplitude: { session_id: window.sessionStorage.getItem('sessionStartTime') },
        },
      },
    );
  }
  if (shouldSendFrontEndTrackEventsToCatfood()) {
    waitForDogfoodClient().then(
      (d) => d.track(event, attributes, metricValue),
      // TODO: Switch to new logger package when it's ready
      // () => logger.error('Catfood tracking failed'),
      // eslint-disable-next-line no-console
      () => console.error('Catfood tracking failed'),
    );
  }
};

export { track };
