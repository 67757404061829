import cx from 'clsx';
import { Chip } from 'launchpad';

import { LaunchDarklyOsmo } from 'components/ui/icons/LaunchDarklyOsmo';
import { titlecase } from 'utils/stringUtils';

import styles from './styles/LogoWithWordmarkHeader.module.css';

type LogoWithWordmarkHeaderProps = {
  federalCssStylesEnabled?: boolean;
  hideHeaderBackground?: boolean;
  logoUrl?: string;
};

export function LogoWithWordmarkHeader({
  federalCssStylesEnabled,
  hideHeaderBackground,
  logoUrl,
}: LogoWithWordmarkHeaderProps) {
  return (
    <header
      className={cx(styles.logoWithWordmarkHeader, styles.dark, {
        [styles.noBackground]: hideHeaderBackground,
      })}
    >
      <a href={logoUrl ? logoUrl : '/'} aria-label="LaunchDarkly">
        <LaunchDarklyOsmo />
      </a>
      {federalCssStylesEnabled && (
        <Chip className={styles.logoHeader} kind="federal">
          {titlecase('federal')}
        </Chip>
      )}
    </header>
  );
}
