import { Record } from 'immutable';
import tinycolor from 'tinycolor2';

const getColorLibrary = async () =>
  (await import(/* webpackChunkName: "colorLibrary" */ './colorUtilsConstants')).colorLibrary;

export class Color extends Record({ data: tinycolor('black') }) {
  rgb() {
    return this.data.toRgb();
  }

  hsl() {
    return this.data.toHsl();
  }

  hsv() {
    return this.data.toHsv();
  }

  hue(h: number) {
    const hsl = this.hsl();
    return this.set('data', tinycolor({ h, s: hsl.s, l: hsl.l }));
  }

  luminosity(l: number) {
    const hsl = this.hsl();
    return this.set('data', tinycolor({ h: hsl.h, s: hsl.s, l }));
  }

  pure() {
    const h = this.hsl().h;
    return this.set('data', tinycolor({ h, s: 100, l: 50 }));
  }

  darken(amount: number) {
    return this.update('data', (data) => tinycolor(data.toRgb()).darken(amount));
  }

  lighten(amount: number) {
    return this.update('data', (data) => tinycolor(data.toRgb()).lighten(amount));
  }

  alpha(a: number) {
    return this.update('data', (data) => tinycolor(data.toRgb()).setAlpha(a));
  }

  toString(...args: []) {
    return this.data.toString(...args);
  }

  css(...args: []) {
    return this.toString(...args);
  }

  hex() {
    return this.data.toHex();
  }

  valueOf() {
    return this.hex();
  }
}

function createColor(
  color: Color | { h: number; s: number; l: number } | { r: number; g: number; b: number; a?: number } | string,
) {
  if (color instanceof Color) {
    return color;
  }
  return new Color({ data: tinycolor(color) });
}

function createRandomColor() {
  const color = createColor({
    h: Math.round(Math.random() * 360),
    s: 1,
    l: 0.5,
  });
  return color;
}
// Format should be 0000 or 000000
function hexToRgb(h: string) {
  let r = '',
    g = '',
    b = '';
  // 3 digits
  if (h.length === 4) {
    r = h[0] + h[0];
    g = h[1] + h[1];
    b = h[2] + h[2];
    return { r: parseInt(r, 16), g: parseInt(g, 16), b: parseInt(b, 16) };
    // 6 digits
  } else if (h.length === 6) {
    r = h[0] + h[1];
    g = h[2] + h[3];
    b = h[4] + h[5];
    return { r: parseInt(r, 16), g: parseInt(g, 16), b: parseInt(b, 16) };
  }
  return null;
}

/**
 * Returns the closest color name given a hex color or Color record. If the color
 * is invalid or no close color was found, it will return null.
 * Ex: #fff will return "white"
 */
async function getClosestColorName(color?: string | Color) {
  // validate hex value
  if (!color) {
    return null;
  }

  const colorVal = createColor(color);
  if (!colorVal.pure().data.isValid()) {
    return null;
  }

  const { r, g, b } = colorVal.rgb();

  let nRGBVal = 0;

  let ndf = 0;
  let cl: null | string = null;
  let df = -1;
  const loadedColorLibrary = await getColorLibrary();

  for (const colorFromLibrary of Object.keys(loadedColorLibrary)) {
    const rgbValueToCompare = hexToRgb(`${loadedColorLibrary[colorFromLibrary]}`);
    if (!rgbValueToCompare) {
      return null;
    }
    const { r: r2, g: g2, b: b2 } = rgbValueToCompare;
    if (r === r2 && g === g2 && b === b2) {
      return colorFromLibrary;
    }

    // closest using rgb values
    nRGBVal = Math.pow(r - r2, 2) + Math.pow(g - g2, 2) + Math.pow(b - b2, 2);

    ndf = nRGBVal;

    if (df < 0 || df > ndf) {
      df = ndf;
      cl = colorFromLibrary;
    }
  }
  const result = cl;

  return result;
}

const white = createColor('white');
const black = createColor('black');

function parseColor(color: Color | { h: number; s: number; l: number } | string) {
  return color instanceof Color ? color : createColor(color);
}

function readable(color: string) {
  return parseColor(color).data.isLight() ? black : white;
}

function css(color: { h: number; s: number; l: number }, ...args: []) {
  return parseColor(color).toString(...args);
}

function pure(color: string) {
  return parseColor(color).pure();
}

function alpha(color: string, a: number) {
  return parseColor(color).alpha(a);
}

export { createColor, createRandomColor, getClosestColorName, readable, css, pure, black, alpha, white };
