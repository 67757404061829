import { createQueryHook } from './internal/createQueryHook';
import { monitoringIssuesDetail } from './internal/queries';

export const useMonitoringIssue = createQueryHook(
  ({
    projectKey,
    issueId,
    windowDays,
    errorId,
  }: {
    projectKey: string;
    issueId: string;
    windowDays?: number;
    errorId?: string;
  }) => monitoringIssuesDetail({ projectKey, issueId, query: { windowDays, errorId } }),
);
