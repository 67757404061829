import { type GetExperimentsQueryParams } from '@gonfalon/openapi';

import { createQueryHook } from './internal/createQueryHook';
import { experiments } from './internal/queries';

export const useExperiments = createQueryHook(
  ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetExperimentsQueryParams;
  }) => experiments.list({ projectKey, environmentKey, params }),
);
