export function parseChangehistorySearchParams(searchParams: URLSearchParams) {
  const activity = searchParams.get('activity') ?? undefined;
  const auditId = searchParams.get('activity-auditId') ?? undefined;
  if (activity && auditId) {
    return { activity, auditId };
  } else {
    return { activity };
  }
}

export type ChangeHistorySearchParams = ReturnType<typeof parseChangehistorySearchParams>;
