import { GetExperimentAnalysisOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface GetExperimentAnalysisQueryParams extends GetExperimentAnalysisOpenAPIQueryParams {
  attributes?: Array<{ name: string; values: Array<string | number | boolean> }>;
}

export function querySerializer(queryParams: GetExperimentAnalysisQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.iterationId) {
    searchParams.set('iterationId', queryParams.iterationId);
  }

  if (queryParams.attributes) {
    searchParams.set('attributes', JSON.stringify(queryParams.attributes));
  }

  return searchParams.toString();
}
