import { trackHideMessenger, trackShowMessenger } from './analytics';

export const trackIntercomEvents = () => {
  // eslint-disable-next-line new-cap
  window?.Intercom('onShow', () => {
    trackShowMessenger();
  });
  // eslint-disable-next-line new-cap
  window?.Intercom('onHide', () => {
    trackHideMessenger();
  });
};
