import { GetPayloadFiltersOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export type GetPayloadFiltersQueryParams = Omit<GetPayloadFiltersOpenAPIQueryParams, 'filter'> & {
  filter?: {
    query?: string;
    archived?: boolean;
  };
};

export function querySerializer(queryParams: GetPayloadFiltersQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.filter) {
    searchParams.set('filter', serializePayloadFilterParam(queryParams.filter));
  }

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  return searchParams.toString();
}

function serializePayloadFilterParam(value: NonNullable<GetPayloadFiltersQueryParams['filter']>) {
  const filter: string[] = [];

  if (value.query) {
    filter.push(`query:${value.query}`);
  }

  if (value.archived) {
    filter.push(`archived:${String(value.archived)}`);
  }

  return filter.sort().join(',');
}
