// This is a re-definition of the Location type from 'history', included here as we migrate away from using 'history'
// directly (once 'history' is no longer included in the project, we likely won't need this type)
type Location = {
  pathname: string;
  search: string;
  hash: string;
  state: unknown;
  key: string;
};

export function updateLocation(location: Location) {
  return {
    type: 'router/UPDATE_LOCATION',
    location,
  };
}
