import { Reducer, ReducersMapObject } from 'redux';

export class ReducerRegistry {
  _emitChange?: (reducers: {}) => void;
  _reducers: ReducersMapObject;

  constructor() {
    this._reducers = {};
  }

  getReducers() {
    return { ...this._reducers };
  }

  addReducer(name: string, reducer: Reducer) {
    this._reducers = { ...this._reducers, [name]: reducer };
    if (this._emitChange) {
      this._emitChange(this.getReducers());
    }
  }

  addReducers(reducers: { [k: string]: Reducer } = {}) {
    Object.keys(reducers).forEach((reducerName) => {
      this.addReducer(reducerName, reducers[reducerName]);
    });
  }

  setChangeListener(listener: (reducers: ReducersMapObject) => void) {
    this._emitChange = listener;
  }
}

const reducerRegistry = new ReducerRegistry();
/* eslint-disable import/no-default-export */
export default reducerRegistry;
