import cx from 'clsx';

import { LogoWithWordmarkHeader } from 'components/ui/layout/LogoWithWordmarkHeader';

import styles from './styles/UnauthenticatedPage.module.css';

type UnauthenticatedPageProps = {
  children?: React.ReactNode;
  federalCssStylesEnabled?: boolean;
  pageClass?: string;
  hideHeaderBackground?: boolean;
  logoUrl?: string;
};

export function UnauthenticatedPage({
  children,
  federalCssStylesEnabled,
  pageClass = '',
  hideHeaderBackground,
  logoUrl,
}: UnauthenticatedPageProps) {
  return (
    <main className={cx(styles.UnauthenticatedPage, pageClass ? styles[pageClass] : pageClass)}>
      <LogoWithWordmarkHeader
        federalCssStylesEnabled={federalCssStylesEnabled}
        hideHeaderBackground={hideHeaderBackground}
        logoUrl={logoUrl}
      />
      <section className={cx(styles.content)}>{children}</section>
    </main>
  );
}
