import { components } from '../generated/openapi';
import { GetMeasuredRolloutsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface GetMeasuredRolloutsQueryParams extends Omit<GetMeasuredRolloutsOpenAPIQueryParams, 'filter'> {
  filter?: {
    environmentKey?: string;
    status?: components['schemas']['MeasuredRolloutStatusKind'];
  };
}

export function querySerializer(queryParams: GetMeasuredRolloutsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.filter) {
    searchParams.set('filter', serializeMeasuredRolloutListFilterParam(queryParams.filter));
  }

  if (queryParams.expand) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  return searchParams.toString();
}

function serializeMeasuredRolloutListFilterParam(value: NonNullable<GetMeasuredRolloutsQueryParams['filter']>) {
  const filter: string[] = [];

  if (value.environmentKey) {
    filter.push(`environmentKey:${value.environmentKey}`);
  }

  if (value.status) {
    filter.push(`status:${value.status}`);
  }

  return filter.sort().join(',');
}
