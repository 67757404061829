import type { JSONArray, JSONValue } from '@gonfalon/types';
import { Result } from 'ts-results';

import { ParserError } from './parser';
import { Token, TokenType } from './token';

export type DateValue = number | string;

export type MatchOperator =
  | 'equals'
  | 'anyOf'
  | 'contains'
  | 'startsWith'
  | 'gte'
  | 'lte'
  | 'after'
  | 'before'
  | 'notEquals'
  | 'exists';

export type MatchValue = JSONValue | JSONArray;

type MatchTokens = {
  matchname: Token;
  matchoperator: Token;
  matchvalue: Token;
};

export type MatchExplanation =
  | {
      type: 'standalone_token';
      input: string;
      failedToken: Token;
      expectedTokenType: TokenType;
    }
  | {
      type: 'expected_token';
      input: string;
      failedToken: Token;
      expectedTokenType: TokenType;
      previousTokens?: Token[];
      previousAST?: Queryfilter;
    }
  | {
      type: 'malformed_json';
      input: string;
      failedToken: Token;
      expectedTokenType: TokenType;
      previousTokens?: Token[];
    }
  | {
      type: 'unknown_operator';
      input: string;
      failedToken: Token;
      expectedTokenType: TokenType;
      previousTokens?: Token[];
    }
  | {
      type: 'invalid_data_type';
      input: string;
      failedToken: Token;
      expectedTokenType: TokenType;
      expectedDataType?: 'string' | 'array' | 'number' | 'datevalue';
      previousTokens?: Token[];
    };

export type Match =
  | {
      type: 'match';
      matchname: string;
      matchoperator: 'equals';
      matchvalue: JSONValue | JSONArray;
      explanation?: MatchExplanation;
      tokens?: MatchTokens;
    }
  | {
      type: 'match';
      matchname: string;
      matchoperator: 'notEquals';
      matchvalue: JSONValue | JSONArray;
      explanation?: MatchExplanation;
      tokens?: MatchTokens;
    }
  | {
      type: 'match';
      matchname: string;
      matchoperator: 'contains';
      matchvalue: JSONArray;
      explanation?: MatchExplanation;
      tokens?: MatchTokens;
    }
  | {
      type: 'match';
      matchname: string;
      matchoperator: 'anyOf';
      matchvalue: JSONArray;
      explanation?: MatchExplanation;
      tokens?: MatchTokens;
    }
  | {
      type: 'match';
      matchname: string;
      matchoperator: 'gte';
      matchvalue: number;
      explanation?: MatchExplanation;
      tokens?: MatchTokens;
    }
  | {
      type: 'match';
      matchname: string;
      matchoperator: 'lte';
      matchvalue: number;
      explanation?: MatchExplanation;
      tokens?: MatchTokens;
    }
  | {
      type: 'match';
      matchname: string;
      matchoperator: 'after';
      matchvalue: DateValue;
      explanation?: MatchExplanation;
      tokens?: MatchTokens;
    }
  | {
      type: 'match';
      matchname: string;
      matchoperator: 'before';
      matchvalue: DateValue;
      explanation?: MatchExplanation;
      tokens?: MatchTokens;
    }
  | {
      type: 'match';
      matchname: string;
      matchoperator: 'startsWith';
      matchvalue: string;
      explanation?: MatchExplanation;
      tokens?: MatchTokens;
    }
  | {
      type: 'match';
      matchname: string;
      matchoperator: 'exists';
      matchvalue: boolean;
      explanation?: MatchExplanation;
      tokens?: MatchTokens;
    };

export type LogicalGroup = { type: 'group'; children: Queryfilter; token?: Token };
export type LogicalAnd = { type: 'and'; children: Queryfilter[]; token?: Token };
export type LogicalOr = { type: 'or'; children: Queryfilter[]; token?: Token };

export type Empty = { type: 'empty' };

export type Queryfilter = Empty | Match | LogicalGroup | LogicalAnd | LogicalOr;

export function getQueryfilterChildren(queryfilter: Result<Queryfilter, ParserError> | undefined): Queryfilter[] {
  if (!queryfilter) {
    return [];
  }
  if (queryfilter.err) {
    return [];
  }
  if (isEmptyNode(queryfilter.val)) {
    return [];
  }
  if (isCompositeNode(queryfilter.val)) {
    return queryfilter.val.children;
  }
  return [];
}

export function getQueryfilterValue(
  queryfilter: Result<Queryfilter, ParserError> | undefined,
): Queryfilter | undefined {
  if (!queryfilter) {
    return undefined;
  }
  if (queryfilter.err) {
    return undefined;
  }
  if (isEmptyNode(queryfilter.val)) {
    return undefined;
  }
  if (isCompositeNode(queryfilter.val)) {
    return queryfilter.val;
  }
  if (isMatchNode(queryfilter.val)) {
    return queryfilter.val;
  }
  return undefined;
}

export function getLastQueryfilterChild(queryfilter: Result<Queryfilter, ParserError> | undefined) {
  if (!queryfilter) {
    return undefined;
  }
  if (queryfilter.err) {
    return undefined;
  }
  if (isEmptyNode(queryfilter.val)) {
    return undefined;
  }
  if (isCompositeNode(queryfilter.val)) {
    // @ts-expect-error - we know that the last child is a match node
    return queryfilter.val.children[queryfilter.val.children.length - 1]?.matchvalue;
  }
  if (isMatchNode(queryfilter.val)) {
    return queryfilter.val.matchvalue;
  }
  return undefined;
}

// method to check if queryfilter result is not parse error and not empty and of type match, return matchname and matchvalue
export function getMatchNameAndValue(
  queryfilter: Result<Queryfilter, ParserError>,
): { matchname: string; matchvalue: MatchValue } | undefined {
  if (queryfilter.err) {
    return undefined;
  }
  if (isEmptyNode(queryfilter.val)) {
    return undefined;
  }
  if (isMatchNode(queryfilter.val)) {
    return { matchname: queryfilter.val.matchname, matchvalue: queryfilter.val.matchvalue };
  }
  return undefined;
}

export function isCompositeNode(node: Queryfilter): node is LogicalAnd | LogicalOr {
  return node.type === 'and' || node.type === 'or';
}
export function isMatchNode(node: Queryfilter): node is Match {
  return node.type === 'match';
}
export function isEmptyNode(node: Queryfilter): node is Empty {
  return node.type === 'empty';
}
const operatorNames = [
  'equals',
  'anyOf',
  'contains',
  'startsWith',
  'gte',
  'lte',
  'after',
  'before',
  'notEquals',
  'exists',
];

export function isValidOperator(input: unknown): input is MatchOperator {
  if (typeof input !== 'string') {
    return false;
  }

  for (const operator of operatorNames) {
    if (operator.toLowerCase() === input.toLowerCase()) {
      return true;
    }
  }

  return false;
}

export function canonicalOperator(input: unknown) {
  if (!isValidOperator(input)) {
    return input;
  }

  const name = operatorNames.find((operator) => operator.toLowerCase() === input.toLowerCase());
  return name;
}

export function isArrayValue(value: unknown): value is JSONArray {
  return Array.isArray(value);
}

export function isValue(value: unknown): value is JSONValue {
  return value === true || value === false || typeof value === 'number' || typeof value === 'string';
}

export function isStringValue(value: unknown): value is string {
  return typeof value === 'string';
}

export function isNumberValue(value: unknown): value is number {
  if (value === undefined || value === null) {
    return false;
  }

  if (typeof value !== 'number') {
    return false;
  }

  return true;
}

export function isDateValue(value: unknown): value is DateValue {
  if (value === undefined || value === null) {
    return false;
  }

  if (typeof value !== 'number' && typeof value !== 'string') {
    return false;
  }

  const date = new Date(value);
  if (!(date instanceof Date) || isNaN(date.valueOf())) {
    return false;
  }

  return true;
}

export function empty(): Empty {
  return { type: 'empty' };
}

export function anyOf(
  matchname: string,
  matchvalue: JSONArray,
  explanation?: MatchExplanation,
  tokens?: MatchTokens,
): Match {
  const node: Match = { type: 'match', matchname, matchoperator: 'anyOf', matchvalue };

  if (explanation) {
    node.explanation = explanation;
  }

  if (tokens) {
    node.tokens = tokens;
  }

  return node;
}

export function equals(
  matchname: string,
  matchvalue: JSONValue | JSONArray,
  explanation?: MatchExplanation,
  tokens?: MatchTokens,
): Match {
  const node: Match = { type: 'match', matchname, matchoperator: 'equals', matchvalue };

  if (explanation) {
    node.explanation = explanation;
  }

  if (tokens) {
    node.tokens = tokens;
  }

  return node;
}

export function notEquals(
  matchname: string,
  matchvalue: JSONValue | JSONArray,
  explanation?: MatchExplanation,
  tokens?: MatchTokens,
): Match {
  const node: Match = { type: 'match', matchname, matchoperator: 'notEquals', matchvalue };

  if (explanation) {
    node.explanation = explanation;
  }

  if (tokens) {
    node.tokens = tokens;
  }

  return node;
}

export function exists(
  matchname: string,
  matchvalue: boolean,
  explanation?: MatchExplanation,
  tokens?: MatchTokens,
): Match {
  const node: Match = { type: 'match', matchname, matchoperator: 'exists', matchvalue };

  if (explanation) {
    node.explanation = explanation;
  }

  if (tokens) {
    node.tokens = tokens;
  }

  return node;
}

export function contains(
  matchname: string,
  matchvalue: JSONArray,
  explanation?: MatchExplanation,
  tokens?: MatchTokens,
): Match {
  const node: Match = { type: 'match', matchname, matchoperator: 'contains', matchvalue };

  if (explanation) {
    node.explanation = explanation;
  }

  if (tokens) {
    node.tokens = tokens;
  }

  return node;
}

export function startsWith(
  matchname: string,
  matchvalue: string,
  explanation?: MatchExplanation,
  tokens?: MatchTokens,
): Match {
  const node: Match = { type: 'match', matchname, matchoperator: 'startsWith', matchvalue };

  if (explanation) {
    node.explanation = explanation;
  }

  if (tokens) {
    node.tokens = tokens;
  }

  return node;
}

export function gte(
  matchname: string,
  matchvalue: number,
  explanation?: MatchExplanation,
  tokens?: MatchTokens,
): Match {
  const node: Match = { type: 'match', matchname, matchoperator: 'gte', matchvalue };

  if (explanation) {
    node.explanation = explanation;
  }

  if (tokens) {
    node.tokens = tokens;
  }

  return node;
}

export function lte(
  matchname: string,
  matchvalue: number,
  explanation?: MatchExplanation,
  tokens?: MatchTokens,
): Match {
  const node: Match = { type: 'match', matchname, matchoperator: 'lte', matchvalue };

  if (explanation) {
    node.explanation = explanation;
  }

  if (tokens) {
    node.tokens = tokens;
  }

  return node;
}

export function after(
  matchname: string,
  matchvalue: DateValue,
  explanation?: MatchExplanation,
  tokens?: MatchTokens,
): Match {
  const node: Match = { type: 'match', matchname, matchoperator: 'after', matchvalue };

  if (explanation) {
    node.explanation = explanation;
  }

  if (tokens) {
    node.tokens = tokens;
  }

  return node;
}

export function before(
  matchname: string,
  matchvalue: DateValue,
  explanation?: MatchExplanation,
  tokens?: MatchTokens,
): Match {
  const node: Match = { type: 'match', matchname, matchoperator: 'before', matchvalue };

  if (explanation) {
    node.explanation = explanation;
  }

  if (tokens) {
    node.tokens = tokens;
  }

  return node;
}

export function parenthesized(nodes: Queryfilter, token?: Token): LogicalGroup {
  const node: LogicalGroup = { type: 'group', children: nodes };

  if (token) {
    node.token = token;
  }

  return node;
}

export function and(nodes: Queryfilter[], token?: Token): LogicalAnd {
  const node: LogicalAnd = { type: 'and', children: nodes };

  if (token) {
    node.token = token;
  }

  return node;
}

export function or(nodes: Queryfilter[], token?: Token): LogicalOr {
  const node: LogicalOr = { type: 'or', children: nodes };

  if (token) {
    node.token = token;
  }

  return node;
}

/**
 * Attempts to reduce the depth of the AST (node)
 * where combining nodes has no impact on the
 * semantics of the resulting AST.
 */
export function flatten(node: Queryfilter): Queryfilter {
  if (node.type === 'empty') {
    return node;
  }

  if (!isCompositeNode(node)) {
    return node;
  }

  if (node.children.length === 0) {
    return node;
  }

  if (node.children.length === 1) {
    return flatten(node.children[0]);
  }

  let newChildren: Queryfilter[] = [];
  for (const child of node.children) {
    if (child.type === node.type) {
      newChildren = newChildren.concat(child.children);
    } else {
      newChildren.push(child);
    }
  }

  if (newChildren.length !== node.children.length) {
    node.children = newChildren;
    return flatten(node);
  }

  for (const [index, child] of node.children.entries()) {
    node.children[index] = flatten(child);
  }

  return node;
}

/**
 * Convert an AST to a string.
 *
 * Note that all (expression) operators (and/or) have equal
 * precedence so we don't both with parentheses when
 * serializing. Should we add operators with higher precedence
 * that will need to change (along with the rest of the parser).
 *
 */
export function toString(node: Queryfilter): string {
  function stringifyMatchValue(matchvalue: MatchValue) {
    return JSON.stringify(matchvalue);
  }

  switch (node.type) {
    case 'empty':
      return '';
    case 'match':
      return `${node.matchname} ${node.matchoperator} ${stringifyMatchValue(node.matchvalue)}`;
    case 'group':
      return `(${toString(node.children)})`;
    case 'and':
    case 'or':
      const matches = [];

      for (const child of node.children) {
        matches.push(toString(child));
      }

      let operator: string;
      switch (node.type) {
        case 'and':
          operator = ',';
          break;
        case 'or':
          operator = '|';
          break;
      }

      return matches.join(operator);
  }
}
