import { useMutation, useQuery } from '@tanstack/react-query';

import {
  getTrustedDeviceTokenInfo,
  resendChooseOrganizationEmailVerification,
  verifyOrganizationAccessToken,
} from 'sources/UnverifiedMemberAPI';

// Sends a request to verify the token and return a longer-lived token to access the choose organization list
export interface UseOrganizationVerificationProps {
  action: 'verify' | 'expire';
  token: string;
}

// Creates a token that allows you to see the list of organizations.
export function useOrganizationVerificationMutation() {
  return useMutation({
    mutationFn: async (props: UseOrganizationVerificationProps) =>
      verifyOrganizationAccessToken(props.token, { action: props.action }),
  });
}

// Gets the token information, such as the email and expiration date, for a trusted device token.
export function useTrustDeviceTokenInfoQuery(token: string) {
  return useQuery({
    queryKey: ['trustDeviceTokenInfo', token],
    queryFn: async () => getTrustedDeviceTokenInfo(token),
    retry: false,
  });
}

// Send an email to the user with a link to verify their email address.
export function useSendChooseOrganizationVerificationEmailMutation() {
  return useMutation({ mutationFn: async (email: string) => resendChooseOrganizationEmailVerification(email) });
}
