import { patchHoldout } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { holdouts } from './internal/queries';

async function patchHoldoutSemantically(
  input: Parameters<typeof patchHoldout>[0],
  options: Parameters<typeof patchHoldout>[1] = {},
) {
  return patchHoldout(input, {
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
    },
  });
}

export const useUpdateHoldout = createMutationHook(patchHoldoutSemantically, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: holdouts.detail._def });
    await queryClient.invalidateQueries({ queryKey: holdouts.list._def });
  },
});
