import { type NavigateOptions, useSearchParams } from 'react-router-dom';

import { createCustomSearchParamsUpdater } from './createCustomSearchParamsUpdater';

export type Setter<T extends object> = (
  nextInit: T | ((prev: T) => T),
  navigateOpts?: NavigateOptions | undefined,
) => void;

export function createSearchParamsHook<T extends object>({
  parse,
  serialize,
}: {
  parse: (searchParams: URLSearchParams) => T;
  serialize: (t: T) => URLSearchParams;
}) {
  return function useSearchParamsInner(defaultInit?: T | undefined) {
    const [searchParams, setSearchParams] = useSearchParams(
      defaultInit !== undefined ? serialize(defaultInit) : undefined,
    );

    const customParamsUpdater = createCustomSearchParamsUpdater({ parse, serialize });

    const createSearchParams = (init: T | ((prev: T) => T)) => customParamsUpdater(searchParams, init);

    const setCustomSearchParams = (init: T | ((prev: T) => T)) => {
      setSearchParams(createSearchParams(init));
    };

    return [parse(searchParams), setCustomSearchParams, createSearchParams] as const;
  };
}
