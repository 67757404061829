import { createQueryHook } from './internal/createQueryHook';
import { discoveredMetricEvents } from './internal/queries';
import { GetDiscoveredMetricEventsParams } from './internal/types';

export const useDiscoveredMetricEvents = createQueryHook(
  ({
    projectKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetDiscoveredMetricEventsParams;
  }) => discoveredMetricEvents.list({ projectKey, environmentKey, params }),
);
