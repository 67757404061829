import { Path } from 'react-router-dom';

import { changeHistorySearchParamsUpdater } from './changeHistorySearchParamsUpdater';

/**
 * Adds change history search params to a destination
 * @param changeHistory - if true, adds change history search params to the destination if a string, adds change history search params with the auditId
 * @returns updated destination
 */
export function withChangeHistory(changeHistory?: true | string) {
  return (path: Path) => {
    if (!changeHistory) {
      return path;
    }
    const searchParams = new URLSearchParams(path.search);
    const nextParams = {
      activity: 'true',
      ...(typeof changeHistory === 'string' ? { auditId: changeHistory } : {}),
    };

    const nextSearchParams = changeHistorySearchParamsUpdater(searchParams, nextParams);

    return {
      ...path,
      search: nextSearchParams.toString(),
    };
  };
}
