import { GetMetricGroupOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface GetMetricGroupQueryParams extends Omit<GetMetricGroupOpenAPIQueryParams, 'expand'> {
  expand?: Array<'experiments' | 'experimentCount'>;
}

export function querySerializer(queryParams: GetMetricGroupQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.expand && queryParams.expand.length > 0) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  return searchParams.toString();
}
