import { createQueryHook } from './internal/createQueryHook';
import { flagImport } from './internal/queries';

export const useFlagImportIntegration = createQueryHook(
  ({
    projectKey,
    integrationKey,
    integrationId,
  }: {
    projectKey: string;
    integrationKey: string;
    integrationId: string;
  }) =>
    flagImport.detail({
      projectKey,
      integrationKey,
      integrationId,
    }),
);
