// Convert a string to camel case.
//
// Examples:
// camelCase('foo bar baz'); // 'fooBarBaz'
// camelCase('fooBarBaz'); // 'fooBarBaz'
// camelCase('foo     bar'); // 'fooBar'
// camelCase('foobar'); // 'foobar'
// camelCase('Foobar'); // 'foobar'
// camelCase('foo123Bar'); // 'foo123Bar'
// camelCase(''); // ''
//
const camelCase = (str: string): string =>
  str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()).replace(/^[A-Z]/, (m) => m.toLowerCase());
export { camelCase };
