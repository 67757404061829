import cx from 'clsx';

import { LegacyIcon } from './LegacyIcon';
import { LegacyIconProps } from './types';

import styles from './ReleaseGuardianIcon.module.css';

type ReleaseGuardianIconProps = LegacyIconProps & {
  isAnimated?: boolean;
};

export function ReleaseGuardianIcon({ isAnimated, ...props }: ReleaseGuardianIconProps) {
  return (
    <LegacyIcon name="release-guardian" {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16">
        <path d="M8 14.67C6.46 14.28 5.18 13.39 4.17 12.01C3.17 10.62 2.67 9.09 2.67 7.4V3.33L8 1.33l5.33 2V7.4c0 1.69 -0.5 3.22 -1.51 4.61C10.82 13.39 9.54 14.28 8 14.67z" />
        <path
          className={cx(styles.heart, { [styles.isAnimated]: isAnimated })}
          d="M8 10.667C9.133 9.644 9.861 8.925 10.183 8.508c0.322 -0.417 0.483 -0.831 0.483 -1.242c0 -0.4 -0.144 -0.744 -0.433 -1.033S9.6 5.8 9.2 5.8C8.967 5.8 8.742 5.847 8.525 5.942S8.133 6.167 8 6.333C7.867 6.167 7.694 6.036 7.483 5.942S7.044 5.8 6.8 5.8C6.4 5.8 6.056 5.944 5.767 6.233S5.333 6.867 5.333 7.267c0 0.211 0.028 0.406 0.083 0.583s0.178 0.386 0.367 0.625C5.972 8.714 6.242 9.006 6.592 9.35S7.411 10.133 8 10.667z"
        />
      </svg>
    </LegacyIcon>
  );
}
