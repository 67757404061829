import { InternalEnvironment } from '@gonfalon/environments';
import { Project } from '@gonfalon/projects';

import { createContainerDataFunction } from './createContainerDataFunction';
import { createInitialStateFunction } from './createInitialStateFunction';

export const acceptUrl = createContainerDataFunction<string>('acceptUrl');
export const applicationName = createContainerDataFunction<string>('applicationName');
export const configUrl = createContainerDataFunction<string>('configUrl');
export const currentEnvironment = createInitialStateFunction<InternalEnvironment>('currentEnvironment');
export const currentProject = createInitialStateFunction<Project>('currentProject');
export const denyUrl = createContainerDataFunction<string>('denyUrl');
export const error = createContainerDataFunction<string | undefined>('error');
export const flag = createInitialStateFunction('flag');
export const joinExistingOrgConfig = createInitialStateFunction<{
  accessToken: string;
  domainMatchedAccounts: Array<{
    accountId: string;
    alreadyJoined: boolean;
    hasAutoSeatProvisioningEnabled: boolean;
    hasSSO: boolean;
    memberCount: number;
    organization: string;
  }>;
  memberEmail: string;
}>('joinExistingOrgConfig');
export const maintainerName = createContainerDataFunction<string>('maintainerName');
export const maintainerUrl = createContainerDataFunction<string>('maintainerUrl');
export const oauthAction = createContainerDataFunction<string>('oauthAction');
export const oauthEmail = createContainerDataFunction<string>('oauthEmail');
export const oauthError = createContainerDataFunction<boolean>('oauthError');
export const oauthFirstname = createContainerDataFunction<string>('oauthFirstname');
export const oauthLastname = createContainerDataFunction<string>('oauthLastname');
export const oauthProvider = createContainerDataFunction<string>('oauthProvider');
export const oauthType = createContainerDataFunction<string>('oauthType');
export const oauthToken = createContainerDataFunction<string>('oauthToken', { parse: false });
export const profile = createInitialStateFunction<{ _id: string; _verified: boolean; role: string }>('profile');
export const providerKey = createContainerDataFunction<string>('providerKey');
export const redirectUrl = createContainerDataFunction<string>('redirectUrl');
export const rulesOfBehaviorIntro = createContainerDataFunction<string>('rulesOfBehaviorIntro');
export const scope = createContainerDataFunction<string>('scope');
export const verifiedClient = createContainerDataFunction<boolean>('verifiedClient');

export { mockBootstrapData } from './mockBootstrapData';
