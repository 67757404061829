import { lazy, Suspense } from 'react';
import cx from 'clsx';

import { shouldShowUnauthenticatedPagePromo } from 'utils/unauthenticatedPage';

import styles from './styles/PageWithPromo.module.css';

const OrbitStars = lazy(
  async () => import(/* webpackChunkName: "OrbitStars" */ 'components/ui/layout/designs/OrbitStars'),
);

const LogoGrid = lazy(async () => import(/* webpackChunkName: "LogoGrid" */ 'components/ui/layout/designs/LogoGrid'));

const FlagControlledPromoContainer = lazy(
  async () =>
    import(/* webpackChunkName: "FlagControlledPromoContainer" */ 'components/Promo/FlagControlledPromoContainer'),
);

const SpringSignupPage = lazy(async () =>
  import(
    /* webpackChunkName: "SpringSignupPage" */ 'components/Promo/promotions/SpringSignupPage/SpringSignupPage'
  ).then((module) => ({
    default: module.SpringSignupPage,
  })),
);

type PageWithPromoProps = {
  className?: string;
  children?: React.ReactNode;
  promoType?: 'LogoGrid' | 'OrbitStars' | 'FlagControlled' | 'SignupAndJoinPagesOverhaul2024';
};

export function PageWithPromo({ className, children, promoType }: PageWithPromoProps) {
  const renderPromo = () => {
    switch (promoType) {
      case 'LogoGrid':
        return <LogoGrid />;
      case 'OrbitStars':
        return <OrbitStars />;
      case 'FlagControlled':
        return shouldShowUnauthenticatedPagePromo() && <FlagControlledPromoContainer />;
      case 'SignupAndJoinPagesOverhaul2024':
        return <SpringSignupPage />;
      default:
        return null;
    }
  };

  return (
    <div className={cx(styles.page, styles.dark, className)}>
      <>
        {children}
        <Suspense fallback={null}>{renderPromo()}</Suspense>
      </>
    </div>
  );
}
