// https://lea.verou.me/blog/2016/12/resolve-promises-externally-with-this-one-weird-trick/
/**
 *
 * @returns A promise that can be resolved or rejected from outside
 */
export function createDeferred<Data>() {
  let resolve!: (value: Data | PromiseLike<Data>) => void;
  let reject!: (reason?: unknown) => void;

  const promise = new Promise<Data>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return {
    promise,
    resolve,
    reject,
  };
}
