import { useEffect, useLayoutEffect, useRef } from 'react';

export function usePageVisibility({ callback }: { callback: (visibilityState: DocumentVisibilityState) => void }) {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (callbackRef.current) {
        callbackRef.current(document.visibilityState);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
}
