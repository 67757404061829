import { type GetAllReleaseProgressionsForReleasePipelineQueryParams } from '@gonfalon/openapi';
import { InfiniteData, QueryFunction, QueryKey, UseInfiniteQueryOptions } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { createInfiniteQueryHook } from './internal/createInfiniteQueryHook';
import { releasePipelines } from './internal/queries';

export const usePaginatedReleaseProgressions = createInfiniteQueryHook(
  ({
    projectKey,
    releasePipelineKey,
    params = {},
  }: {
    projectKey: string;
    releasePipelineKey: string;
    params?: GetAllReleaseProgressionsForReleasePipelineQueryParams;
  }) => {
    return withInfiniteQueryProps(
      releasePipelines.detail({ projectKey, releasePipelineKey })._ctx.releaseProgressions({ params })._ctx.paginate,
      params,
    );
  },
);

/**
 * The "query-key-factory" package will only preserve the "queryKey" and "queryFn" props provided.
 * Infinite queries need additional properties to function, which help determine whether a next/previous
 * page can be fetched and how to do it. This function adds those props to include this logic,
 * based upon our standard { limit, offset } pagination parameters.
 *
 * If it becomes clear that we want every infinite query to use this same logic,
 * we could move this within "createInfiniteQueryHook" itself.
 */
function withInfiniteQueryProps<T>(query: T, params: { limit?: number } = {}) {
  type Key = T extends { queryKey: infer K } ? (K extends QueryKey ? K : never) : never;
  type Data = T extends { queryFn: QueryFunction<infer D, Key> } ? D : never;
  const limit = getInfiniteQueryDefaultLimit(params);

  const options: UseInfiniteQueryOptions<Data, unknown, InfiniteData<Data, number>, Data, Key, number> = {
    ...(query as { queryKey: Key; queryFn: QueryFunction<Data, Key, number> }),
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      invariant(
        lastPage && typeof lastPage === 'object' && 'items' in lastPage && Array.isArray(lastPage.items),
        'expected page to have "items" array',
      );
      return lastPage.items.length < limit ? undefined : lastPageParam + limit;
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      return firstPageParam === 0 ? undefined : Math.max(firstPageParam - limit, 0);
    },
    initialPageParam: 0,
  };

  return options;
}

function getInfiniteQueryDefaultLimit({ limit = 20 }: { limit?: number } = {}) {
  return limit;
}
