import { ReactNode, useState } from 'react';
import { isReactQueryDevtoolsEnabled } from '@gonfalon/dogfood-flags';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { type PersistQueryClientOptions, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import { getQueryClient } from './getQueryClient';

// Renders the react-query icon in the bottom left of your browser.
// Clicking it opens the dev tools so you can inspect your cache and queries.
// https://react-query.tanstack.com/devtools
const renderDevTools = () => {
  if (__DEV__ && isReactQueryDevtoolsEnabled()) {
    return <ReactQueryDevtools initialIsOpen={false} />;
  }

  return null;
};

const getPersistOptions = () =>
  ({
    persister: createSyncStoragePersister({
      storage: window.localStorage,
    }),
    dehydrateOptions: {
      // https://github.com/TanStack/query/discussions/3568
      shouldDehydrateQuery: (query) => {
        if (
          query.state.status === 'success' &&
          (query.queryKey.includes('migration-metrics') || query.queryKey.includes('shortcuts'))
        ) {
          return true;
        }
        return false;
      },
    },
  }) satisfies Omit<PersistQueryClientOptions, 'queryClient'>;

export function ReactQueryClientProvider({ children }: { children: ReactNode }) {
  const client = getQueryClient();
  const [persistOptions] = useState(getPersistOptions);

  return (
    <PersistQueryClientProvider client={client} persistOptions={persistOptions}>
      {children}
      {renderDevTools()}
    </PersistQueryClientProvider>
  );
}
