import { type ReactNode } from 'react';
import { Slot } from '@radix-ui/react-slot';

import styles from './TextTruncator.module.css';

type Props = { asChild?: boolean; children?: ReactNode };

export function TextTruncator({ asChild, ...props }: Props) {
  const Comp = asChild ? Slot : 'span';
  return <Comp {...props} className={styles.truncate} />;
}
