import { createFlagImportConfiguration } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { flagImport } from './internal/queries';

export const useCreateFlagImportIntegration = createMutationHook(createFlagImportConfiguration, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries(flagImport.list);
  },
});
