import type { FetchResponse } from 'openapi-fetch';

import { getAPIClientConfig } from './apiClientConfig';
import { NetworkError, RESTAPIError } from './error';

function hasCode(error: unknown): error is { code: string } {
  return (
    error !== undefined &&
    error !== null &&
    typeof error === 'object' &&
    'code' in error &&
    typeof error.code === 'string' &&
    error.code !== ''
  );
}

function hasMessage(error: unknown): error is { message: string } {
  return (
    error !== undefined &&
    error !== null &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string' &&
    error.message !== ''
  );
}

export async function reactQueryResponseAdapter<T, O, M extends `${string}/${string}`>(
  promise: Promise<FetchResponse<T, O, M>>,
) {
  const config = getAPIClientConfig();

  let fetchResponse: FetchResponse<T, O, M>;
  try {
    fetchResponse = await promise;
  } catch (error) {
    const networkError = new NetworkError({
      message: hasMessage(error) ? error.message : 'Unknown network error',
      cause: error,
    });
    if (config.onError) {
      config.onError(networkError);
    }
    throw networkError;
  }

  const { data, error, response } = fetchResponse;

  if (data) {
    return data;
  }

  const code = hasCode(error) ? error.code : 'unknown_error';
  const message = hasMessage(error) ? error.message : response.statusText;

  const clientError = new RESTAPIError({
    status: response.status,
    url: response.url,
    code,
    message,
    cause: error,
  });

  if (config.onError) {
    config.onError(clientError);
  }

  throw clientError;
}
