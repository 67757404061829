import { useEffect, useState } from 'react';

// This hook allows you to debounce quick-updating strings in your UI.
// Combine it with a useEffect in your component to reduce API queries.
// Look below the function to see an example.
export const useDebouncedString = (term: string, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(term);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(term);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [term, delay]);
  return debouncedValue;
};

/*
  -----------------------------------------------------------------------------
  Example usage:
  -----------------------------------------------------------------------------

  Oh hi. You've stumbled on some sweet documentation to help explain this
  hook. We don't have a lot of this kind of documentation in Gonfalon, but …
  we should! It gets stripped out during the build process, so it's here for
  YOU as you learn how this works. This summary is written to be easily
  ingested, and shouldn't take more than five minutes to understand what this
  hook does and how you can use it in your component.

  You're reading this because you have a function that runs every time a string
  is updated in the UI … perhaps an input field that then triggers an API call.
  You don't want to call the API every time the string changes, so you're
  looking for a way to cut down how frequently you hit the backend. Nice!
  You're in the right spot.

  Here are some assumptions I'm going to make about how your code is set up.
  • You have an input with some sort of onChange that calls a handler function.
  • You have a useState where you're storing the updated value … something like
    `const [searchValue, setSearchValue] = useState('');`.
  • Your handler function updates the useState value, with something like this:
    `setSearchValue(event.target.value)`
  • You also have a useEffect that keeps an eye on the searchValue, and when
    searchValue updates, calls your API / fetching function. It looks like …
    ```
    useEffect(() => {
      fetchDataFromTheBackend(searchValue);
    }, [searchValue]);
    ```

  So how does useDebouncedString help? How can you, dear reader, use it?

  The first thing you'll do is add a new const to your code that calls the
  useDebouncedString hook and passes in your useState value. Something like:
  `const debouncedSearchValue = useDebouncedString(searchValue);`
                                                      ^ 👀
                                              oh look it's your useState value

  The second thing you'll do is update your useEffect block, replacing
  instances of `searchValue` with `debouncedSearchValue`. It'll look like this:
  ```
  useEffect(() => {
    fetchDataFromTheBackend(debouncedSearchValue);
  }, [debouncedSearchValue]);
  ```

  That's it! So what's going on there? Every time `searchValue` gets updated by
  the input field's handler function it kicks off a request to the
  useDebouncedString hook, sending along the searchValue string and a `delay`
  value. (`delay` defaults to 500ms.)

  If it's been more than `delay` milliseconds, the useDebouncedString
  hook will update the value it stores in it's *own* useState. If it hasn't
  been `delay` milliseconds, it'll wait until that time has passed and will
  then update it.

  Then … back in your function, since useEffect only ever updates when
  `debouncedSearchValue` updates (which happens, at most, every `delay`
  milliseconds), your useEffect function will calm down a bit.
*/
