import { putSelectedEnvironments } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { queries } from './internal/queries';

export const useUpdateSelectedEnvironments = createMutationHook(putSelectedEnvironments, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: queries['selected-environments']._def });
  },
});
