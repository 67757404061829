import { ResourceSpecifier } from './ast';

export const EOF = '\0';

export type EOF = typeof EOF;

export type RoleAttribute = {
  type: ResourceSpecifier['type'];
  attribute: string;
};
