import { deleteSegment } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { segments } from './internal/queries';

export const useDeleteSegment = createMutationHook(deleteSegment, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: segments.list._def });
  },
});
