import { DefaultError, useMutation, UseMutationOptions } from '@tanstack/react-query';
import { FetchResponse } from 'openapi-fetch';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

/**
 * Create a mutation hook for a given mutator function and optional mutation options.
 *
 * The mutator function should be a generated OpenAPI function from `@gonfalon/openapi`.
 *
 * Note that the resulting hook does not accept `useMutation` options. This enforces a
 * healthy separation of concerns:
 * - The package owns cache updates as a result of mutations. Use mutation callbacks
 *   when creating your hook.
 * - Consumers of the package may also provide mutation callbacks via the `mutate`
 *   function (returned by `useMutation`) to take UI-related actions, such as showing
 *   a toast or snackbar on success or failure.
 */
export function createMutationHook<
  T,
  O,
  M extends `${string}/${string}`,
  Input = void,
  Context = unknown,
  Error = DefaultError,
>(
  mutator: (input: Input) => Promise<FetchResponse<T, O, M>>,
  options: Omit<
    UseMutationOptions<NonNullable<FetchResponse<T, O, M>['data']>, Error, Input, Context>,
    'mutationFn'
  > = {},
) {
  return function useMutationHook() {
    return useMutation({
      mutationFn: async (input: Input) => reactQueryResponseAdapter(mutator(input)),
      ...options,
    });
  };
}
