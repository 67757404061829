import { postDomainVerification, schemas } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { ssoDomains } from './internal/queries';

type DomainVerificationCollectionRep = schemas['DomainVerificationCollectionRep'];

export const useCreateSSODomain = createMutationHook(postDomainVerification, {
  onMutate: async (newDomainInput) => {
    const queryClient = getQueryClient();
    await queryClient.cancelQueries({ queryKey: ['ssoDomains'] });

    const prevData = queryClient.getQueryData<DomainVerificationCollectionRep>(['ssoDomains']);

    queryClient.setQueryData<DomainVerificationCollectionRep>(['ssoDomains'], (oldData) => {
      if (oldData && oldData.items) {
        const { domain } = newDomainInput.body;

        return {
          ...oldData,
          // set a temporary ID so that the new domain shows up in the list immediately. Will return real ID once refetched on success.
          items: [...oldData.items, { domain, domainVerificationID: `tmp-id-${domain}`, isVerified: false }],
        };
      }

      return oldData;
    });

    return { prevData };
  },
  onError: (error, __, context) => {
    const queryClient = getQueryClient();
    queryClient.setQueryData(['ssoDomains'], (context as { prevData: DomainVerificationCollectionRep }).prevData);
  },
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: ssoDomains._def });
  },
});
