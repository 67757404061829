import { CSSProperties } from 'react';

import { LegacyIcon } from './LegacyIcon';
import type { DiamondProps } from './types';

export const Diamond = ({
  fill = 'var(--lp-color-gray-500)',
  stroke = '',
  displayOuterStroke = false,
  strokeWidth,
  className,
  size,
  children,
  ...other
}: DiamondProps) => {
  const styles: CSSProperties = { fill, stroke, strokeWidth: strokeWidth || (stroke ? 1 : 0) };
  const defaultPolygon = <polygon style={styles} points="0,16 16,32 32,16 16,0" />;

  const polygonWithOuterStroke = (
    <>
      <polygon fill={fill} strokeWidth={2} points="0,16 16,32 32,16 16,0" stroke="var(--lp-color-bg-ui-primary)" />
      <polygon style={styles} points="2, 16 16, 30 30, 16 16, 2" />
    </>
  );

  const renderText = () => {
    if (!children) {
      return null;
    }

    const textProps = {
      x: displayOuterStroke ? 6 : 4,
      y: 17,
      fontSize: displayOuterStroke ? 27 : 30,
    };

    return <text {...textProps}>{children}</text>;
  };

  return (
    <LegacyIcon {...other} size={size} className={className} name="marker" subtle={false}>
      <svg aria-hidden="true" focusable="false" role="img" x="0" y="0" width="32px" height="32px" viewBox="0 0 32 32">
        {displayOuterStroke ? <>{polygonWithOuterStroke}</> : <>{defaultPolygon}</>}
        {renderText()}
      </svg>
    </LegacyIcon>
  );
};
