import httpUtils, { jsonToImmutableError } from 'utils/httpUtils';

export async function resendUnverifiedMemberEmailVerification(email: string): Promise<void | Response> {
  return httpUtils.post('/internal/account/resend-verification', { body: { email } }).catch(jsonToImmutableError);
}

export async function resendChooseOrganizationEmailVerification(email: string): Promise<void | Response> {
  return httpUtils
    .post('/internal/unauthenticated-members/organization-verifications', { body: { email } })
    .catch(jsonToImmutableError);
}

export async function completeUnverifiedMemberSignUp(
  token: string,
  accountId?: string,
  referrer = '',
): Promise<void | Response> {
  return httpUtils
    .post(`/internal/account/signup/complete/${token}`, { body: { accountId, customReferer: referrer } })
    .catch(jsonToImmutableError);
}

/**
 * PATCH /unauthenticated-members/organization-verifications/{token}
 * 200 - success
 * 400 - invalid json or request
 * 401 - expired or invalid token
 * 404 - invalid token
 */
export async function verifyOrganizationAccessToken(token: string, body: { action: 'verify' | 'expire' }) {
  return httpUtils
    .patch(`/internal/unauthenticated-members/organization-verifications/${token}`, { body })
    .catch(async (resp) => jsonToImmutableError(resp));
}

export async function getTrustedDeviceTokenInfo(token: string) {
  return httpUtils
    .get(`/internal/unauthenticated-members/organization-verifications/${token}`)
    .then(async (res) => res.json())
    .catch(jsonToImmutableError);
}
