import { createLayer } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { layers } from './internal/queries';

export const useCreateLayer = createMutationHook(createLayer, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: layers.list._def });
  },
});
