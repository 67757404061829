/**
 * Remove a value from an array at the given index, and return
 * a new copy of the array.
 */
export function arrayRemove<T>(array: T[], index: number) {
  const copy = array.slice();

  copy.splice(index, 1);

  return copy;
}

/**
 * Remove an element from an array using a comparison function, and return
 * a new copy of the array.
 *
 * By default, strict equality is used. For objects, this means referential equality.
 */
export function arrayRemoveValue<T>(
  array: T[],
  value: T,
  { compare }: { compare: (t1: T, t2: T) => boolean } = { compare: (t1, t2) => t1 === t2 },
) {
  const copy = array.slice();

  const index = array.findIndex((item) => compare(item, value));

  copy.splice(index, 1);

  return copy;
}
