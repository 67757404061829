import { type GetMigrationFlagMetricsQueryParams } from '@gonfalon/openapi';

import { createQueryHook } from './internal/createQueryHook';
import { migrationMetrics } from './internal/queries';

export const useMigrationMetricsOverview = createQueryHook(
  ({
    projectKey,
    flagKey,
    environmentKey,
    params = {},
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    params?: Omit<GetMigrationFlagMetricsQueryParams, 'rules'>;
  }) => ({
    ...migrationMetrics.list({ projectKey, flagKey, environmentKey, params }),
    select: (data) => data.overview,
  }),
);
