import { type PostMigrationSafetyIssuesRequestBody } from '@gonfalon/openapi';

import { createQueryHook } from './internal/createQueryHook';
import { migrationSafetyIssues } from './internal/queries';

export const useMigrationSafetyIssues = createQueryHook(
  ({
    projectKey,
    flagKey,
    environmentKey,
    query,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    query: PostMigrationSafetyIssuesRequestBody;
  }) => migrationSafetyIssues.list({ projectKey, flagKey, environmentKey, query }),
);
