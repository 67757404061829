/*
 * Calls and awaits a promise-returning function and returns its resolution or
 * rejection in a two-tuple.
 *
 * This makes working with error cases in async functions much more ergonomic
 * by keeping both the resolution and rejection in the current scope.
 *
 * @example const [data, error] = await settle(() => getData());
 */
export async function settle<Result>(func: () => Promise<Result>): Promise<[Result, undefined] | [undefined, unknown]> {
  try {
    return [await func(), undefined];
  } catch (error) {
    return [undefined, error];
  }
}
