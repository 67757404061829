import { type GetProjectQueryParams, schemas } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { projectsDetail } from './internal/queries';

export function initializeProject({
  projectKey,
  params,
  project,
}: {
  projectKey: string;
  params?: GetProjectQueryParams;
  project: schemas['Project'];
}) {
  getQueryClient().setQueryData(projectsDetail({ projectKey, query: params }).queryKey, project);
}
