/* eslint-disable @typescript-eslint/no-explicit-any */

import { forOwn, isArray, isPlainObject, omitBy } from '@gonfalon/es6-utils';

/**
 * Construct a new collection from obj by deeply removing properties filtered out
 * by the given predicate.
 *
 * Note: This only works with plain JS structures.
 * @param  {Object|Array} obj Collection to cleanup
 * @param  {(v, k) => Boolean} filter Predicate to determine whether to omit a property
 * @return {Object|Array} A new collection with the ommitted properties
 */
export function omitDeep(
  obj: { [k: string]: any } | any[],
  filter: (v: any, k: any) => boolean,
): { [k: string]: any } | any[] {
  if (isArray(obj)) {
    return obj.map((v) => omitDeep(v, filter));
  } else if (isPlainObject(obj)) {
    const o: any = {};
    forOwn(obj, (v, k) => {
      o[k] = isPlainObject(v) || isArray(v) ? omitDeep(v, filter) : v;
    });
    return omitBy(o, filter);
  } else {
    return obj;
  }
}
