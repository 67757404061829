import { createSearchParams, Path, URLSearchParamsInit } from 'react-router-dom';

/**
 * Adds search params to a destination
 * @param searchParams anything that can be passed to URLSearchParams constructor
 * @returns updated destination
 */
export function withSearchParams(searchParams?: URLSearchParamsInit) {
  return (path: Path) => {
    if (!searchParams) {
      return path;
    }

    const nextSearchParams = new URLSearchParams(path.search);
    const additionalSearchParams = createSearchParams(searchParams);
    for (const [name, value] of additionalSearchParams.entries()) {
      if (nextSearchParams.has(name) && nextSearchParams.get(name) === value) {
        continue;
      }
      nextSearchParams.append(name, value);
    }
    return { ...path, search: nextSearchParams.toString() };
  };
}
