import { enableLoginV2 } from '@gonfalon/dogfood-flags';

import { useBeastMode, useFindLoginPage, useLogin, useLoginV2 } from 'actions/auth';
import { setTimeToLoginEndTime as setTimeToLoginEndTimeAction } from 'actions/timeToLoginMetric';
import withForm from 'components/withForm';
import { useDispatch } from 'hooks/useDispatch';
import { createLoginInfo, LoginInfo, validateLoginInfo, validateLoginInfoWithEmailOnly } from 'utils/loginUtils';

import LoginForm, { LoginFormProps } from './LoginForm';

const useRedux = () => {
  const dispatch = useDispatch();
  const setTimeToLoginEndTime = (endTime: number) => dispatch(setTimeToLoginEndTimeAction(endTime));
  return {
    setTimeToLoginEndTime,
  };
};

const WrappedLoginForm = withForm<LoginInfo>({
  initialState(props) {
    return createLoginInfo({ username: props.beastModeEmail || '' });
  },
  validate(record, props) {
    return props.requirePassword ? validateLoginInfo(record) : validateLoginInfoWithEmailOnly(record);
  },
  onSubmit(props, modified) {
    let final = modified;
    if (props.beastMode) {
      final = final.set('username', '');
      enableLoginV2()
        ? props.onBeastMode(final, props.redirect)
        : props.onLogin(final, props.redirect, props.beastMode);
      return;
    }
    if (props?.requirePassword) {
      props.setTimeToLoginEndTime(Date.now());
    }

    if (enableLoginV2() && !props?.requirePassword) {
      props.onGetLoginPage(final, props.redirect);
    } else if (enableLoginV2() && props.requirePassword) {
      props.onLoginV2(final, props.redirect);
    } else {
      props.onLogin(final, props.redirect, props.beastMode);
    }
  },
})(LoginForm);

/* eslint-disable import/no-default-export */
export default function LoginFormContainer(props: LoginFormProps) {
  const onLogin = useLogin();
  const onBeastMode = useBeastMode();
  const handleGetLoginPage = useFindLoginPage();
  const onLoginV2 = useLoginV2();
  const { setTimeToLoginEndTime } = useRedux();

  return (
    <WrappedLoginForm
      {...props}
      setTimeToLoginEndTime={setTimeToLoginEndTime}
      onLogin={onLogin}
      onLoginV2={onLoginV2}
      onBeastMode={onBeastMode}
      onGetLoginPage={handleGetLoginPage}
    />
  );
}
