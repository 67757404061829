import { z } from 'zod';

import type { EnvironmentChangeConfirmation } from './types';

export const createEnvironmentConfirmationSchema = (
  environmentKey: string,
  changeConfirmation: EnvironmentChangeConfirmation,
) =>
  changeConfirmation.isConfirmationRequired
    ? z.object({
        confirmation: z.object({
          comment: changeConfirmation.isCommentRequired ? z.string().trim().min(1) : z.string().trim().optional(),
          verification: changeConfirmation.isVerificationRequired
            ? z.literal(environmentKey)
            : z.string().trim().optional(),
        }),
      })
    : z.object({
        confirmation: z
          .object({
            comment: changeConfirmation.isCommentRequired ? z.string().trim().min(1) : z.string().trim().optional(),
            verification: changeConfirmation.isVerificationRequired
              ? z.literal(environmentKey)
              : z.string().trim().optional(),
          })
          .optional(),
      });
