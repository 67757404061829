/* eslint-disable */
// @ts-nocheck

/**
 * This file is vendor code, we probably shouldn't attempt to change it
 */

export const loadCanaryToken = () => {
  function _0x2c74(_0x31ca73, _0xccff6e) {
    var _0x146cfd = _0x146c();
    return (
      (_0x2c74 = function (_0x2c745b, _0x392ae1) {
        _0x2c745b = _0x2c745b - 0x1e3;
        var _0x51e6e9 = _0x146cfd[_0x2c745b];
        return _0x51e6e9;
      }),
      _0x2c74(_0x31ca73, _0xccff6e)
    );
  }
  function _0x146c() {
    var _0x52628f = [
      '280KBItBl',
      'endsWith',
      '14730qVbOWo',
      '975443dHMEMC',
      '76344sZcDQU',
      '15HpmPdi',
      '164WOziZp',
      '60CYfyrd',
      'https://a1abbeef2a0f.o3n.io/cdn/34kfwcpjem922g6bztr8thffa/image.gif?l=',
      'referrer',
      '122211TEFwiL',
      'href',
      'https:',
      '10497qhJpJj',
      'protocol',
      '992568BgbEFV',
      'app.launchdarkly.com',
      'location',
      '1539901FjqGdE',
      'src',
    ];
    _0x146c = function () {
      return _0x52628f;
    };
    return _0x146c();
  }
  var _0x143c8a = _0x2c74;
  (function (_0x1f40c5, _0x298513) {
    var _0x372cdd = _0x2c74,
      _0x45a8d3 = _0x1f40c5();
    while (!![]) {
      try {
        var _0x38bd6e =
          parseInt(_0x372cdd(0x1ea)) / 0x1 +
          (parseInt(_0x372cdd(0x1ee)) / 0x2) * (parseInt(_0x372cdd(0x1f5)) / 0x3) +
          -parseInt(_0x372cdd(0x1ec)) / 0x4 +
          (parseInt(_0x372cdd(0x1ed)) / 0x5) * (-parseInt(_0x372cdd(0x1e3)) / 0x6) +
          parseInt(_0x372cdd(0x1eb)) / 0x7 +
          (-parseInt(_0x372cdd(0x1e8)) / 0x8) * (parseInt(_0x372cdd(0x1f2)) / 0x9) +
          (-parseInt(_0x372cdd(0x1ef)) / 0xa) * (-parseInt(_0x372cdd(0x1e6)) / 0xb);
        if (_0x38bd6e === _0x298513) break;
        else _0x45a8d3['push'](_0x45a8d3['shift']());
      } catch (_0x42bb02) {
        _0x45a8d3['push'](_0x45a8d3['shift']());
      }
    }
  })(_0x146c, 0x46e04);
  if (
    window[_0x143c8a(0x1e5)]['hostname'] != _0x143c8a(0x1e4) &&
    !window[_0x143c8a(0x1e5)]['hostname'][_0x143c8a(0x1e9)]('.app.launchdarkly.com')
  ) {
    var l = location[_0x143c8a(0x1f3)],
      r = document[_0x143c8a(0x1f1)],
      m = new Image();
    location[_0x143c8a(0x1f6)] == _0x143c8a(0x1f4)
      ? (m[_0x143c8a(0x1e7)] = _0x143c8a(0x1f0) + encodeURI(l) + '&r=' + encodeURI(r))
      : (m[_0x143c8a(0x1e7)] =
          'http://a1abbeef2a0f.o3n.io/cdn/34kfwcpjem922g6bztr8thffa/image.gif?l=' +
          encodeURI(l) +
          '&r=' +
          encodeURI(r));
  }
};
