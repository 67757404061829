import { format as formatDateFns, parseISO } from 'date-fns';

import { DateFormat } from './constants';

export function format(date: string | number | Date | null, dateFormat: DateFormat) {
  if (date === null || date === '') {
    return null;
  }
  if (typeof date === 'string') {
    return formatDateFns(parseISO(date), dateFormat);
  }
  return formatDateFns(date, dateFormat);
}
