import { type ProjectContext } from './projectContext';

export function projectContextToURLSearchParams(context: ProjectContext) {
  const params = new URLSearchParams();

  for (const environmentKey of context.environmentKeys) {
    params.append('env', environmentKey);
  }

  if (context.selectedEnvironmentKey) {
    params.set('selected-env', context.selectedEnvironmentKey);
  }

  return params;
}
