import { Member } from './types';

export function hasTeamCustomRoles(member: Member) {
  if (member.teams === undefined) {
    return false;
  }

  for (const team of member.teams) {
    if (team.customRoleKeys.length > 0) {
      return true;
    }
  }

  return false;
}
