import { createContext } from 'react';

import { Theme } from './types';

export type ThemeContext = {
  theme?: Theme;
  toggleTheme: () => void;
};

export const ThemeContext = createContext<ThemeContext>({
  theme: 'default',
  toggleTheme: () => undefined, // will get set in ThemeProvider
});
