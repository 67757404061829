import { List, Map, Record, Set } from 'immutable';

import { SdkVersionContextUsage } from 'components/SdkNotification/sdkNotificationUtils';
import { ApprovalRequestSummariesFilters } from 'utils/approvalsFilterUtils';
import { FlagFilters } from 'utils/flagFilterUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { Tasks } from 'utils/learningCenterDrawerUtils';
import { DismissedOverages, Overages } from 'utils/overagesUtils';
import { createWebStorage, WebStorage } from 'utils/storageUtils';

type StickyResourceFilters = ImmutableMap<{
  flags: FlagFilters;
  integrations: string;
}>;

type DimissedSDKPrompts = ImmutableMap<{
  [env: string]: boolean;
}>;

type SdkContextUsageMap = ImmutableMap<{
  [sdkName: string]: ImmutableMap<SdkVersionContextUsage>;
}>;

type SdkNotifications = ImmutableMap<{
  [env: string]: SdkContextUsageMap;
}>;

type LDRelayUsage = ImmutableMap<{
  [env: string]: ImmutableMap<{
    [timestamp: string]: boolean;
  }>;
}>;

type AccountOptionsType = {
  tzid: string;
  flagSortOrder: string;
  dismissedOverages: DismissedOverages | null;
  dismissedSDKPrompt: Map<string, DimissedSDKPrompts>;
  overages: Overages | null;
  verificationBannerDismissed: boolean;
  midTrialBannerDismissed: boolean;
  endTrialBannerDismissed: boolean;
  chatIntegrationPromoCardDismissed: boolean;
  openSeatNotificationDimissed: boolean;
  productionEnvironments: Map<string, List<string>>;
  showEmptyCoderefsRepositories: boolean;
  taxBannerDismissed: boolean;
  stickyFilters: Map<string, StickyResourceFilters>;
  enterpriseCampaignStartedModalDismissed: boolean;
  enterpriseCampaignEndedModalDismissed: boolean;
  onboardingTasks: Tasks | null;
  approvalsFilters: ImmutableMap<ApprovalRequestSummariesFilters>;
  contextAttributeColumns: Map<string, Set<string>>;
  sdkNotifications: Map<string, SdkNotifications>;
  ldRelayUsage: Map<string, LDRelayUsage>;
};

class AccountOptions extends Record<AccountOptionsType>({
  tzid: '',
  flagSortOrder: '',
  dismissedOverages: null,
  dismissedSDKPrompt: Map(),
  overages: null,
  verificationBannerDismissed: false,
  midTrialBannerDismissed: false,
  endTrialBannerDismissed: false,
  chatIntegrationPromoCardDismissed: false,
  productionEnvironments: Map(),
  openSeatNotificationDimissed: false,
  showEmptyCoderefsRepositories: false,
  stickyFilters: Map(),
  taxBannerDismissed: false,
  enterpriseCampaignStartedModalDismissed: false,
  enterpriseCampaignEndedModalDismissed: false,
  onboardingTasks: null,
  approvalsFilters: Map(),
  contextAttributeColumns: Map(),
  sdkNotifications: Map(),
  ldRelayUsage: Map(),
}) {}

let storage: WebStorage<AccountOptions>;
const storageKeyDeprecated = 'ld-account';
const getStorageKeyForAccount = (accountId: string) => `ld-account-${accountId}`;
const defaultValue = new AccountOptions();

export function save(data: AccountOptions) {
  if (!storage) {
    throw new Error('AccountLocalStorage must be initialized with an account ID');
  }
  return storage.save(data);
}

export function clear() {
  if (!storage) {
    throw new Error('AccountLocalStorage must be initialized with an account ID');
  }
  storage.clear();
}

export function load() {
  if (!storage) {
    throw new Error('AccountLocalStorage must be initialized with an account ID');
  }

  const data = storage.load(defaultValue);

  return new AccountOptions(data);
}

export function init(accountId: string) {
  storage = createWebStorage<AccountOptions>(getStorageKeyForAccount(accountId));

  // minor: carry over previous state just so we don't surprise
  // anyone who already has local preferences and a single account.
  const unscopedStorage = createWebStorage<AccountOptions>(storageKeyDeprecated);
  const oldState = unscopedStorage.load();

  if (oldState) {
    storage.save(oldState);
    unscopedStorage.clear();
  }
}
