import { deleteApplication } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { applications } from './internal/queries';

export const useDeleteApplication = createMutationHook(deleteApplication, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: applications.list._def });
  },
});
