import { Path } from 'react-router-dom';
import { baseUri } from '@gonfalon/constants';

/**
 * Convert a destination path an href suitable for window.location.href
 * @param path
 * @returns string
 */
export function toHref(path: Path, { absolute = false }: { absolute?: boolean } = {}) {
  const base = baseUri();

  const { pathname, search, hash } = path;
  const url = new URL(pathname, base);

  if (search) {
    url.search = search;
  }

  if (hash) {
    url.hash = hash;
  }

  return absolute ? url.toString() : url.toString().replace(base, '');
}
