import invariant from 'tiny-invariant';

import { NetworkError, RESTAPIError } from './error';

export interface APIClientConfig {
  onError?: (error: RESTAPIError | NetworkError) => void;
}

let config: APIClientConfig;

export function initializeAPIClientConfig(
  { onError }: APIClientConfig = {},
  { validate = true }: { validate?: boolean } = {},
) {
  if (validate) {
    invariant(config === undefined, 'API client already initialized');
  }
  config = { onError };
}

export function getAPIClientConfig() {
  invariant(config !== undefined, 'API client is not initialized');
  return config;
}
