/* eslint-disable no-console */
// eslint-disable-next-line no-restricted-imports
import { Collection, fromJS, Record } from 'immutable';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyImmutableObject = Collection<any, any> | Record<any>;

function serialize(data: AnyImmutableObject) {
  return JSON.stringify(data.toJS());
}

function deserialize(data: string) {
  try {
    return fromJS(JSON.parse(data));
  } catch (error) {
    if (__DEV__ && !__TEST__) {
      // TODO: Investigate why using our logger here causes our logUtils-tests to fail
      console.error('Failed to parse JSON from local storage', error);
    }
    return {};
  }
}

export type WebStorage<T> = {
  load(defaultValue?: T): T;
  save(data: T): T | undefined;
  clear(): void;
};

export function createWebStorage<T extends AnyImmutableObject>(key: string): WebStorage<T> {
  const storage = window.localStorage;

  return {
    load(defaultValue?: T) {
      try {
        const data = storage.getItem(key);
        return data !== null ? deserialize(data) : defaultValue;
      } catch (e) {
        // continue regardless of error
      }
    },

    save(data: T) {
      try {
        storage.setItem(key, serialize(data));
        return data;
      } catch (e) {
        // continue regardless of error
      }
    },

    clear() {
      try {
        storage.removeItem(key);
      } catch (e) {
        // continue regardless of error
      }
    },
  };
}
