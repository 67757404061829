import { DEFAULT_LOCALE } from './constants';
import { hasDecimal } from './hasDecimal';

export function formatCurrency(value: number) {
  let options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  if (hasDecimal(value)) {
    options = { ...options, minimumFractionDigits: 2, maximumFractionDigits: 2 };
  }

  return value.toLocaleString(DEFAULT_LOCALE, options);
}
