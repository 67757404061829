import { type GetMetricGroupQueryParams } from '@gonfalon/openapi';

import { createSuspenseQueryHook } from './internal/createSuspenseQueryHook';
import { metricGroups } from './internal/queries';

export const useSuspenseMetricGroup = createSuspenseQueryHook(
  ({
    projectKey,
    metricGroupKey,
    params,
  }: {
    projectKey: string;
    metricGroupKey: string;
    params?: GetMetricGroupQueryParams;
  }) => metricGroups.detail({ projectKey, metricGroupKey, params }),
);
