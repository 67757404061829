import { type GetSegmentsQueryParams } from '@gonfalon/openapi';

import { createQueryFetcher } from './internal/createQueryFetcher';
import { segments } from './internal/queries';

export const fetchSegments = createQueryFetcher(
  ({
    projectKey,
    params,
    environmentKey,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetSegmentsQueryParams;
  }) => segments.list({ projectKey, environmentKey, params }),
);
