// eslint-disable-next-line no-restricted-imports
import { fromJS } from 'immutable';

import { TimeToLoginMetricAction } from 'actions/timeToLoginMetric';
import { GlobalState } from 'reducers';
import { TimeToLoginMetric, trackSignInDuration, trackSignInUIFlowComplete } from 'utils/loginUtils';

import registry from './registry';

export const selectDuration = (state: GlobalState) => state.timeToLoginMetric.get('duration');
export const selectTimeToLoginMetrics = (state: GlobalState) => state.timeToLoginMetric;

const initialState: TimeToLoginMetric = {
  startTime: undefined,
  endTime: undefined,
  duration: undefined,
  type: 'Password',
};
export const timeToLoginMetric = (state = fromJS(initialState), action: TimeToLoginMetricAction) => {
  switch (action.type) {
    case 'timeToLoginMetric/RESET':
      return state.set(initialState);
    case 'timeToLoginMetric/SET_START':
      return state.merge({
        startTime: action?.payload,
      });
    case 'timeToLoginMetric/SET_END':
      const duration = action?.payload - state?.get('startTime');

      const newState = state.merge({
        endTime: action?.payload,
        duration,
      });
      trackSignInUIFlowComplete(newState.get('type'));
      trackSignInDuration(newState.toJS());
      return newState;
    case 'timeToLoginMetric/SET_TYPE':
      if (state.get('type') === 'Multi-account member SSO') {
        // Since the login hook is called for both Multi-account member SSO and regular SSO, we only want to set the type once so that
        // we don't override the Multi-account member SSO type with the regular SSO type.
        return state;
      } else {
        return state.merge({
          type: action?.payload,
        });
      }

    default:
      return state;
  }
};

registry.addReducers({
  timeToLoginMetric,
});
