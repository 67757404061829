// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { throwErrorsInRouterHooks } from '@gonfalon/dogfood-flags';
import { trackError } from '@gonfalon/telemetry';

/**
 * Type-safe function for reading the specified URL parameter.
 * Will throw error if the parameter is not present in the URL,
 * unless `optional` option is set to `true`.
 */
export function useParam(name: string, options?: { optional: false }): string;
export function useParam(name: string, options: { optional: boolean }): string | undefined;
export function useParam(name: string, options?: { optional: boolean }): string | undefined {
  const shouldThrowError = throwErrorsInRouterHooks();
  const param = useParams()[name];

  if (param) {
    return param;
  }

  if (!options?.optional) {
    const error = new Error(`Parameter "${name}" not present in URL`);

    if (shouldThrowError) {
      throw error;
    } else {
      trackError(error);
    }
  }
}
