import { InfiniteData, QueryKey as RQQueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';

type SafeInfiniteQueryOptions<QueryFnData, Error, Data, QueryData, QueryKey extends RQQueryKey, PageParam> = Omit<
  UseInfiniteQueryOptions<QueryFnData, Error, Data, QueryData, QueryKey, PageParam>,
  'queryKey' | 'queryFn' | 'queryKeyHashFn'
>;

export function createInfiniteQueryHook<
  Dependencies,
  QueryFnData = unknown,
  Error = unknown,
  QueryKey extends RQQueryKey = RQQueryKey,
  PageParam = unknown,
>(
  queryFactory: (
    deps: Dependencies,
  ) => UseInfiniteQueryOptions<
    QueryFnData,
    Error,
    InfiniteData<QueryFnData, PageParam>,
    QueryFnData,
    QueryKey,
    PageParam
  >,
) {
  function useCustomHook(
    deps: Dependencies,
    options?: SafeInfiniteQueryOptions<
      QueryFnData,
      Error,
      InfiniteData<QueryFnData, PageParam>,
      QueryFnData,
      QueryKey,
      PageParam
    >,
  ) {
    return useInfiniteQuery({ ...options, ...queryFactory(deps) });
  }

  return useCustomHook;
}
