import { GetProjectsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetProjectsQueryParams = Omit<GetProjectsOpenAPIQueryParams, 'filter' | 'sort' | 'expand'> & {
  sort?: 'name' | '-name' | 'createdOn' | '-createdOn';
  expand?: Array<'environments'>;
  filter?: {
    query?: string;
    keys?: string[];
    tags?: string[];
  };
};

export function querySerializer(queryParams: GetProjectsQueryParams) {
  const searchParams = new URLSearchParams();

  if ((queryParams.limit ?? 0) > 0) {
    searchParams.set('limit', String(queryParams.limit));
  }

  if ((queryParams.offset ?? 0) > 0) {
    searchParams.set('offset', String(queryParams.offset));
  }

  if (queryParams.sort) {
    searchParams.set('sort', queryParams.sort);
  }

  if (queryParams.expand && queryParams.expand.length > 0) {
    searchParams.set('expand', queryParams.expand.join(','));
  }

  if (queryParams.filter) {
    const serializedFilter = serializeProjectFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  return searchParams.toString();
}

function serializeProjectFilterParam(value: NonNullable<GetProjectsQueryParams['filter']>) {
  const filter: string[] = [];

  if (value?.query) {
    filter.push(`query:${value.query}`);
  }

  if (value?.tags) {
    filter.push(`tags:${value.tags.map(encodeURIComponent).join('+')}`);
  }

  if (value?.keys) {
    filter.push(`keys:${value.keys.map(encodeURIComponent).join('|')}`);
  }

  filter.sort();

  return filter.join(',');
}
