import {
  oauthAction,
  oauthEmail,
  oauthError,
  oauthFirstname,
  oauthLastname,
  oauthProvider,
  oauthToken,
} from '@gonfalon/bootstrap-data';
import { googleOauthClientId } from '@gonfalon/constants';
import { isGithubOAuthSignUpEnabled, isGoogleOAuthSignUpEnabled } from '@gonfalon/dogfood-flags';

export function getOAuthData() {
  return {
    error: oauthError(),
    token: String(oauthToken()),
    firstName: oauthFirstname(),
    lastName: oauthLastname(),
    email: oauthEmail(),
    action: oauthAction(),
    provider: oauthProvider(),
  };
}

export function shouldEnableGoogleOAuthSignUp() {
  if (isGoogleOAuthSignUpEnabled() && googleOauthClientId()) {
    return true;
  }
  return false;
}

export function shouldEnableGithubOAuthSignUp(): boolean {
  return isGithubOAuthSignUpEnabled();
}
