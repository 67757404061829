import { z } from 'zod';

const pathSchema = z.object({
  pathname: z.string(),
  search: z.string(),
  hash: z.string(),
});

export type Path = z.infer<typeof pathSchema>;

export function isPath(value: unknown): value is Path {
  return pathSchema.safeParse(value).success;
}
