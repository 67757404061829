import { currentEnvironment as _currentEnvironment, currentProject as _currentProject } from '@gonfalon/bootstrap-data';
import { Map } from 'immutable';

import type { ImmutableServerError } from 'utils/httpUtils';
import type { ImmutableMap } from 'utils/immutableUtils';
import { type Project, createProject } from 'utils/projectUtils';

export type ProjectEntities = Map<string, Project>;

export type ProjectsState = ImmutableMap<{
  lastFetched?: number;
  isFetching: boolean;
  entities: ProjectEntities;
  doNotFetch: boolean;
  error?: ImmutableServerError;
}>;

export function getProjectsInitialState(
  initialState: {
    currentProject: ReturnType<typeof _currentProject>;
    currentEnvironment: ReturnType<typeof _currentEnvironment>;
  } = { currentProject: _currentProject(), currentEnvironment: _currentEnvironment() },
) {
  const state: ProjectsState = Map({
    lastFetched: null,
    isFetching: false,
    entities: Map(),
    doNotFetch: false,
    error: null,
  });

  const currentProject = initialState.currentProject;
  const bootstrappedCurrentEnvironment = initialState.currentEnvironment;

  if (currentProject) {
    let p = createProject(
      // @ts-expect-error the project immutable record incorrectly assumes `environments` exists on the rep
      currentProject,
    );

    if (bootstrappedCurrentEnvironment) {
      p = p.update('environments', (envs) => envs.push(bootstrappedCurrentEnvironment._id));
    }

    return state.withMutations((st) =>
      st.merge({
        entities: Map({
          [p.key]: p,
        }),
      }),
    );
  }

  return state;
}
