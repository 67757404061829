import { internalGetProgressiveRollouts } from '@gonfalon/openapi';

import { createQueryOptions } from './internal/createQueryOptions';
import {
  internalPostProgressiveRolloutsStatusTaskRunner,
  ProgressiveRolloutStatusTask,
} from './internal/internalPostProgressiveRolloutsStatusTaskRunner';
import { makeQueryId } from './internal/makeQueryId';

export const getProgressiveRolloutsQuery = createQueryOptions(internalGetProgressiveRollouts);

async function getProgressiveRolloutStatus(props: ProgressiveRolloutStatusTask) {
  return internalPostProgressiveRolloutsStatusTaskRunner.run(props);
}

const getProgressiveRolloutStatusQueryKey = makeQueryId(getProgressiveRolloutStatus.name);
export const getProgressiveRolloutStatusQuery = (args: ProgressiveRolloutStatusTask) => ({
  queryKey: [{ type: getProgressiveRolloutStatusQueryKey, ...args }] as const,
  queryFn: async () => getProgressiveRolloutStatus(args),
});
