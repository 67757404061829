import { type GetSegmentsQueryParams } from '@gonfalon/openapi';

import { createQueryHook } from './internal/createQueryHook';
import { segments } from './internal/queries';

export const useSegments = createQueryHook(
  ({
    projectKey,
    environmentKey,
    params,
    batch,
  }: {
    projectKey: string;
    environmentKey: string;
    params?: GetSegmentsQueryParams;
    batch?: boolean;
  }) => segments.list({ projectKey, environmentKey, params, batch }),
);
