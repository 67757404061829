import { createSuspenseQueryHook } from './internal/createSuspenseQueryHook';
import { segments } from './internal/queries';

export const useSuspenseSegment = createSuspenseQueryHook(
  ({
    projectKey,
    environmentKey,
    segmentKey,
    shouldIgnoreErrors,
  }: {
    projectKey: string;
    environmentKey: string;
    segmentKey: string;
    shouldIgnoreErrors?: boolean;
  }) => segments.detail({ projectKey, environmentKey, segmentKey, shouldIgnoreErrors }),
);
