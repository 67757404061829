import { transformKeyConvention } from './patterns';
import { stripEmojisFromString } from './stripEmojisFromString';

/*
  Used to auto-convert entity names to valid key conventions

  camelCase, PascalCase, snake_case, kebab-case, and apending prefix
 */

export const transformmKey = (input: string, pattern?: string | undefined, prefix?: string) => {
  const transformKey = transformKeyConvention(stripEmojisFromString(input), pattern, prefix);
  if (prefix && pattern === 'snakeCase') {
    return prefix.concat('_', transformKey);
  } else if (prefix && pattern === 'kebabCase') {
    return prefix.concat('-', transformKey);
  } else if (prefix) {
    return prefix.concat('', transformKey);
  }
  return transformKey;
};
