import { postHoldout } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { holdouts } from './internal/queries';

export const useCreateHoldout = createMutationHook(postHoldout, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: holdouts.list._def });
  },
});
