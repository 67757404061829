import { Github } from 'components/ui/icons/integrationLogos';

import { OAuthButtonContainer } from '../OAuthButtonContainer';

import styles from './GitHubOAuthButton.module.css';

export const GitHubOAuthButton = () => {
  const href = 'oauth/github';
  return (
    <OAuthButtonContainer className={styles.container}>
      <a href={href}>
        <Github size="small" className={styles.icon} /> Continue with GitHub
      </a>
    </OAuthButtonContainer>
  );
};
