import { JSONValue } from '@gonfalon/types';
import { Err, Ok, Result } from 'ts-results';

export function safeJSONParse(input: string): Result<JSONValue, Error> {
  try {
    return Ok(JSON.parse(input));
  } catch (error) {
    if (error instanceof SyntaxError) {
      return Err(error);
    }

    return Err(new Error(`Failed to parse "${input}"`));
  }
}

export function safeJSONStringParse(input: string): Result<string, string> {
  const matchname = safeJSONParse(input);

  if (matchname.err) {
    return Err(input);
  }

  if (typeof matchname.val !== 'string') {
    return Err(input);
  }

  return Ok(matchname.val);
}
