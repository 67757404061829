export function createInitialStateFunction<T = unknown>(name: string): () => T | undefined {
  return () => {
    const value = window.__initial_state__[name];

    if (typeof value !== 'undefined') {
      return value as T;
    }

    return;
  };
}
