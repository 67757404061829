import { GetEnvironmentsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetEnvironmentsQueryParams = Omit<GetEnvironmentsOpenAPIQueryParams, 'key' | 'id'> & {
  key?: string[];
  id?: string[];
};

export function querySerializer(params: GetEnvironmentsQueryParams) {
  const searchParams = new URLSearchParams();

  if (params.q && params.q.length > 0) {
    searchParams.set('q', params.q);
  }

  if (params.key) {
    for (const key of params.key) {
      searchParams.append('key', key);
    }
  }

  if (params.id) {
    for (const id of params.id) {
      searchParams.append('id', id);
    }
  }

  return searchParams.toString();
}
