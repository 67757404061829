import { createQueryHook } from './internal/createQueryHook';
import { metricAnalytics } from './internal/queries';

type Props = {
  projectKey: string;
  environmentKey: string;
  analyticsQueryResultId: string;
};

export const useProductAnalyticQueryResult = createQueryHook(
  ({ projectKey, environmentKey, analyticsQueryResultId }: Props) =>
    metricAnalytics.queryResult({ projectKey, environmentKey, analyticsQueryResultId }),
);
