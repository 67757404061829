import { and, contains, equals, or, QueryFilter, queryfilterToString } from '@gonfalon/queryfilter';

import { GetApplicationsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface GetApplicationsQueryParams extends Omit<GetApplicationsOpenAPIQueryParams, 'sort' | 'filter'> {
  sort?: 'name' | '-name' | 'creationDate' | '-creationDate';
  filter?: {
    key?: string;
    name?: string;
    kind?: string;
    autoAdded?: boolean;
  };
}

export function querySerializer(params: GetApplicationsQueryParams) {
  const searchParams = new URLSearchParams();

  if ((params.limit ?? 0) > 0) {
    searchParams.set('limit', String(params.limit));
  }

  if ((params.offset ?? 0) > 0) {
    searchParams.set('offset', String(params.offset));
  }

  if (params.sort) {
    searchParams.set('sort', String(params.sort));
  }

  if (params.filter) {
    const serializedFilter = serializeApplicationFilterParam(params.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  return searchParams.toString();
}

function serializeApplicationFilterParam(value: NonNullable<GetApplicationsQueryParams['filter']>) {
  const matches: QueryFilter[] = [];
  const query: QueryFilter[] = [];

  if (value.key) {
    query.push(contains('key', [value.key]));
  }

  if (value.name) {
    query.push(contains('name', [value.name]));
  }

  if (value.kind) {
    matches.push(equals('kind', value.kind));
  }

  if (value.autoAdded) {
    matches.push(equals('autoAdded', value.autoAdded));
  }

  return query.length ? queryfilterToString(and([or(query), and(matches)])) : queryfilterToString(and(matches));
}
