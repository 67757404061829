import { schemas } from '@gonfalon/openapi';

export type Member = schemas['Member'];
export type Team = schemas['Team'];
export type MaintainerTeam = schemas['MaintainerTeam'];
export type MemberSummary = schemas['MemberSummary'];

type BulkMemberActionNotification = {
  errorsCount: number;
  memberCount: number;
  warningsCount: number;
};

export type { BulkMemberActionNotification };

export enum QuickstartStatus {
  COMPLETED = 'completed',
  SKIPPED = 'skipped',
}
