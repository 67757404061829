import { type GetEvaluationsUsageQueryParams } from '@gonfalon/openapi';

import { createQueryHook } from './internal/createQueryHook';
import { flags } from './internal/queries';

export const useFlagEvaluationsForEnvironment = createQueryHook(
  ({
    projectKey,
    flagKey,
    environmentKey,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    params?: GetEvaluationsUsageQueryParams;
  }) => flags.detail({ projectKey, flagKey })._ctx.evaluations({ environmentKey, flagEvaluationParams: params }),
);
