import { createQueryHook } from './internal/createQueryHook';
import { measuredRollouts } from './internal/queries';

export const useMeasuredRolloutStatus = createQueryHook(
  ({ projectKey, environmentKey, flagKey }: { projectKey: string; environmentKey: string; flagKey: string }) =>
    measuredRollouts.measuredRolloutStatus({
      projectKey,
      environmentKey,
      flagKey,
    }),
);
