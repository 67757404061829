import { isBoolean } from '@gonfalon/es6-utils';
import { Err, Ok, Result } from 'ts-results';

import {
  after,
  and,
  anyOf,
  before,
  canonicalOperator,
  contains,
  empty,
  equals,
  exists,
  flatten,
  gte,
  isArrayValue,
  isDateValue,
  isNumberValue,
  isStringValue,
  isValidOperator,
  lte,
  Match,
  notEquals,
  or,
  parenthesized,
  Queryfilter,
  startsWith,
} from './builder';
import { safeJSONParse, safeJSONStringParse } from './json';
import { Lexer } from './lexer';
import { Token, TokenType } from './token';

type Context = {
  input: string;
  failedToken?: Token;
  expectedTokenType?: TokenType;
  previousToken?: Token;
};

export class ParserError extends Error {
  name = 'ParserError';
  failedToken?: Token;
  expectedType?: TokenType;
  context?: Context;

  constructor(message: string, context?: Context) {
    super(message);
    this.message = message;
    this.context = context;
  }
}

export class SyntaxError extends ParserError {
  name = 'SyntaxError';
}

const enum Precedence {
  LOWEST,
  LOGICAL, // and(,) or(|)
}

const precedences: { [key in Token['type']]?: Precedence } = {
  and: Precedence.LOGICAL,
  or: Precedence.LOGICAL,
};

type PrefixParseFunction = () => Result<Queryfilter, ParserError>;
type InfixParseFunction = (left: Queryfilter) => Result<Queryfilter, ParserError>;

export class Parser {
  private readonly lexer: Lexer;

  // @ts-expect-error initialized via this.nextToken in constructor
  private currentToken: Token;
  // @ts-expect-error initialized via this.nextToken in constructor
  private peekToken: Token;

  private readonly prefixParseFunctions: Map<Token['type'], PrefixParseFunction>;
  private readonly infixParseFunctions: Map<Token['type'], InfixParseFunction>;

  constructor(lexer: Lexer) {
    this.lexer = lexer;

    // Initialize the current and peek tokens
    this.nextToken();
    this.nextToken();

    this.prefixParseFunctions = new Map();
    this.registerPrefix('matchname', this.parseMatchExpression);
    this.registerPrefix('openparen', this.parseGroupExpression);

    this.registerPrefix('illegal', this.parseIllegalToken);
    this.registerPrefix('eof', this.parseEOFToken);

    this.infixParseFunctions = new Map();
    this.registerInfix('and', this.parseLogicalExpression);
    this.registerInfix('or', this.parseLogicalExpression);
  }

  public parse(): Result<Queryfilter, ParserError> {
    const node = this.parseExpression(Precedence.LOWEST);
    if (node.err) {
      return node;
    }

    return Ok(flatten(node.val));
  }

  private parseExpression(precedence: Precedence): Result<Queryfilter, ParserError> {
    const prefix = this.prefixParseFunctions.get(this.currentToken.type);
    if (prefix === undefined) {
      // If we don't have a prefix function for a known token it means
      // that token is only relevant in combination with another token.
      // If we end up in a position where it appears without its "partner"
      // we ignore it.
      this.nextToken();
      return this.parseExpression(precedence);
    }

    const leftExpression = prefix();
    if (leftExpression.err) {
      return leftExpression;
    }

    let left = leftExpression.val;

    while (this.peekToken.type !== 'eof' && precedence < this.peekPrecedence()) {
      const infix = this.infixParseFunctions.get(this.peekToken.type);
      if (infix === undefined) {
        return leftExpression;
      }

      this.nextToken();

      const nextLeftExpression = infix(left);
      if (nextLeftExpression.err) {
        return nextLeftExpression;
      }

      left = nextLeftExpression.val;
    }

    return Ok(left);
  }

  private readonly parseMatchExpression: PrefixParseFunction = () => {
    const startPosition = this.currentToken.startPosition;
    const matchnameToken = copy(this.currentToken);
    const matchname = safeJSONStringParse(this.currentToken.text);
    if (matchname.err) {
      return Err(
        new ParserError(`Invalid matchname name ${this.currentToken.text}`, {
          input: this.lexer.input,
          failedToken: copy(this.currentToken),
          expectedTokenType: 'matchname',
        }),
      );
    }

    if (!this.expectPeek('matchoperator')) {
      if (this.peekToken.type === 'illegal') {
        if (this.peekToken.reason === 'unmatched_quote') {
          return Ok(
            equals('q', this.input().slice(this.currentToken.startPosition, this.input().length).trim(), {
              type: 'expected_token',
              input: this.input(),
              failedToken: copy(this.peekToken),
              expectedTokenType: 'matchoperator',
              previousTokens: [copy(matchnameToken)],
            }),
          );
        }

        if (this.peekToken.reason === 'eof') {
          return Ok(
            equals('q', this.input().slice(this.currentToken.startPosition, this.input().length).trim(), {
              type: 'expected_token',
              input: this.input(),
              failedToken: copy(this.peekToken),
              expectedTokenType: 'matchoperator',
              previousTokens: [copy(matchnameToken)],
            }),
          );
        }
      }

      if (this.peekToken.type === 'eof') {
        return Ok(
          equals('q', this.input().slice(this.currentToken.startPosition, this.input().length).trim(), {
            type: 'expected_token',
            input: this.input(),
            failedToken: copy(this.peekToken),
            expectedTokenType: 'matchoperator',
            previousTokens: [copy(matchnameToken)],
          }),
        );
      }
    }

    const matchOperatorToken = copy(this.currentToken);
    const matchoperator = safeJSONStringParse(this.currentToken.text);
    if (matchoperator.err) {
      return Err(
        new ParserError(`Invalid match operator name ${this.currentToken.text}`, {
          input: this.lexer.input,
          failedToken: copy(this.currentToken),
          expectedTokenType: 'matchoperator',
        }),
      );
    }

    const canonical = canonicalOperator(matchoperator.val);
    if (!isValidOperator(canonical)) {
      return Ok(
        equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), startPosition), {
          type: 'unknown_operator',
          input: this.input(),
          failedToken: matchOperatorToken,
          expectedTokenType: 'matchoperator',
          previousTokens: [copy(matchnameToken)],
        }),
      );
    }

    if (!this.expectPeek('matchvalue')) {
      return Ok(
        equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), startPosition), {
          type: 'expected_token',
          input: this.input(),
          failedToken: copy(this.peekToken),
          expectedTokenType: 'matchvalue',
          previousTokens: [copy(matchnameToken), copy(matchOperatorToken)],
        }),
      );
    }

    const matchvalueToken = copy(this.currentToken);
    const matchvalue = safeJSONParse(this.currentToken.text);
    if (matchvalue.err) {
      return Ok(
        equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), startPosition), {
          type: 'malformed_json',
          input: this.input(),
          failedToken: matchvalueToken,
          expectedTokenType: 'matchvalue',
          previousTokens: [copy(matchnameToken), copy(matchOperatorToken)],
        }),
      );
    }

    let node: Match;

    switch (canonical) {
      case 'equals':
        node = equals(matchname.val, matchvalue.val, undefined, {
          matchname: matchnameToken,
          matchoperator: matchOperatorToken,
          matchvalue: matchvalueToken,
        });
        break;
      case 'notEquals':
        node = notEquals(matchname.val, matchvalue.val);
        break;
      case 'after':
        if (!isDateValue(matchvalue.val)) {
          return Ok(
            equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), startPosition), {
              type: 'invalid_data_type',
              input: this.input(),
              failedToken: matchvalueToken,
              expectedTokenType: 'matchvalue',
              expectedDataType: 'datevalue',
              previousTokens: [copy(matchnameToken), copy(matchOperatorToken)],
            }),
          );
        }

        node = after(matchname.val, matchvalue.val, undefined, {
          matchname: matchnameToken,
          matchoperator: matchOperatorToken,
          matchvalue: matchvalueToken,
        });
        break;
      case 'before':
        if (!isDateValue(matchvalue.val)) {
          return Ok(
            equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), startPosition), {
              type: 'invalid_data_type',
              input: this.input(),
              failedToken: matchvalueToken,
              expectedTokenType: 'matchvalue',
              expectedDataType: 'datevalue',
              previousTokens: [copy(matchnameToken), copy(matchOperatorToken)],
            }),
          );
        }

        node = before(matchname.val, matchvalue.val, undefined, {
          matchname: matchnameToken,
          matchoperator: matchOperatorToken,
          matchvalue: matchvalueToken,
        });
        break;
      case 'contains':
        if (!isArrayValue(matchvalue.val)) {
          return Ok(
            equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), startPosition), {
              type: 'invalid_data_type',
              input: this.input(),
              failedToken: matchvalueToken,
              expectedTokenType: 'matchvalue',
              expectedDataType: 'array',
              previousTokens: [copy(matchnameToken), copy(matchOperatorToken)],
            }),
          );
        }

        node = contains(matchname.val, matchvalue.val, undefined, {
          matchname: matchnameToken,
          matchoperator: matchOperatorToken,
          matchvalue: matchvalueToken,
        });
        break;
      case 'anyOf':
        if (!isArrayValue(matchvalue.val)) {
          return Ok(
            equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), startPosition), {
              type: 'invalid_data_type',
              input: this.input(),
              failedToken: matchvalueToken,
              expectedTokenType: 'matchvalue',
              expectedDataType: 'array',
              previousTokens: [copy(matchnameToken), copy(matchOperatorToken)],
            }),
          );
        }

        node = anyOf(matchname.val, matchvalue.val, undefined, {
          matchname: matchnameToken,
          matchoperator: matchOperatorToken,
          matchvalue: matchvalueToken,
        });
        break;
      case 'exists':
        if (!isBoolean(matchvalue.val)) {
          return Ok(
            equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), startPosition), {
              type: 'invalid_data_type',
              input: this.input(),
              failedToken: matchvalueToken,
              expectedTokenType: 'matchvalue',
              expectedDataType: 'array',
              previousTokens: [copy(matchnameToken), copy(matchOperatorToken)],
            }),
          );
        }

        node = exists(matchname.val, matchvalue.val, undefined, {
          matchname: matchnameToken,
          matchoperator: matchOperatorToken,
          matchvalue: matchvalueToken,
        });
        break;
      case 'startsWith':
        if (!isStringValue(matchvalue.val)) {
          return Ok(
            equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), startPosition), {
              type: 'invalid_data_type',
              input: this.input(),
              failedToken: matchvalueToken,
              expectedTokenType: 'matchvalue',
              expectedDataType: 'string',
              previousTokens: [copy(matchnameToken), copy(matchOperatorToken)],
            }),
          );
        }

        node = startsWith(matchname.val, matchvalue.val, undefined, {
          matchname: matchnameToken,
          matchoperator: matchOperatorToken,
          matchvalue: matchvalueToken,
        });
        break;
      case 'gte':
        if (!isNumberValue(matchvalue.val)) {
          return Ok(
            equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), startPosition), {
              type: 'invalid_data_type',
              input: this.input(),
              failedToken: matchvalueToken,
              expectedTokenType: 'matchvalue',
              expectedDataType: 'number',
              previousTokens: [copy(matchnameToken), copy(matchOperatorToken)],
            }),
          );
        }
        node = gte(matchname.val, matchvalue.val, undefined, {
          matchname: matchnameToken,
          matchoperator: matchOperatorToken,
          matchvalue: matchvalueToken,
        });
        break;
      case 'lte':
        if (!isNumberValue(matchvalue.val)) {
          return Ok(
            equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), startPosition), {
              type: 'invalid_data_type',
              input: this.input(),
              failedToken: matchvalueToken,
              expectedTokenType: 'matchvalue',
              expectedDataType: 'number',
              previousTokens: [copy(matchnameToken), copy(matchOperatorToken)],
            }),
          );
        }
        node = lte(matchname.val, matchvalue.val, undefined, {
          matchname: matchnameToken,
          matchoperator: matchOperatorToken,
          matchvalue: matchvalueToken,
        });
        break;
    }

    return Ok(node);
  };

  private readonly parseGroupExpression: PrefixParseFunction = () => {
    this.nextToken();

    const node = this.parseExpression(Precedence.LOWEST);
    if (node.err) {
      return node;
    }

    if (!this.expectPeek('closeparen')) {
      // Ignore grouping if it isn't closed properly.
      // From a UX perspective, this means groupings are only relevant once they're
      // valid.
      return node;
    }

    return Ok(parenthesized(node.val));
  };

  private readonly parseIllegalToken: PrefixParseFunction = () =>
    Ok(
      equals('q', this.currentToken.text, {
        type: 'standalone_token',
        input: this.input(),

        // An illegal token in prefix position means we have an
        // incomplete matchname.
        expectedTokenType: 'matchname',
        failedToken: copy(this.currentToken),
      }),
    );

  private readonly parseEOFToken: PrefixParseFunction = () => Ok(empty());

  private readonly parseLogicalExpression: InfixParseFunction = (left: Queryfilter) => {
    const operatorToken = copy(this.currentToken);
    const operator = this.currentToken.type;
    const precedence = this.currentPrecedence();

    this.nextToken();

    let right: Result<Queryfilter, ParserError>;

    // if there's no right-hand side, use a type:empty node
    if (this.currentToken.type === 'eof') {
      right = Ok(empty());
    } else if (this.currentToken.type === 'illegal') {
      right = Ok(
        equals('q', this.readInputUntil(tokenTypeIn('and', 'or', 'closeparen'), this.currentToken.startPosition), {
          type: 'expected_token',
          input: this.input(),
          failedToken: copy(this.currentToken),
          expectedTokenType: 'matchname',
          previousAST: copy(left),
        }),
      );
    } else {
      right = this.parseExpression(precedence);
    }

    if (right.err) {
      return right;
    }

    switch (operator) {
      case 'and':
        return Ok(and([left, right.val], operatorToken));
      case 'or':
        return Ok(or([left, right.val], operatorToken));
    }

    return Err(
      new ParserError(`Unknown logical operator "${this.currentToken.type}`, {
        input: this.lexer.input,
        failedToken: copy(this.currentToken),
      }),
    );
  };

  private expectPeek(tokenType: Token['type']): boolean {
    if (this.peekToken.type === tokenType) {
      this.nextToken();
      return true;
    }

    return false;
  }

  /**
   * Read the input string until a specific token type is reached. The
   * part of the input that corresponds to the matching token is not
   * included.
   */
  private readInputUntil(condition: (token: Token) => boolean, startPosition?: number): string {
    let text = startPosition !== undefined ? this.input().slice(startPosition, this.currentToken.startPosition) : '';

    while (this.peekToken.type !== 'eof') {
      if (condition(this.peekToken)) {
        text += this.input().slice(this.currentToken.startPosition, this.peekToken.startPosition);
        return text;
      }

      text += this.input().slice(this.currentToken.startPosition, this.peekToken.startPosition);

      this.nextToken();
    }

    text += this.input().slice(this.currentToken.startPosition, this.peekToken.startPosition);
    return text;
  }

  private nextToken() {
    this.currentToken = this.peekToken;
    this.peekToken = this.lexer.nextToken();
  }

  private currentPrecedence() {
    const p = precedences[this.currentToken.type];
    if (p === undefined) {
      return Precedence.LOWEST;
    }

    return p;
  }

  private peekPrecedence() {
    const p = precedences[this.peekToken.type];
    if (p === undefined) {
      return Precedence.LOWEST;
    }

    return p;
  }

  private registerPrefix(tokenType: Token['type'], fn: PrefixParseFunction) {
    this.prefixParseFunctions.set(tokenType, fn);
  }

  private registerInfix(tokenType: Token['type'], fn: InfixParseFunction) {
    this.infixParseFunctions.set(tokenType, fn);
  }

  private input() {
    return this.lexer.input;
  }
}

export function parse(input: string): Result<Queryfilter, ParserError> {
  const lexer = new Lexer(input);
  const parser = new Parser(lexer);
  const ast = parser.parse();
  return ast;
}

/**
 * We make a copy of the current token when saving errors
 * so that we save the token's value from that point in time.
 * (Otherwise, error tokens would all end up point to whatever
 * the current token was.)
 * @param obj
 * @returns a copy of the object
 */
function copy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

function tokenTypeIn(...tokenTypes: TokenType[]) {
  return (token: Token) => {
    for (const tokenType of tokenTypes) {
      if (token.type === tokenType) {
        return true;
      }
    }

    return false;
  };
}
