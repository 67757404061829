import { ReactNode } from 'react';
import cx from 'clsx';

import styles from './OAuthButtonContainer.module.css';

type OAuthButtonContainerProps = {
  children: ReactNode;
  className?: string;
};

export const OAuthButtonContainer = ({ children, className }: OAuthButtonContainerProps) => (
  <div className={cx(styles.container, className)}>{children}</div>
);
