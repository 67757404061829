/**
 * This module defines assigns unauthenticated route ownership to the App Platform team.
 *
 */

import { UnauthenticatedRouteOwnership } from '@gonfalon/router';

export const unauthenticatedRouteOwnership: UnauthenticatedRouteOwnership = {
  '/*': 'release-fe',
  '/forgot': 'release-fe',
  '/invite/:invitationToken': 'release-fe',
  '/join': 'release-fe',
  '/login': 'release-fe',
  '/oauth/google': 'release-fe',
  '/oauth/github': 'release-fe',
  '/reset/:passwordResetToken': 'release-fe',
  '/signup': 'release-fe',
  '/join-your-organization/:token': 'release-fe',
  '/verify-email/:email': 'release-fe',
  '/check-your-email': 'release-fe',
  '/choose-organization/:email': 'release-fe',
  '/choose-organization/verify/:token': 'release-fe',
  '/choose-organization/verify-email/:email': 'release-fe',
  '/choose-organization/check-your-email': 'release-fe',
  '/choose-organization/:email/:selectedOrganization/enter-password': 'release-fe',
  '/choose-organization/:selectedOrganization/forgot': 'release-fe',
  '/trial-limit': 'release-fe',
  '/welcome/:token': 'release-fe',
};
