import { useEffect } from 'react';

export function useMediaQueryListener(query: string, callback: (matches: boolean) => void) {
  useEffect(() => {
    const media = window.matchMedia(query);
    function handleMediaChange() {
      callback(media.matches);
    }
    media.addEventListener('change', handleMediaChange);
    return () => media.addEventListener('change', handleMediaChange);
  }, [callback, query]);
}
