import { Map } from 'immutable';
import { AnyAction } from 'redux';

import { createRequest, Request, RequestAction } from 'utils/requestUtils';

type ActionFilter = (action: AnyAction) => boolean;

export const createRequestReducer = (types: string[], filterAction: ActionFilter = () => true) => {
  const [requestType, successType, failureType] = types;

  return (state = createRequest(), action: AnyAction) => {
    if (!filterAction(action)) {
      return state;
    }

    switch (action.type) {
      case requestType:
        return state.start();
      case successType:
        return state.done(action as RequestAction);
      case failureType:
        return state.failed(action as RequestAction);
      default:
        return state;
    }
  };
};

type ActionKeyFunction = (action: RequestAction) => string;

export const createRequestReducerByKey = (types: string[], keyFn: ActionKeyFunction) => {
  const byKey = createRequestReducer(types);

  return (state: Map<string, Request> = Map(), action: AnyAction) => {
    if (action && types.indexOf(action.type) !== -1) {
      const key = keyFn(action as RequestAction);
      return state.set(key, byKey(state.get(key), action));
    } else {
      return state;
    }
  };
};
