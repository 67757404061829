import { hasCustomRoles } from './hasCustomRoles';
import { hasPermissionGrants } from './hasPermissionGrants';
import { hasTeamCustomRoles } from './hasTeamCustomRoles';
import { Member } from './types';

export function isReader(member: Member) {
  return (
    member.role === 'reader' && !hasCustomRoles(member) && !hasTeamCustomRoles(member) && !hasPermissionGrants(member)
  );
}
