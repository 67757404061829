import { type GetHoldoutQueryParams } from '@gonfalon/openapi';

import { createQueryHook } from './internal/createQueryHook';
import { holdouts } from './internal/queries';

export const useHoldout = createQueryHook(
  ({
    projectKey,
    environmentKey,
    holdoutKey,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    holdoutKey: string;
    params?: GetHoldoutQueryParams;
  }) => holdouts.detail({ projectKey, environmentKey, holdoutKey, params }),
);
