import { PatchFeatureFlagOpenAPIRequestBody } from '../generated/operations/raw-openapi-request-body-for-serializers';

type JSONPatch = PatchFeatureFlagOpenAPIRequestBody;

interface SemanticPatch {
  /** @description Optional comment */
  comment?: string;
  /** @description Environment key for some instructions */
  environmentKey?: string;
  /** @description A list of actions the update should perform */
  instructions: unknown[];
}

export type PatchFeatureFlagRequestBody = JSONPatch | SemanticPatch;

export function bodySerializer(body: PatchFeatureFlagRequestBody): string {
  return JSON.stringify(body);
}
