import { createQueryHook } from './internal/createQueryHook';
import { metricAnalytics } from './internal/queries';
import { PostProductAnalyticsQueryRep } from './internal/types';

type Props = {
  projectKey: string;
  environmentKey: string;
  query: PostProductAnalyticsQueryRep;
};

export const useQueryMetricAnalytics = createQueryHook(({ projectKey, environmentKey, query }: Props) =>
  metricAnalytics.series({ projectKey, environmentKey, query }),
);
