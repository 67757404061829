const initAnalyticsSession = () => {
  if (window.sessionStorage.getItem('sessionStartTime') === null) {
    try {
      window.sessionStorage.setItem('sessionStartTime', Date.now().toString());
    } catch (e) {
      // Some users configure their browsers to disallow sessionStorage; we’re okay with it failing silently in those cases
    }
  }
};

export { initAnalyticsSession };
