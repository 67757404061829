// pluralize the word passed in, using the count value to determine if the word should be pluralized
// handles irregular words, e.g. person/people
// words passed in should be in it's singular form
// if `inclusive` is true, include the count at the beginning of the returned string
const pluralize = (word: string, count?: number, inclusive: boolean = false) => {
  if (count === 1 && !inclusive) {
    return word;
  }

  const IRREGULARS: { [word: string]: string } = {
    is: 'are',
    person: 'people',
    more: 'more',
    this: 'these',
    that: 'those',
  };

  const isAlreadyPlural = (): boolean =>
    word.endsWith('ies') ||
    word.endsWith('es') ||
    (word.endsWith('s') && !word.endsWith('ss') && !word.endsWith('us') && !word.endsWith('is'));

  const pluralizeWord = (): string => {
    // if the word is in the irregulars list, return the plural form
    if (IRREGULARS[word]) {
      return IRREGULARS[word];
    }
    // if the word ends in 'y' and is not preceded by a vowel, add 'ies'
    if (word.endsWith('y') && !/[aeiou]y$/.test(word)) {
      return word.replace(/y$/, 'ies');
    }
    // if the word ends in 'ch', 'sh', 's', 'x', or 'z', add 'es'
    if (word.endsWith('ch') || word.endsWith('sh') || word.endsWith('s') || word.endsWith('x') || word.endsWith('z')) {
      return `${word}es`;
    }
    // otherwise, add 's'
    return `${word}s`;
  };

  const result = count === 1 || isAlreadyPlural() ? word : pluralizeWord();
  // if inclusive is true, add the count to the beginning of the result
  return (inclusive ? `${count} ` : '') + result;
};

export { pluralize };
