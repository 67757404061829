import type { ReactNode } from 'react';
import { useEffect } from 'react';
import sprite from '@launchpad-ui/icons/sprite.svg';

import Logger from 'utils/logUtils';

const logger = Logger.get('IconProvider');

export function IconProvider({ children }: { children: ReactNode }) {
  useEffect(() => {
    fetch(sprite)
      .then(async (response) => response.text())
      .then((data) => {
        const parser = new DOMParser();
        const content = parser.parseFromString(data, 'image/svg+xml').documentElement;
        content.id = 'lp-icons-sprite';
        content.style.display = 'none';
        document.body.appendChild(content);
      })
      .catch((err) => {
        logger.error('Failed to fetch sprite', err);
      });
  }, []);

  return children;
}
