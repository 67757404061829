import { AccessCheck } from '../internal/types';

export function getAccessRestrictedText({
  access,
  kind,
}: {
  access: AccessCheck;
  kind: 'action' | 'field' | 'setting' | 'token';
}) {
  let text: string | null;

  if (access.isAllowed) {
    return null;
  }

  if (access.customMessage) {
    text = access.customMessage;
  }

  switch (kind) {
    case 'action':
      text = access.appliedRoleName
        ? `The "${access.appliedRoleName}" role does not allow you to perform this action`
        : 'Your permissions don’t allow you to perform this action';
      break;
    case 'field':
      text = access.appliedRoleName
        ? `The "${access.appliedRoleName}" role does not allow you to modify this field`
        : 'Your permissions don’t allow you to modify this field';
      break;
    case 'setting':
      text = access.appliedRoleName
        ? `The "${access.appliedRoleName}" role does not allow you to change this setting`
        : 'Your permissions don’t allow you to change this setting';
      break;
    default:
      text = '';
  }

  return text;
}
