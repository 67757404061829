import { unauthenticatedPagePromo, unauthenticatedPagePromoEffect } from '@gonfalon/dogfood-flags';

export enum UnauthenticatedPageThemes {
  default = 'off',
  dark = 'dark-theme',
}

export enum UnauthenticatedPagePromos {
  disabled = 'off',
  default = 'default',
  awsReinvent = 'aws-reinvent',
  postGalaxy = 'post-galaxy',
  springSignupPage = 'spring-signup-page',
  winterRelease = 'winter-release',
}

export enum UnauthenticatedPagePromoEffects {
  disabled = 'off',
  stars = 'stars',
}

export const shouldShowUnauthenticatedPagePromo = () =>
  unauthenticatedPagePromo() !== UnauthenticatedPagePromos.disabled;

export const shouldShowUnauthenticatedPagePromoEffect = () =>
  unauthenticatedPagePromoEffect() !== UnauthenticatedPagePromoEffects.disabled;
