import { type GetMeasuredRolloutsQueryParams } from '@gonfalon/openapi';

import { createQueryHook } from './internal/createQueryHook';
import { measuredRollouts } from './internal/queries';

export const useMeasuredRollout = createQueryHook(
  ({
    projectKey,
    flagKey,
    environmentKey,
    measuredRolloutId,
    params,
  }: {
    projectKey: string;
    flagKey: string;
    environmentKey: string;
    measuredRolloutId: string;
    params?: GetMeasuredRolloutsQueryParams;
  }) => measuredRollouts.detail({ projectKey, flagKey, environmentKey, measuredRolloutId, params }),
);
