import { useEffect } from 'react';

import { OAuthButtonContainer } from '../OAuthButtonContainer';

export enum GoogleDataText {
  SIGN_IN = 'signin',
  SIGNIN_WITH = 'signin_with',
  SIGNUP_WITH = 'signup_with',
  CONTINUE_WITH = 'continue_with',
}

export type GoogleOAuthProps = {
  clientId: string;
  dataText?: GoogleDataText;
  enableOneTap?: boolean;
  redirect?: string;
  width?: number;
};

export const GoogleOAuthButton = ({
  clientId,
  dataText,
  enableOneTap = true,
  redirect = '',
  width = 700,
}: GoogleOAuthProps) => {
  const decodedRedirectPath = decodeURIComponent(redirect);
  const successPath = `${window.location.origin}${decodedRedirectPath}${window.location.search}`;
  // `loginUri` directs the user to Google OAuth. After sign-in, they're sent to `successPath` with necessary query params that allow us to authorize apps such as slack integrations.
  const loginUri = `${window.location.origin}/oauth/google?redirect=${encodeURIComponent(successPath)}`;

  useEffect(() => {
    const scriptUrl = 'https://accounts.google.com/gsi/client';
    const scriptTags = document.querySelectorAll(`script[src="${scriptUrl}"]`);
    const script = document.createElement('script');

    if (scriptTags.length === 0) {
      script.src = scriptUrl;
      script.async = true;
      script.defer = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <OAuthButtonContainer>
      <div
        id="g_id_onload"
        data-context="use"
        data-ux_mode="redirect"
        data-client_id={clientId}
        data-login_uri={loginUri}
        data-auto_prompt={enableOneTap}
      ></div>

      <div
        className="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="filled_black"
        data-size="large"
        data-logo_alignment="left"
        data-text={dataText}
        data-width={width}
        data-test-id="google-oauth-login"
      ></div>
    </OAuthButtonContainer>
  );
};
