import { type GetFeatureFlagsQueryParams } from '@gonfalon/openapi';

import { createQueryFetcher } from './internal/createQueryFetcher';
import { flags } from './internal/queries';

export const fetchFlags = createQueryFetcher(
  ({ projectKey, params }: { projectKey: string; params?: GetFeatureFlagsQueryParams }) => {
    return flags.list({ projectKey, params });
  },
);
