import { putFlagFollower } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { flags, projects } from './internal/queries';

export const useCreateFlagFollower = createMutationHook(putFlagFollower, {
  onSuccess: async (data, { projectKey, featureFlagKey: flagKey, environmentKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: flags.detail({ projectKey, flagKey })._ctx.followers({ environmentKey }).queryKey,
    });
    await queryClient.invalidateQueries({
      queryKey: projects.detail({ projectKey })._ctx.followers({ environmentKey }).queryKey,
    });
  },
});
