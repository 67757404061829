/**
 * This merges an array of request results into a single array of objects, where the objects' "environments" object properties have been merged.
 * This applies to both merging flag status query results and flag query results.
 */
export function mergeEnvironmentsByKey<T extends { key: string; environments: object }>(data: Array<{ items: T[] }>) {
  const result: T[] = [];

  for (const batch of data) {
    for (const item of batch.items) {
      const itemIndex = result.findIndex((i) => i.key === item.key);

      if (itemIndex >= 0) {
        result[itemIndex].environments = {
          ...result[itemIndex].environments,
          ...item.environments,
        };
      } else {
        result.push(item);
      }
    }
  }

  return result;
}
