import { currentEnvironment as _currentEnvironment } from '@gonfalon/bootstrap-data';
import { Map } from 'immutable';

import { type Environment, createEnvironment } from 'utils/environmentUtils';
import { type ImmutableServerError } from 'utils/httpUtils';
import { type ImmutableMap } from 'utils/immutableUtils';

export type EnvironmentEntities = Map<string, Environment>;
export type EnvironmentsState = ImmutableMap<{
  entities: EnvironmentEntities;
  lastFetched?: number;
  isFetching?: boolean;
  error?: ImmutableServerError;
  doNotFetch?: boolean;
}>;

export function getEnvironmentsInitialState(
  initialState: ReturnType<typeof _currentEnvironment> = _currentEnvironment(),
) {
  const state: EnvironmentsState = Map({
    entities: Map(),
    lastFetched: null,
    isFetching: false,
    doNotFetch: false,
    error: null,
  });
  const env = initialState;

  if (env) {
    const e = createEnvironment(env);
    return state.update('entities', (entities: EnvironmentEntities) => entities.set(e._id, e));
  }

  return state;
}
