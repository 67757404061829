import { patchReleaseByFlagKey } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { flags } from './internal/queries';

export const useUpdateFlagRelease = createMutationHook(patchReleaseByFlagKey, {
  onSuccess: async (data, { projectKey, flagKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: flags.detail({ projectKey, flagKey })._ctx.release.queryKey });
  },
});
