import { createTaskRunner } from '@gonfalon/async';
import { type GetMigrationFlagMetricsQueryParams, getMigrationFlagMetrics, schemas } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

type MetricsTask = {
  projectKey: string;
  flagKey: string;
  environmentKey: string;
  ruleId: string;
  params?: Omit<GetMigrationFlagMetricsQueryParams, 'rules'>;
};

export const getMigrationFlagMetricsTaskRunner = createTaskRunner<
  MetricsTask,
  schemas['RuleMetricsRep'] | undefined,
  NonNullable<Awaited<ReturnType<typeof getMigrationFlagMetrics>>['data']>
>({
  runner: async (inputs) => {
    return reactQueryResponseAdapter(
      getMigrationFlagMetrics({
        projectKey: inputs[0].projectKey,
        flagKey: inputs[0].flagKey,
        environmentKey: inputs[0].environmentKey,
        query: { ...inputs[0].params, rules: Array.from(new Set(inputs.map((input) => input.ruleId))) },
      }),
    );
  },
  resolver: (result, input) => {
    const res = result.rules?.find((rule) => rule.ruleId === input.ruleId);
    return res
      ? res
      : {
          data: [],
          ruleId: input.ruleId,
          summary: {},
        };
  },
  batching: {
    maxBatchSize: 10,
    resultMerger: (result) => {
      let rules: Array<schemas['RuleMetricsRep']> = [];
      for (const batch of result) {
        rules = rules.concat(batch.rules ?? []);
      }

      return {
        overview: result[0].overview,
        rules,
      };
    },
  },
});
