import { NavigateOptions, useLocation } from 'react-router-dom';

import { isPath } from './isPath';
import { useCurrentPath } from './useCurrentPath';

/**
 * Returns both the current path as `referer` as well as a `routerOptions` object that includes the current path as `state.referer`.
 */
export function useLocationReferer() {
  const location = useLocation();
  const referer = location.state?.referer;
  const currentPath = useCurrentPath();

  return {
    referer: isPath(referer) ? referer : undefined,
    routerOptions: {
      state: { referer: currentPath },
    } satisfies NavigateOptions,
  };
}
