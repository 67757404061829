import { Path, resolvePath } from 'react-router-dom';

type PathModifier = (url: Path) => Path;

/**
 * Internal help for building URLs functionally
 * @param pathname
 * @param options
 * @returns path
 */
export function buildPath(pathname: string, ...options: PathModifier[]) {
  let path = resolvePath(pathname);
  for (const option of options) {
    path = resolvePath(option(path));
  }
  return path;
}
