import { createQueryHook } from './internal/createQueryHook';
import { monitoringIssuesList } from './internal/queries';
import { GetMonitoringIssuesQueryParams } from './internal/types';

type Params = { projectKey: string } & GetMonitoringIssuesQueryParams;

export const useMonitoringIssues = createQueryHook(
  ({ projectKey, search, limit, offset, sortBy = 'last_seen_error', status }: Params) =>
    monitoringIssuesList({ projectKey, query: { search, limit, offset, sortBy, status } }),
);
