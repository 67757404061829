import { isUseHTTPMethodOverrideEnabled } from '@gonfalon/dogfood-flags';
import type { paths } from '@gonfalon/openapi';
import createClient, { mergeHeaders, Middleware } from 'openapi-fetch';

const launchdarkly: Middleware = {
  onRequest({ request }) {
    if (isUseHTTPMethodOverrideEnabled()) {
      if (['PUT', 'PATCH', 'DELETE'].includes(request.method)) {
        const nextHeaders = new Headers(request.headers);
        nextHeaders.set('X-HTTP-Method-Override', request.method);
        return new Request(request, {
          method: 'POST',
          headers: mergeHeaders(request.headers, { 'X-HTTP-Method-Override': request.method }),
        });
      }
    }
  },
};

let client: ReturnType<typeof createClient<paths>> | undefined;

export function getClient() {
  if (client === undefined) {
    client = createClient<paths>({
      baseUrl: location.origin,
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
      },
    });
    client.use(launchdarkly);
  }

  return client;
}

export function resetClient() {
  client = undefined;
}
