import { GetState, GlobalDispatch, GlobalState } from 'reducers';
import { selectTimeToLoginMetrics } from 'reducers/timeToLoginMetric';
import { SignInExperienceType } from 'utils/loginUtils';
import { GenerateActionType } from 'utils/reduxUtils';

export const resetTimeToLoginMetrics = () =>
  ({
    type: 'timeToLoginMetric/RESET',
  }) as const;

export const setTimeToLoginStartTime = (time: number) =>
  ({
    type: 'timeToLoginMetric/SET_START',
    payload: time,
  }) as const;

export const setTimeToLoginEndTime = (time: number) =>
  ({
    type: 'timeToLoginMetric/SET_END',
    payload: time,
  }) as const;

export const setLoginExperienceType = (type: SignInExperienceType) =>
  ({
    type: 'timeToLoginMetric/SET_TYPE',
    payload: type,
  }) as const;

const shouldSetLoginEndTime = (state: GlobalState) => {
  const repos = selectTimeToLoginMetrics(state);
  return !repos.get('endTime');
};

export function setLoginEndTimeMetricIfValid(time: number) {
  return (dispatch: GlobalDispatch, getState: GetState) => {
    if (shouldSetLoginEndTime(getState())) {
      return dispatch(setTimeToLoginEndTime(time));
    }
  };
}

const TimeToLoginMetricActionCreators = {
  setTimeToLoginStartTime,
  setTimeToLoginEndTime,
  setLoginExperienceType,
  reset: resetTimeToLoginMetrics,
};

export type TimeToLoginMetricAction = GenerateActionType<typeof TimeToLoginMetricActionCreators>;
