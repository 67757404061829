import { isUndefined } from '@gonfalon/es6-utils';
import { AnyAction, applyMiddleware, compose, createStore, Middleware, Reducer, StoreEnhancer } from 'redux';
import thunk from 'redux-thunk';

import { ImmutableServerError } from 'utils/httpUtils';

// Generate an object containing namespaced constant values.
// This is useful to avoid clashes in action types.
// @param {String} namespace a string that will be prefixed to your action names
// @param {Array} names an array of action names
export function constants<T extends string, TNamespace extends string>(namespace: TNamespace, names: readonly T[]) {
  return names.reduce(
    (acc, name) => ({
      ...acc,
      [name]: `${namespace}/${name}`,
    }),
    {},
  ) as {
    [k in T]: `${TNamespace}/${k}`;
  };
}

export const createConstantNamespace = (ns: string) => (name: string) => `${ns}/${name}`;

// Configure the state store
// @param {(state, action) => state} reducer the root reducer function
// @param {Object} initialState the optional initial state for the store
// @param {Array} middlewares additional middleware
// @return {Object} the configured store
export function configureStore(
  reducer: Reducer,
  initialState: $TSFixMe,
  middlewares: Middleware[] = [],
  ...extraEnhancers: StoreEnhancer[]
) {
  const middleware = [thunk, ...middlewares];

  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(
      reducer,
      initialState,
      composeEnhancers(applyMiddleware(...middleware), ...extraEnhancers) as StoreEnhancer<unknown>,
    );
  }

  return createStore(
    reducer,
    initialState,
    compose(applyMiddleware(...middleware), ...extraEnhancers) as StoreEnhancer<unknown>,
  );
}

// We use both `Request` (an `immutable.Record`, recent code) and `immutable.Map` (older code) instances to represent
// request state.
//
// We rely on the `.get` method to smooth over the different property access mechanism of Map and Record.
export type RequestState = {
  get(prop: 'lastFetched'): number;
  get(prop: 'isFetching' | 'isSummaryRep'): boolean;
  get(prop: 'error'): ImmutableServerError | null;
};

// Check that all passed resources are ready.
//
// For all state fetched from the server, we maintain the time of the last
// fetch (lastFetched) and whether the system is currently fetching (isFetching).
export function ready(...resources: Array<RequestState | undefined>) {
  return resources.every((r) => {
    if (isUndefined(r)) {
      return false;
    }
    return !!r.get('lastFetched') && !r.get('isFetching');
  });
}

// Check if any passed resources have previously fetched and are currently fetching
export function refetching(...resources: Array<RequestState | undefined>) {
  return resources.some((r) => {
    if (isUndefined(r)) {
      return false;
    }
    return !!r.get('lastFetched') && r.get('isFetching');
  });
}

export function serverError(...resources: Array<RequestState | undefined>) {
  const erroredResource = resources.find((r) => !!r?.get('error'));
  return erroredResource?.get('error') || null;
}

export function isFullRep(...resources: RequestState[]) {
  return resources.every((r) => !r?.get('isSummaryRep'));
}

type ActionPropertiesFromMiddleware = {
  timestamp?: number;
  keepState?: boolean; // For Beast Mode™
};

export type EmptyAction = {
  type: null;
};

export function isEmptyAction(action: AnyAction): action is EmptyAction {
  return 'type' in action && action.type === null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenerateActionType<T extends { [key: string]: (...args: readonly any[]) => AnyAction }> =
  | (ReturnType<T[keyof T]> & ActionPropertiesFromMiddleware)
  | EmptyAction;

// Replace a value in an array at the given index without mutating the array.
// This can be useful for updating values inside array types in the Redux store.
// Optional conditional can be passed to only perform the insert when the condition is true.
export const pureReplaceAtIndex = <T>(arr: T[], index: number, value: T, opts?: { condition: boolean }) => {
  if (opts !== undefined && !opts.condition) {
    return arr;
  }
  return [...arr.slice(0, index), value, ...arr.slice(index + 1)];
};
