import { createQueryHook } from './internal/createQueryHook';
import { metricAnalytics } from './internal/queries';

type Props = {
  projectKey: string;
  environmentKey: string;
  metricKey: string;
  startsWith?: string;
  limit?: number;
};

export const useProductAnalyticsMetricDimensions = createQueryHook(
  ({ projectKey, environmentKey, metricKey, startsWith, limit }: Props) =>
    metricAnalytics.metricDimensions({ projectKey, environmentKey, metricKey, startsWith, limit }),
);
