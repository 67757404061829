import { useEffect, useRef } from 'react';

export type ModifierKeyState = {
  metaKey: boolean;
  ctrlKey: boolean;
  altKey: boolean;
  shiftKey: boolean;
};

/**
 * Track the state of the modifier keys (meta, ctrl, alt, shift).
 *
 * NOTE: The value from this hook is a ref, meaning updates will not trigger a re-render.
 * It's designed to be used in event handlers where you want to tweak behavior based on the
 * state of the modifier keys.
 */
export function useModifierKeys() {
  const modifiers = useRef<ModifierKeyState>({
    metaKey: false,
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
  });

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.repeat) {
      return;
    }

    modifiers.current = {
      metaKey: event.metaKey,
      ctrlKey: event.ctrlKey,
      altKey: event.altKey,
      shiftKey: event.shiftKey,
    };
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.repeat) {
      return;
    }

    modifiers.current = {
      metaKey: event.metaKey,
      ctrlKey: event.ctrlKey,
      altKey: event.altKey,
      shiftKey: event.shiftKey,
    };
  };

  useEffect(() => {
    // Note: we use the capture phase to ensure we get events before any other listeners (e.g. react-aria-components)
    document.addEventListener('keyup', handleKeyUp, { capture: true });
    document.addEventListener('keydown', handleKeyDown, { capture: true });

    return () => {
      document.removeEventListener('keyup', handleKeyUp, { capture: true });
      document.removeEventListener('keydown', handleKeyDown, { capture: true });
    };
  }, []);

  return modifiers;
}
