// https://github.com/github/fetch
//
// NOTE: this is a polyfill, meaning that it will only be used
// if the browser does not implement the Fetch API itself.
import { redirectToBeastModeEscalation, refreshBeastModeExpiration } from '@gonfalon/beastmode';
import { omitDeep } from '@gonfalon/collections';
import { isUseHTTPMethodOverrideEnabled } from '@gonfalon/dogfood-flags';
import { isObject } from '@gonfalon/es6-utils';
import { RESTAPIError } from '@gonfalon/rest-api';
// eslint-disable-next-line no-restricted-imports
import { fromJS, isImmutable } from 'immutable';

import { ImmutableMap } from 'utils/immutableUtils';

import 'isomorphic-fetch';

type ServerErrorContext<T> = T extends null ? {} : T;

export type ImmutableServerError<T = null> = ImmutableMap<
  {
    code: string;
    message: string;
    status: number;
  } & ServerErrorContext<T>
>;

const jsonType = 'application/json';

type BodyObject = object;

type PropFilter = (obj: BodyObject) => BodyObject;

type BodySerializationOptions = {
  transformBody?: PropFilter;
};

// Our HTTP methods accept instances of our models which the standard request would not accept as-is.
// We transform those models into JSON when needed (in method() below).
type RequestBody = BodyInit | object;

type RequestInitWithBody = Omit<RequestInit, 'body'> & {
  body: RequestBody;
};

const filterOutUnderscoreProps: PropFilter = (obj: BodyObject) => omitDeep(obj, (v, k) => /^_/.test(k));

function serializeBody(body: RequestBody, options: BodySerializationOptions = {}) {
  if (body instanceof FormData) {
    return body;
  }

  const obj = isImmutable(body) ? body.toJS() : body;

  if (!isObject(obj)) {
    return obj;
  }

  return JSON.stringify(options.transformBody ? options.transformBody(obj) : obj);
}

function prepareBody(req: RequestInitWithBody, options: BodySerializationOptions): RequestInit {
  return { ...req, body: serializeBody(req.body, options) };
}

async function status(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
}

export type ImmutableBeastModeError = ImmutableServerError<{
  code: 'escalation_required';
}>;

export type ImmutableNotFoundError = ImmutableServerError<{
  code: 'not_found';
}>;

export function isImmutableBeastModeError(error: unknown): error is ImmutableBeastModeError {
  return isImmutable(error) && error.get('status') === 403 && error.get('code') === 'escalation_required';
}

export function isImmutableNotFoundError(error: unknown): error is ImmutableNotFoundError {
  return isImmutable(error) && error.get('status') === 404;
}

export const middleware = {
  json: async (response: Response) => {
    if (
      response.status !== 204 &&
      response.headers &&
      response.headers.get('Content-Type') &&
      response.headers.get('Content-Type')?.includes(jsonType)
    ) {
      return response.json();
    } else {
      return Promise.reject(new TypeError());
    }
  },
  jsonError: async (response: Response) => {
    if (
      response.headers &&
      response.headers.get('Content-Type') &&
      response.headers.get('Content-Type')?.includes(jsonType)
    ) {
      // We don't care about the type of the promise
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return new Promise<any>((_, reject) => {
        response
          .json()
          .then((body) => reject({ ...body, status: response.status }))
          .catch(() => reject({ status: response.status }));
      });
    } else {
      return Promise.reject({ status: response.status });
    }
  },
  beastModeRefresh: (res: Response) => {
    refreshBeastModeExpiration();
    return res;
  },
  // beastModeError must receive an http response processed by jsonError middleware
  beastModeError: (error: ImmutableServerError) => {
    if (isImmutableBeastModeError(error)) {
      redirectToBeastModeEscalation();
      throw error;
    }
  },
};

// These versions of our response middlewares automatically convert data
// to Immutable.js structures.
export const jsonToImmutable = async (response: Response) => middleware.json(response).then((data) => fromJS(data));
export const jsonToImmutableError = async (response: Response) =>
  middleware.jsonError(response).catch((error) => {
    throw fromJS(error);
  });
export const restApitoImmutableError = (error: RESTAPIError) => {
  throw fromJS({ status: error.status, message: error.message, code: error.code });
};
export const beastModeRefresh = middleware.beastModeRefresh;
export const beastModeError = middleware.beastModeError;

// Idempotent (safe) methods as defined by RFC7231 section 4.2.2, plus REPORT
const methodsWithBody = ['POST', 'PUT', 'PATCH', 'REPORT'];
const unsafeMethods = ['PATCH', 'PUT', 'REPORT', 'DELETE'];
const httpMethodOverride = 'X-HTTP-Method-Override';

const baseHeaders = {
  Accept: jsonType,
};

const withBodyHeaders = {
  ...baseHeaders,
  'Content-Type': jsonType,
};

type HTTPHeaders = HeadersInit & {
  [name: string]: string;
};

type HTTPRequestOptions = Omit<RequestInit, 'body'> & {
  headers?: HTTPHeaders;
  beta?: boolean;
  propFilter?: PropFilter;
  body?: RequestBody;
  signal?: AbortSignal;
};

function method(name: string) {
  const defaultPropFilter = filterOutUnderscoreProps;

  return async function (url: string, options: HTTPRequestOptions = {}) {
    const { headers, propFilter, beta, body, ...otherOptions } = options;

    const finalPropFilter = propFilter ? propFilter : defaultPropFilter;
    const needsBodyHeaders = methodsWithBody.includes(name) && !(body instanceof FormData);
    const defaultHeaders: HTTPHeaders = needsBodyHeaders ? withBodyHeaders : baseHeaders;
    const finalHeaders: HTTPHeaders = { ...defaultHeaders, ...headers };

    if (beta) {
      finalHeaders['Ld-Api-Version'] = 'beta';
    }

    let methodName = name;

    if (isUseHTTPMethodOverrideEnabled()) {
      finalHeaders[httpMethodOverride] = name;
      if (unsafeMethods.includes(name)) {
        methodName = 'POST';
      }
    }

    const reqOptions: RequestInit = {
      method: methodName,
      headers: finalHeaders,
      credentials: 'same-origin',
    };

    const hasBody = methodsWithBody.includes(name) && body;

    const allOptions: RequestInit = {
      ...otherOptions,
      ...reqOptions,
    };

    const bodyOptions = name !== 'PATCH' ? { transformBody: finalPropFilter } : {};
    const finalOptions = hasBody
      ? prepareBody({ ...allOptions, body } as RequestInitWithBody, bodyOptions)
      : allOptions;

    const req = fetch(url, finalOptions);

    return req.then(status);
  };
}

export type HTTPMethodFunction = ReturnType<typeof method>;

/* eslint-disable import/no-default-export */
export default {
  get: method('GET'),
  post: method('POST'),
  put: method('PUT'),
  patch: method('PATCH'),
  delete: method('DELETE'),
  report: method('REPORT'),
};
