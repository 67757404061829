export function createContainerDataFunction<T = unknown>(
  name: string,
  options: { parse: boolean } = { parse: true },
): () => T | undefined {
  return () => {
    const value = document.querySelector<HTMLElement>('.ld-container')?.dataset[name];

    if (typeof value !== 'undefined') {
      // Allow consumers to bypass parsing, useful for example when large numbers would
      // otherwise be parsed incorrectly:
      //
      // String(116322939468319970652) === '116322939468319970000'
      if (!options.parse) {
        return value as T;
      }

      try {
        return JSON.parse(value) as T;
      } catch (error) {
        return value as T;
      }
    }

    return;
  };
}
