import { QueryKey as RQQueryKey, useSuspenseQuery, UseSuspenseQueryOptions } from '@tanstack/react-query';

type SafeSuspenseQueryOptions<QueryFnData, Error, Data, QueryKey extends RQQueryKey> = Omit<
  UseSuspenseQueryOptions<QueryFnData, Error, Data, QueryKey>,
  'queryKey' | 'queryFn' | 'queryKeyHashFn'
>;

export function createSuspenseQueryHook<
  Dependencies,
  QueryFnData = unknown,
  Error = unknown,
  Data = QueryFnData,
  QueryKey extends RQQueryKey = RQQueryKey,
>(queryFactory: (deps: Dependencies) => UseSuspenseQueryOptions<QueryFnData, Error, Data, QueryKey>) {
  return (deps: Dependencies, options?: SafeSuspenseQueryOptions<QueryFnData, Error, Data, QueryKey>) =>
    useSuspenseQuery({ ...options, ...queryFactory(deps) });
}
