import { schemas } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { environments } from './internal/queries';

export function initializeEnvironment({
  projectKey,
  environmentKey,
  environment,
}: {
  projectKey: string;
  environmentKey: string;
  environment: schemas['Environment'];
}) {
  getQueryClient().setQueryData(environments.detail({ projectKey, environmentKey }).queryKey, environment);
}
