import { useContext } from 'react';

import { NewIAContext } from './NewIAContext';

export function useIsInNewIA() {
  const context = useContext(NewIAContext);
  if (context === undefined) {
    return false;
  }

  return true;
}
