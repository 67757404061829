// import { BeastModeActionType } from './beastModeActionTypes';

import { GenerateActionType } from 'utils/reduxUtils';

export const deactivateBeastMode = () =>
  ({
    type: 'beastMode/STOP',
    activated: false,
  }) as const;

export const activateBeastMode = () =>
  ({
    type: 'beastMode/START',
    activated: true,
  }) as const;

const BeastModeActionCreators = {
  deactivateBeastMode,
  activateBeastMode,
};

export type BeastModeAction = GenerateActionType<typeof BeastModeActionCreators>;
