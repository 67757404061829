import { lazy } from 'react';
import { googleOauthClientId as getGoogleOauthClientId } from '@gonfalon/constants';
import { enableDomainMatching, enableUnverifiedMembersSignup } from '@gonfalon/dogfood-flags';
import cx from 'clsx';
import { Alert } from 'launchpad';

import { GitHubOAuthButton, GoogleDataText, GoogleOAuthButton } from 'components/loginAndSignUp/OAuthButtons';
import { DividerWithText } from 'components/ui/DividerWithText/DividerWithText';
import { PageWithPromo } from 'components/ui/layout/PageWithPromo';
import { UnauthenticatedPage } from 'components/ui/layout/UnauthenticatedPage';
import { Module, ModuleContent, ModuleHeader } from 'components/ui/module';
import styles from 'stylesheets/components/Login.module.css';
import { ImmutableServerError } from 'utils/httpUtils';
import { isEmailNotVerifiedError, trackSignUp } from 'utils/loginUtils';
import { shouldShowUnauthenticatedPagePromo } from 'utils/unauthenticatedPage';

import { ErrorAlert } from '../ui/alert/ErrorAlert';

import LoginFormContainer from './LoginFormContainer';

const VerifyMFA = lazy(async () => import(/* webpackChunkName: "VerifyMFA" */ 'components/VerifyMFA'));

export type LoginProps = {
  token?: string;
  authTimedOut?: boolean;
  needsRefresh?: boolean;
  isRecovery: boolean;
  error?: ImmutableServerError;
  githubOauthError?: boolean;
  googleOauthError?: boolean;
  requirePassword: boolean;
  onMFAVerify: (token: string, code: string, redirectPath?: string) => Promise<void>;
  onMFARecover: (token: string, code: string) => Promise<void>;
  onUseMFARecovery: () => void;
  rulesOfBehaviorIntro?: string;
  disallowSignups: boolean;
  beastMode?: boolean;
  beastModeEmail?: string;
  federalCssStylesEnabled: boolean;
  enableGithubOAuthSignUp: boolean;
  enableGoogleOAuthSignUp: boolean;
  redirect?: string;
};

/* eslint-disable import/no-default-export */
export default function Login({
  federalCssStylesEnabled,
  token,
  error,
  isRecovery,
  authTimedOut,
  needsRefresh,
  requirePassword,
  disallowSignups,
  beastMode,
  beastModeEmail,
  rulesOfBehaviorIntro,
  onMFAVerify,
  onMFARecover,
  githubOauthError,
  googleOauthError,
  onUseMFARecovery,
  enableGithubOAuthSignUp,
  enableGoogleOAuthSignUp,
  redirect,
}: LoginProps) {
  const isUnverifiedEmail = enableUnverifiedMembersSignup() && isEmailNotVerifiedError(error);
  const isDomainMatchingEnabled = enableDomainMatching();
  const googleOauthClientId = getGoogleOauthClientId();
  const enableOAuthSignUp = enableGoogleOAuthSignUp || enableGithubOAuthSignUp;

  const sanitizedUrlSearchParams = () => {
    if (window.location.search && window.location.search.length > 1) {
      const params = new URLSearchParams(window.location.search);
      // deleting the googleOauthError & githubOauthError search params from the url
      params.delete('googleOauthError');
      params.delete('githubOauthError');
      return `?${params.toString()}`;
    } else {
      return '';
    }
  };

  const renderLoginForm = () => (
    // @ts-expect-error withForm doesn't know about the injected props
    <LoginFormContainer
      requirePassword={requirePassword}
      beastMode={beastMode}
      beastModeEmail={beastModeEmail}
      redirect={redirect}
      isUnverifiedEmail={isUnverifiedEmail}
    />
  );

  const renderJoinExistingAcct = () => {
    if (isDomainMatchingEnabled) {
      return null;
    }

    return (
      <p>
        Want to join an existing account?{' '}
        <a
          onClick={() =>
            trackSignUp('Request to Invite Link Clicked', {
              url: window.location.pathname,
              component: 'Login',
              type: 'link',
            })
          }
          href="/join"
        >
          Request invitation
        </a>
      </p>
    );
  };

  const renderOtherActions = () => {
    if (isUnverifiedEmail) {
      return null;
    }

    return !beastMode ? (
      !disallowSignups && (
        <>
          {' '}
          <p>
            Don't have an account?{' '}
            <a
              onClick={() =>
                trackSignUp('Sign Up Link Clicked', {
                  url: window.location.pathname,
                  component: 'Login',
                  type: 'link',
                })
              }
              href={`/signup${sanitizedUrlSearchParams()}`}
            >
              Sign up
            </a>
          </p>
          {renderJoinExistingAcct()}
          <p>
            Have a federal account? <a href="https://app.launchdarkly.us/login">Sign in here</a>
          </p>
        </>
      )
    ) : (
      <p>
        Go back to <a href="/settings">account settings</a>.
      </p>
    );
  };

  const renderLogin = () => (
    <>
      <Module
        className={cx(styles.Login, {
          [styles.federal]: federalCssStylesEnabled,
        })}
      >
        <div className={styles.content}>
          <ModuleHeader>
            {!isUnverifiedEmail && <h1>Sign in to LaunchDarkly</h1>}
            {beastMode && <h2>Reauthenticate to continue.</h2>}
          </ModuleHeader>
          {!requirePassword && rulesOfBehaviorIntro && (
            <Alert kind="error" noIcon className={styles.federalWarning}>
              {rulesOfBehaviorIntro}
            </Alert>
          )}
          <ModuleContent isSnug className={cx({ [styles.LoginModuleFederal]: federalCssStylesEnabled })} isSeamless>
            {(authTimedOut || needsRefresh) && (
              <Alert className="u-mb-l" kind="info">
                Authentication timed out
              </Alert>
            )}
            {error && !isUnverifiedEmail && <ErrorAlert className="u-mb-l" error={error} />}
            {enableOAuthSignUp && (googleOauthError || githubOauthError) && !error && (
              <ErrorAlert
                className="u-mb-l"
                error={{
                  message: `Error signing in with ${
                    googleOauthError ? 'Google' : 'Github'
                  }. If you have an existing account, sign in with your email address.`,
                }}
              />
            )}

            <>
              {renderLoginForm()}
              {enableGoogleOAuthSignUp && googleOauthClientId && !isUnverifiedEmail && (
                <>
                  <DividerWithText text="or" />
                  <GoogleOAuthButton
                    clientId={googleOauthClientId}
                    redirect={redirect}
                    dataText={requirePassword ? GoogleDataText.SIGNIN_WITH : GoogleDataText.CONTINUE_WITH}
                  />
                </>
              )}
              {enableGithubOAuthSignUp && !isUnverifiedEmail && (
                <>
                  <GitHubOAuthButton />
                </>
              )}
            </>

            <div className={styles.otherActions}>{renderOtherActions()}</div>
            {federalCssStylesEnabled ? (
              <p>
                Have a commercial account? <a href="https://app.launchdarkly.com/login">Sign in here</a>
              </p>
            ) : (
              <></>
            )}
          </ModuleContent>
        </div>
      </Module>
    </>
  );

  const renderMFA = () => (
    <VerifyMFA
      className="Login-mfa"
      token={token}
      error={error}
      isRecovery={isRecovery}
      onVerify={onMFAVerify}
      onRecover={onMFARecover}
      onUseRecovery={onUseMFARecovery}
      rulesOfBehaviorIntro={rulesOfBehaviorIntro}
      redirectPath={redirect}
      redirectQueryString={sanitizedUrlSearchParams()}
    />
  );

  const hideHeaderBackground = !token && !shouldShowUnauthenticatedPagePromo();

  return (
    <UnauthenticatedPage
      federalCssStylesEnabled={federalCssStylesEnabled}
      pageClass="Login"
      hideHeaderBackground={hideHeaderBackground}
    >
      {token ? (
        <PageWithPromo promoType="LogoGrid">{renderMFA()}</PageWithPromo>
      ) : (
        <PageWithPromo promoType="FlagControlled">{renderLogin()}</PageWithPromo>
      )}
    </UnauthenticatedPage>
  );
}
