import { createQueryHook } from './internal/createQueryHook';
import { metricAnalytics } from './internal/queries';

type Props = {
  projectKey: string;
  environmentKey: string;
  startsWith?: string;
  limit?: number;
};

export const useProductAnalyticsMetrics = createQueryHook(({ projectKey, environmentKey, startsWith, limit }: Props) =>
  metricAnalytics.metrics({ projectKey, environmentKey, startsWith, limit }),
);
