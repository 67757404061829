import { createSuspenseQueryHook } from './internal/createSuspenseQueryHook';
import { environments } from './internal/queries';

export const useSuspenseEnvironment = createSuspenseQueryHook(
  ({
    projectKey,
    environmentKey,
    shouldIgnoreErrors,
  }: {
    projectKey: string;
    environmentKey: string;
    shouldIgnoreErrors?: boolean;
  }) => environments.detail({ projectKey, environmentKey, shouldIgnoreErrors }),
);
