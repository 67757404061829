import { GetAllReleasePipelinesOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export type GetAllReleasePipelinesQueryParams = Omit<GetAllReleasePipelinesOpenAPIQueryParams, 'filter'> & {
  filter?: {
    query?: string;
    env?: string;
  };
};

export function querySerializer(queryParams: GetAllReleasePipelinesQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.filter) {
    const serializedFilter = serializeReleasePipelinesFilterParam(queryParams.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  return searchParams.toString();
}

export function serializeReleasePipelinesFilterParam(value: GetAllReleasePipelinesQueryParams['filter']) {
  const filter: string[] = [];

  if (value?.query) {
    filter.push(`query:${value.query}`);
  }

  if (value?.env) {
    filter.push(`env:${value.env}`);
  }

  filter.sort();

  return filter.join(',');
}
