// eslint-disable-next-line no-restricted-imports
import { createSearchParams, matchPath, Path } from 'react-router-dom';

import { projectContextSchema } from './projectContext';

export function readProjectContextFromPath(path: Path) {
  const match = matchPath('/projects/:projectKey/*', path.pathname);

  if (!match) {
    return undefined;
  }

  const projectKey = match?.params.projectKey;

  if (!projectKey) {
    return undefined;
  }

  const searchParams = createSearchParams(path.search);
  const environmentKeys = new Set(searchParams.getAll('env'));
  const selectedEnvironmentKey = searchParams.get('selected-env') ?? '';

  const params = { projectKey, environmentKeys, selectedEnvironmentKey };
  const result = projectContextSchema.safeParse(params);

  if (!result.success) {
    return undefined;
  }

  return result.data;
}
