import type { LegacyIconProps } from '@gonfalon/icons';
import { LegacyIcon } from '@gonfalon/icons';
import AmplitudeSvg from 'img/icons/amplitude.svg';
import AmplitudeDarkModeSvg from 'img/icons/amplitude-dark-mode.svg';
import AmazonKinesisSvg from 'img/icons/aws-kinesis.svg';
import BitbucketSvg from 'img/icons/bitbucket.svg';
import DatadogSvg from 'img/icons/datadoghq-icon.svg';
import AzureEventHubsSvg from 'img/icons/event-hubs.svg';
import GitSvg from 'img/icons/git.svg';
import GithubSvg from 'img/icons/github.svg';
import GitlabSvg from 'img/icons/gitlab.svg';
import GooglePubsubSvg from 'img/icons/google-cloud-pubsub.svg';
import HeapSvg from 'img/icons/heap.svg';
import HeapDarkModeSvg from 'img/icons/heap-dark-mode.svg';
import MixpanelSvg from 'img/icons/mixpanel.svg';
import MixpanelDarkModeSvg from 'img/icons/mixpanel-dark-mode.svg';
import MParticleSvg from 'img/icons/mparticle.svg';
import SegmentSvg from 'img/icons/segment.svg';

export const AmazonKinesis = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="kinesis" className={className} size={size} {...props}>
    <AmazonKinesisSvg />
  </LegacyIcon>
);

export const Amplitude = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="amplitude" className={className} size={size} {...props}>
    <AmplitudeSvg />
  </LegacyIcon>
);

export const AmplitudeDarkMode = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="amplitude-dark-mode" className={className} size={size} {...props}>
    <AmplitudeDarkModeSvg />
  </LegacyIcon>
);

export const AzureEventHubs = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="azure-event-hubs" className={className} size={size} {...props}>
    <AzureEventHubsSvg />
  </LegacyIcon>
);

export const Bitbucket = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="bitbucket" className={className} size={size} {...props}>
    <BitbucketSvg />
  </LegacyIcon>
);

export const Datadog = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="datadog" className={className} size={size} {...props}>
    <DatadogSvg />
  </LegacyIcon>
);

export const Git = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="git" className={className} size={size} {...props}>
    <GitSvg />
  </LegacyIcon>
);

export const Github = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="github" className={className} size={size} {...props}>
    <GithubSvg />
  </LegacyIcon>
);

export const Gitlab = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="gitlab" className={className} size={size} {...props}>
    <GitlabSvg />
  </LegacyIcon>
);

export const GooglePubsub = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="google-pubsub" className={className} size={size} {...props}>
    <GooglePubsubSvg />
  </LegacyIcon>
);

export const Heap = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="heap" className={className} size={size} {...props}>
    <HeapSvg />
  </LegacyIcon>
);

export const HeapDarkMode = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="heap-dark-mode" className={className} size={size} {...props}>
    <HeapDarkModeSvg />
  </LegacyIcon>
);

export const Mixpanel = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="mixpanel" className={className} size={size} {...props}>
    <MixpanelSvg />
  </LegacyIcon>
);

export const MixpanelDarkMode = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="mixpanel-dark-mode" className={className} size={size} {...props}>
    <MixpanelDarkModeSvg />
  </LegacyIcon>
);

export const MParticle = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="mParticle" className={className} size={size} {...props}>
    <MParticleSvg />
  </LegacyIcon>
);

export const Segment = ({ className, size, ...props }: LegacyIconProps) => (
  <LegacyIcon name="segment" className={className} size={size} {...props}>
    <SegmentSvg />
  </LegacyIcon>
);
