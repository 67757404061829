import { deleteEnvironment } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { queries } from './internal/queries';

export const useDeleteEnvironment = createMutationHook(deleteEnvironment, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: queries.environments.list._def });
  },
});
