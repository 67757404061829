import { settle } from '@gonfalon/async';
import { patchFeatureFlag } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { flags } from './internal/queries';
import { reactQueryResponseAdapter } from './internal/reactQueryResponseAdapter';

export async function updateFlagSemantically({
  projectKey,
  flagKey,
  environmentKey,
  instructions,
  ignoreConflicts,
  comment,
}: {
  projectKey: string;
  flagKey: string;
  environmentKey?: string;
  instructions: unknown[];
  ignoreConflicts: boolean;
  comment?: string;
}) {
  const queryClient = getQueryClient();

  const [result, error] = await settle(async () =>
    reactQueryResponseAdapter(
      patchFeatureFlag(
        {
          projectKey,
          featureFlagKey: flagKey,
          query: { ignoreConflicts },
          body: {
            comment,
            environmentKey,
            instructions,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
          },
        },
      ),
    ),
  );

  if (error) {
    throw error;
  }

  await queryClient.invalidateQueries({ queryKey: flags.list._def });
  // TODO: This should be more targeted (to only invalidate queries for this specific flag),
  // but in practice if we're updating a specific flag it should be the only one with active
  // detail queries
  await queryClient.invalidateQueries({ queryKey: flags.detail._def });

  return result;
}
