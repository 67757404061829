import { deleteApplicationVersion } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { applications } from './internal/queries';

export const useDeleteApplicationVersion = createMutationHook(deleteApplicationVersion, {
  onSuccess: async (data, { applicationKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: applications.detail({ applicationKey })._ctx.versions._def });
  },
});
