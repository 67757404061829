import { handlePendingRequests } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { pendingRequests } from './internal/queries';

export const usePostPendingRequests = createMutationHook(handlePendingRequests, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: pendingRequests.list._def });
  },
});
