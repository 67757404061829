import {
  isEnvironmentScopedResource,
  isProjectScopedResource,
  ResourceSpecifier,
  resourceSpecifierFromString,
  RoleAttribute,
} from '@gonfalon/resource-specifiers';

import type { Statement } from './internal/types';

const getRoleAttributes = (specifier: ResourceSpecifier): RoleAttribute[] => {
  const roleAttributes: RoleAttribute[] = [];

  if (specifier.type === 'acct') {
    return roleAttributes;
  }

  if (specifier.roleAttribute) {
    roleAttributes.push({
      type: specifier.type,
      attribute: specifier.roleAttribute,
    });
  }

  if (isProjectScopedResource(specifier)) {
    roleAttributes.push(...getRoleAttributes(specifier.project));
  }

  if (isEnvironmentScopedResource(specifier)) {
    roleAttributes.push(...getRoleAttributes(specifier.environment));
  }

  return roleAttributes;
};

export const getRoleAttributesFromPolicy = (policy: Statement[]) => {
  const allResources = policy.flatMap((statement) => [
    ...(statement.resources || []),
    ...(statement.notResources || []),
  ]);
  const specifiers = allResources.reduce<ResourceSpecifier[]>((acc, resource) => {
    const { ok, val } = resourceSpecifierFromString(resource);
    if (ok) {
      acc.push(val);
    }
    return acc;
  }, []);

  return specifiers.flatMap(getRoleAttributes);
};
