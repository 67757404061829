import { useMemo, useState, useTransition } from 'react';
import { Key } from 'react-aria-components';
import { TextTruncator } from '@gonfalon/launchpad-experimental';
import { getCustomRolesQuery } from '@gonfalon/rest-api';
import { ComboBox, Group, Input, ListBox, ListBoxItem, Popover, ProgressBar, Text } from '@launchpad-ui/components';
import { Box } from '@launchpad-ui/core';
import { useQuery } from '@tanstack/react-query';

import { CustomRole } from '../internal/types';

import { ButtonGroup } from './ButtonGroup';

import styles from './RoleSelect.module.css';

export type RoleSelectProps = {
  onSelectionChange: (role?: CustomRole) => void;
};

type Item = { id: string; name: string };

export const RoleSelect = ({ onSelectionChange }: RoleSelectProps) => {
  const [selectedRole, setSelectedRole] = useState<CustomRole | null>(null);
  const [filterText, setFilterText] = useState('');
  const [isTransitioning, startTransition] = useTransition();

  const { data: customRolesRes, isPending } = useQuery(
    getCustomRolesQuery({
      query: { filter: { query: filterText }, limit: 20 },
      apiVersion: '20240415',
    }),
  );

  const isLoading = isTransitioning || isPending;

  const items = useMemo(
    () => customRolesRes?.items.map((item) => ({ id: item.key, name: item.name })) || [],
    [customRolesRes],
  );

  const handleFilterChange = (value: string) => {
    startTransition(() => {
      setFilterText(value);
    });
  };

  const handleSelectionChange = (key?: Key | null) => {
    if (typeof key === 'string') {
      const role = customRolesRes?.items.find((item) => item.key === key);
      if (role) {
        setSelectedRole(role);
        onSelectionChange(role);
      }
      return;
    }

    setSelectedRole(null);
    onSelectionChange();
  };

  return (
    <ComboBox
      menuTrigger="focus"
      onInputChange={handleFilterChange}
      allowsEmptyCollection
      items={items}
      onSelectionChange={handleSelectionChange}
      selectedKey={selectedRole?.key}
      inputValue={selectedRole?.name || filterText}
      aria-label="Select role"
    >
      <Group>
        <Input placeholder="Select role" />
        <ButtonGroup label="Select role" />
      </Group>
      <Popover placement="bottom">
        <ListBox<Item>
          renderEmptyState={() => (
            <Box display="flex" justifyContent="center" alignItems="center">
              {isLoading ? <ProgressBar aria-label="Loading…" isIndeterminate size="small" /> : <>No roles found</>}
            </Box>
          )}
        >
          {(item) => (
            <ListBoxItem textValue={item.name}>
              <Text slot="label" className={styles.label}>
                <TextTruncator>{item.name}</TextTruncator>
              </Text>
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </ComboBox>
  );
};
