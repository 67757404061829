import { pluralRules } from './pluralRules';

export function pluralize(count: number, singular: string, plural: string) {
  const rule = pluralRules.select(count);

  switch (rule) {
    case 'one':
      return singular;
    case 'other':
      return plural;
    default:
      return undefined;
  }
}
