import { postShortcut } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { shortcuts } from './internal/queries';

export const useCreateShortcut = createMutationHook(postShortcut, {
  onMutate: async (variables) => {
    const queryOptions = shortcuts.list({ projectKey: variables.body.context.projectKey });
    const queryKey = queryOptions.queryKey;

    type QueryData = Awaited<ReturnType<(typeof queryOptions)['queryFn']>>;

    await getQueryClient().cancelQueries({ queryKey });

    const previousShortcuts = getQueryClient().getQueryData<QueryData>(queryKey);

    getQueryClient().setQueryData<QueryData>(queryKey, (old) => {
      if (old === undefined) {
        return;
      }

      return {
        ...old,
        items: [...old.items, variables.body],
      };
    });

    return { previousShortcuts };
  },
  onError: (error, variables, context) => {
    const queryOptions = shortcuts.list({ projectKey: variables.body.context.projectKey });
    const queryKey = queryOptions.queryKey;
    getQueryClient().setQueryData(queryKey, context?.previousShortcuts);
  },
  onSuccess: async (data, variables) => {
    const queryOptions = shortcuts.list({ projectKey: variables.body.context.projectKey });
    const queryKey = queryOptions.queryKey;
    await getQueryClient().invalidateQueries({ queryKey });
  },
});
