import { userEmail } from '@gonfalon/constants';
import { postTracking } from '@gonfalon/openapi';

export const trackEventsViaInternalApi = async (event: string, properties: { [key: string]: string }) => {
  const email = userEmail();

  const response = await postTracking(
    {
      body: {
        event,
        Properties: { ...properties, userEmail: email },
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'LD-Bypass-UA-Tracking': '',
      },
    },
  );
  if (response.response.ok) {
    return;
  } else {
    throw new Error('failed to create analytics event');
  }
};
