// Updates and clears the state of intercom. Particularly useful when a user logs out or signs in.
import { intercomFinAppAppId } from '@gonfalon/constants';

import { initialBoot } from './initialBoot';
import { loadIntercomFinAppScript } from './loadIntercomFinAppScript';
import { removeIntercom } from './removeIntercom';
import { InitIntercomFinAppParams } from './types';

export function resetIntercomInstance(options?: InitIntercomFinAppParams) {
  const appId = intercomFinAppAppId();

  removeIntercom();

  if (window?.Intercom && appId) {
    // eslint-disable-next-line new-cap
    window?.Intercom('shutdown');

    loadIntercomFinAppScript(appId, options)
      .then(() => {
        initialBoot(appId, options);
      })
      .catch((err) => err);
  }
}
