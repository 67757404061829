import { createQueryHook } from './internal/createQueryHook';
import { bigSegmentStore } from './internal/queries';

export const useBigSegmentStoreIntegration = createQueryHook(
  ({
    projectKey,
    environmentKey,
    integrationKey,
    integrationId,
  }: {
    projectKey: string;
    environmentKey: string;
    integrationKey: string;
    integrationId: string;
  }) =>
    bigSegmentStore.detail({
      projectKey,
      environmentKey,
      integrationKey,
      integrationId,
    }),
);
