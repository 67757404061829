import { deleteReleasePipeline } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { releasePipelines } from './internal/queries';

export const useDeleteReleasePipeline = createMutationHook(deleteReleasePipeline, {
  onSuccess: async (_, { projectKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: releasePipelines.list({ projectKey }).queryKey });
  },
});
